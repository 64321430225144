import { SET_ALERT } from "../types";

const alertReducer = (state, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        showAlert: action.payload.showAlert,
        message: action.payload.message,
        type: action.payload.type,
      };

    default:
      return state;
  }
};

export default alertReducer;
