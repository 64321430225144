import React, { useState, useEffect } from "react";
import SiteValidations from "../../utils/SiteValidations";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Tooltip,
  IconButton,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import usStates from "../../utils/States";
import "../../css/Order_site_edit.css";
import axios from "axios";
import FilterListIcon from "@mui/icons-material/FilterList";

const EditsiteToEdit = ({
  viewSiteIndex,
  bundles,
  setOrderPage,
  orderFormData,
  setOrderFormData,
}) => {
  const [planHasBeenSelected, setPlanHasBeenSelected] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [siteToEdit, setSiteToEdit] = useState(null);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [editedSite, setEditedSite] = useState(null);
  const [displayedBundles, setDisplayedBundles] = useState(null);
  const [showBundleEdit, setShowBundleEdit] = useState(false);
  const [chargesBeforeEdit, setChargesBeforeEdit] = useState({
    mrc: 0,
    nrc: 0,
  });
  const [selectedHardware, setSelectedHardware] = useState("ALL");
  const [selectedTerm, setSelectedTerm] = useState("ALL");
  const [selectedProvider, setSelectedProvider] = useState("ALL");
  const [bundlesData, setBundlesData] = useState(null);
  const [terms, setTerms] = useState(null);
  const [hardware, setHardware] = useState(null);
  const [providers, setProviders] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [siteValidations, setSiteValidations] = useState({
    overall: true,
    plan_sku: true,
    site_name: false,
    site_contact_name: false,
    site_contact_phone: false,
    site_contact_email: false,
    site_address: false,
    site_city: false,
    site_state: false,
    site_zip: false,
    site_ship_attention: false,
    site_ship_address: false,
    site_ship_city: false,
    site_ship_zip: false,
  });

  const getBundles = async () => {
    const res = await axios.get("/api/bundles/active");
    setBundlesData(res.data);
  };

  useEffect(() => {
    getBundles();
  }, []);

  useEffect(() => {
    const editingSite = orderFormData.sites.filter(
      (site) => site.site_name === viewSiteIndex
    );
    setSiteToEdit(editingSite[0]);
    setChargesBeforeEdit({
      mrc: editingSite[0].site_monthly,
      nrc: editingSite[0].site_upfront,
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (siteToEdit !== null) {
      const bundleToEdit = bundles.filter(
        (bundle) => siteToEdit.plan_sku === bundle.sku
      );
      setSelectedBundle(bundleToEdit[0]);
      setEditedSite(siteToEdit);
    }

    // eslint-disable-next-line
  }, [siteToEdit]);

  // *********************************************************
  useEffect(() => {
    var hardwareList = [];

    if (bundlesData !== null) {
      bundlesData.map((bundle) => {
        function containsObject(obj, list) {
          var i;
          for (i = 0; i < list.length; i++) {
            if (list[i].title === obj.manufacturer) {
              return true;
            }
          }

          return false;
        }

        const contains = containsObject(bundle, hardwareList);

        if (!contains) {
          hardwareList.push({
            _id: bundle._id,
            title: bundle.manufacturer,
            value: bundle.manufacturer,
          });
        }
        return null;
      });

      setHardware(hardwareList);
    }
    // eslint-disable-next-line
  }, [bundlesData]);

  useEffect(() => {
    var termsList = [];

    if (bundlesData !== null) {
      bundlesData.map((bundle) => {
        function containsObject(obj, list) {
          var i;
          for (i = 0; i < list.length; i++) {
            if (list[i].title === obj.term) {
              return true;
            }
          }

          return false;
        }

        const contains = containsObject(bundle, termsList);

        if (!contains && bundle.active) {
          termsList.push({
            _id: bundle._id,
            title: bundle.term,
            value: bundle.term,
          });
        }
        return null;
      });

      setTerms(termsList);
    }
    // eslint-disable-next-line
  }, [bundlesData]);

  useEffect(() => {
    var providerList = [];

    if (bundlesData !== null) {
      bundlesData.map((bundle) => {
        function containsObject(obj, list) {
          var i;
          for (i = 0; i < list.length; i++) {
            if (list[i].title === obj.provider) {
              return true;
            }
          }

          return false;
        }

        const contains = containsObject(bundle, providerList);

        if (!contains && bundle.active) {
          providerList.push({
            _id: bundle._id,
            title: bundle.provider,
            value: bundle.provider,
          });
        }
        return null;
      });

      setProviders(providerList);
    }
    // eslint-disable-next-line
  }, [bundlesData]);

  useEffect(() => {
    var filteredHardware = [];
    var filteredProviders = [];
    var filteredBundles = [];
    if (bundlesData !== null) {
      if (selectedHardware === "ALL") {
        bundlesData.map((bundle) => filteredHardware.push(bundle));
      } else {
        const filtered = bundlesData.filter(
          (item) => item.manufacturer === selectedHardware
        );
        filtered.map((bundle) => filteredHardware.push(bundle));
      }
      if (selectedProvider === "ALL") {
        filteredHardware.map((bundle) => filteredProviders.push(bundle));
      } else {
        const filtered2 = filteredHardware.filter(
          (item) => item.provider === selectedProvider
        );

        filtered2.map((bundle) => filteredProviders.push(bundle));
      }

      if (selectedTerm === "ALL") {
        filteredProviders.map((bundle) => filteredBundles.push(bundle));
      } else {
        const filtered2 = filteredProviders.filter(
          (item) => item.term === selectedTerm
        );

        filtered2.map((bundle) => filteredBundles.push(bundle));
      }
      setDisplayedBundles(filteredBundles);
    }
    // eslint-disable-next-line
  }, [selectedHardware, bundlesData, selectedTerm, selectedProvider]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // *********************************************************

  const siteEdit = (e) => {
    setEditedSite({ ...editedSite, [e.target.id]: e.target.value });
  };

  const changeHardware = (e) => {
    setSelectedHardware(e.target.value);
  };
  const changeTerm = (e) => {
    setSelectedTerm(e.target.value);
  };
  const changeProvider = (e) => {
    setSelectedProvider(e.target.value);
  };

  const selectPlan = (bundle) => {
    if (selectedPlan === null) {
      setSelectedPlan(bundle);
      setSiteToEdit({
        ...siteToEdit,
        plan_sku: bundle.sku,
        site_monthly: bundle.mrc,
        site_upfront: bundle.nrc,
      });
    } else {
      setSelectedPlan(null);
      setSiteToEdit({
        ...siteToEdit,
        plan_sku: "",
        site_monthly: 0.0,
        site_upfront: 0.0,
      });
    }
  };

  const planChecked = (e) => {
    if (planHasBeenSelected) {
      setPlanHasBeenSelected(false);
    } else {
      setPlanHasBeenSelected(true);
    }
  };

  const resetPlan = () => {
    if (selectedPlan !== null) {
      setPlanHasBeenSelected(false);
      setSelectedPlan(null);
      setSelectedHardware("ALL");
      setSelectedTerm("ALL");
      setSelectedProvider("ALL");
      setSiteToEdit({
        ...siteToEdit,
        plan_sku: "",
        site_monthly: 0.0,
        site_upfront: 0.0,
        site_term: "",
      });
    } else {
      setSelectedHardware("ALL");
      setSelectedTerm("ALL");
      setSelectedProvider("ALL");
      setSiteToEdit({
        ...siteToEdit,
        plan_sku: "",
        site_monthly: 0.0,
        site_upfront: 0.0,
        site_term: "",
      });
    }
  };

  const editSiteToOrder = () => {
    const validated = SiteValidations(editedSite);
    setSiteValidations(validated);
    if (validated.overall) {
      var sitesList = [];
      if (orderFormData.sites.length > 1) {
        orderFormData.sites.map((site) => {
          if (site.site_name !== editedSite.site_name) {
            sitesList.push(site);
          }
          return null;
        });
      }

      sitesList.push({
        site_name: editedSite.site_name,
        site_company: editedSite.site_company,
        plan_sku: editedSite.plan_sku,
        site_monthly: editedSite.site_monthly,
        site_upfront: editedSite.site_upfront,
        site_contact_name: editedSite.site_contact_name,
        site_contact_phone: editedSite.site_contact_phone,
        site_contact_email: editedSite.site_contact_email,
        site_address: editedSite.site_address,
        site_city: editedSite.site_city,
        site_state: editedSite.site_state,
        site_zip: editedSite.site_zip,
        site_ship_company: editedSite.site_ship_company,
        site_ship_attention: editedSite.site_ship_attention,
        site_ship_address: editedSite.site_ship_address,
        site_ship_city: editedSite.site_ship_city,
        site_ship_state: editedSite.site_ship_state,
        site_ship_zip: editedSite.site_ship_zip,
        site_notes: editedSite.site_notes,
        site_term: editedSite.site_term,
      });

      const updatedMRC = editedSite.site_monthly - chargesBeforeEdit.mrc;
      const updatedNRC = editedSite.site_upfront - chargesBeforeEdit.nrc;

      const newMRC = orderFormData.order_monthly + updatedMRC;
      const newNRC = orderFormData.order_upfront + updatedNRC;

      setOrderFormData({
        ...orderFormData,
        sites: sitesList,
        order_monthly: newMRC,
        order_upfront: newNRC,
      });

      setEditedSite({
        plan_sku: "",
        site_monthly: 0,
        site_upfront: 0,
        site_name: "",
        site_company: "",
        site_contact_name: "",
        site_contact_phone: "",
        site_contact_email: "",
        site_address: "",
        site_city: "",
        site_state: "",
        site_zip: "",
        site_ship_company: "",
        site_ship_attention: "",
        site_ship_address: "",
        site_ship_city: "",
        site_ship_state: "",
        site_ship_zip: "",
        site_notes: "",
      });
      setPlanHasBeenSelected(false);
      setSelectedPlan(null);
      setDisplayedBundles(null);
      setSelectedHardware("ALL");

      setOrderPage("siteSummary");
    } else {
      window.scroll(0, 0);
    }
  };

  const editBundle = () => {
    setShowBundleEdit(true);
    setEditedSite({
      ...editedSite,
      plan_sku: "",
      site_monthly: 0.0,
      site_upfront: 0.0,
      site_term: null,
    });
  };

  const manditoryFieldMessage = "------ MANDITORY FIELD ------";

  return (
    <div className="public_order_container">
      <Paper elevation={4} sx={{ padding: 4 }}>
        <Typography variant="h4" color="secondary" sx={{ textAlign: "center" }}>
          Site Details - EDIT
        </Typography>
        {showBundleEdit ? (
          <div>
            <form id="bundle filters">
              <Tooltip title="Filter">
                <IconButton
                  onClick={toggleFilters}
                  sx={{ ml: 2, float: "right" }}
                >
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
              {showFilters ? (
                <>
                  <Grid container sx={{ mt: 2, pl: 3 }} flexDirection="row">
                    <Grid item xs={12}>
                      <Typography variant="h6" sx={{ mt: 2 }}>
                        FILTER BUNDLES:{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ margin: " 16px 8px" }}>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ ml: 2, mt: { xs: 2, sm: 0 } }}
                    >
                      <FormControl variant="outlined">
                        <InputLabel>Manufacturer</InputLabel>
                        <Select
                          name="selectedHardware"
                          value={selectedHardware}
                          onChange={changeHardware}
                          options={hardware}
                          sx={{ minWidth: "200px" }}
                        >
                          <MenuItem key="ALL" value="ALL">
                            ALL
                          </MenuItem>
                          {hardware !== null &&
                            hardware.map((item) => (
                              <MenuItem key={item._id} value={item.value}>
                                {item.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ ml: 2, mt: { xs: 2, sm: 0 } }}
                    >
                      <FormControl>
                        <InputLabel>Term</InputLabel>
                        <Select
                          name="selectedTerm"
                          value={selectedTerm}
                          onChange={changeTerm}
                          sx={{ minWidth: "200px" }}
                        >
                          <MenuItem key="ALL" value="ALL">
                            ALL
                          </MenuItem>
                          {terms !== null &&
                            terms.map((item) => (
                              <MenuItem key={item._id} value={item.value}>
                                {item.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ ml: 2, mt: { xs: 2, sm: 0 } }}
                    >
                      <FormControl>
                        <InputLabel>Provider</InputLabel>
                        <Select
                          name="selectedProvider"
                          value={selectedProvider}
                          onChange={changeProvider}
                          sx={{ minWidth: "200px" }}
                        >
                          <MenuItem key="ALL" value="ALL">
                            ALL
                          </MenuItem>
                          {providers !== null &&
                            providers.map((item) => (
                              <MenuItem key={item._id} value={item.value}>
                                {item.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </form>

            <Table size="small">
              <TableHead>
                <TableRow sx={{ background: "#2e66b0" }}>
                  <TableCell sx={{ color: "white" }}>Select</TableCell>
                  <TableCell sx={{ color: "white" }}>Device</TableCell>
                  <TableCell sx={{ color: "white" }}>Size</TableCell>
                  <TableCell sx={{ color: "white" }}>
                    Service Provider
                  </TableCell>
                  <TableCell sx={{ color: "white" }}>Monthly</TableCell>
                  <TableCell sx={{ color: "white" }}>Upfront</TableCell>
                  <TableCell sx={{ color: "white" }}>Overage*</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedBundles !== null
                  ? displayedBundles.map((bundle) => (
                      <TableRow key={bundle._id}>
                        <TableCell>
                          <Checkbox
                            checked={
                              selectedPlan !== null &&
                              selectedPlan._id === bundle._id
                                ? true
                                : false
                            }
                            disabled={planHasBeenSelected}
                            onChange={() => selectPlan(bundle)}
                            onClick={planChecked}
                            id={bundle._id}
                          />
                        </TableCell>
                        <TableCell>
                          {bundle.manufacturer} - {bundle.model}{" "}
                        </TableCell>
                        <TableCell>{bundle.size}</TableCell>
                        <TableCell>{bundle.provider}</TableCell>
                        <TableCell>${bundle.mrc.toFixed(2)}</TableCell>
                        <TableCell>${bundle.nrc.toFixed(2)}</TableCell>
                        <TableCell>${bundle.overage.toFixed(2)}</TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>

            <div onClick={resetPlan} className="site_bundle_reset_edit">
              RESET
            </div>
            <div className="public_pricing_overage_disclaimer">
              * overage is billed per 1gb in 1gb increments
            </div>
          </div>
        ) : (
          <>
            <Table size="small" sx={{ mt: 2 }}>
              <TableHead>
                <TableRow sx={{ background: "#2e66b0" }}>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Device
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Plan
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Provider
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Monthly
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Upfront
                  </TableCell>
                  <TableCell sx={{ color: "white", textAlign: "center" }}>
                    Overage
                  </TableCell>
                </TableRow>
              </TableHead>
              {selectedBundle !== null ? (
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ textAlign: "center" }}>
                      {selectedBundle.manufacturer} - {selectedBundle.model}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {selectedBundle.size}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {selectedBundle.provider}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      ${selectedBundle.mrc.toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      ${selectedBundle.nrc.toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      ${selectedBundle.overage.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : null}
            </Table>
            <Button
              variant="contained"
              size="small"
              color="warning"
              sx={{ color: "white", my: 2 }}
              onClick={editBundle}
            >
              Edit Bundle
            </Button>
          </>
        )}
        {siteValidations !== null && !siteValidations.plan_sku ? (
          <Typography
            variant="h6"
            color="error"
            sx={{ background: "yellow", textAlign: "center", p: 2 }}
          >
            ***** MUST SELECT AN ABOVE BUNDLE *****
          </Typography>
        ) : null}

        {editedSite !== null || undefined ? (
          <div>
            <Card elevation={4} sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h5" color="secondary" sx={{ mt: 1 }}>
                  Site Information
                </Typography>
                <form>
                  <TextField
                    variant="outlined"
                    label="Unique Site Name"
                    id="site_name"
                    onChange={siteEdit}
                    value={editedSite.site_name}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_name}
                    helperText={
                      siteValidations.site_name && manditoryFieldMessage
                    }
                  />
                  <TextField
                    variant="outlined"
                    label="Site Company Name"
                    id="site_company"
                    onChange={siteEdit}
                    value={editedSite.site_company}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                  />
                  <TextField
                    variant="outlined"
                    label="Address"
                    id="site_address"
                    onChange={siteEdit}
                    value={editedSite.site_address}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_address}
                    helperText={
                      siteValidations.site_address && manditoryFieldMessage
                    }
                  />
                  <TextField
                    variant="outlined"
                    label="City"
                    id="site_city"
                    onChange={siteEdit}
                    value={editedSite.site_city}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_city}
                    helperText={
                      siteValidations.site_city && manditoryFieldMessage
                    }
                  />
                  <FormControl sx={{ minWidth: { xs: "100%" }, p: 1, mt: 1 }}>
                    <InputLabel id="customer_state_label">State</InputLabel>
                    <Select
                      labelId="customer_state_label"
                      name="site_state"
                      id="site_state"
                      label="State"
                      onChange={siteEdit}
                      value={editedSite.site_state}
                      error={siteValidations.site_state}
                    >
                      <MenuItem value="SELECT"> SELECT </MenuItem>
                      {usStates !== null &&
                        usStates.map((state) => (
                          <MenuItem key={state.name} value={state.abbreviation}>
                            {state.abbreviation}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <TextField
                    variant="outlined"
                    label="Zip"
                    id="site_zip"
                    onChange={siteEdit}
                    value={editedSite.site_zip}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_zip}
                    helperText={
                      siteValidations.site_zip && manditoryFieldMessage
                    }
                  />
                </form>
              </CardContent>
            </Card>
            <Card elevation={4} sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h5" color="secondary" sx={{ mt: 1 }}>
                  Site Contact
                </Typography>
                <form>
                  <TextField
                    variant="outlined"
                    label="Site Contact"
                    id="site_contact_name"
                    onChange={siteEdit}
                    value={editedSite.site_contact_name}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_contact_name}
                    helperText={
                      siteValidations.site_contact_name && manditoryFieldMessage
                    }
                  />
                  <TextField
                    variant="outlined"
                    label="Phone"
                    id="site_contact_phone"
                    onChange={siteEdit}
                    value={editedSite.site_contact_phone}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_contact_phone}
                    helperText={
                      siteValidations.site_contact_phone &&
                      manditoryFieldMessage
                    }
                  />
                  <TextField
                    variant="outlined"
                    label="Email"
                    id="site_contact_email"
                    onChange={siteEdit}
                    value={editedSite.site_contact_email}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_contact_email}
                    helperText={
                      siteValidations.site_contact_email &&
                      manditoryFieldMessage
                    }
                  />
                </form>
              </CardContent>
            </Card>

            <Card elevation={4} sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h5" color="secondary" sx={{ mt: 1 }}>
                  Shipping Information
                </Typography>
                <form>
                  <TextField
                    variant="outlined"
                    label="Attention"
                    id="site_ship_attention"
                    onChange={siteEdit}
                    value={editedSite.site_ship_attention}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_ship_attention}
                    helperText={
                      siteValidations.site_ship_attention &&
                      manditoryFieldMessage
                    }
                  />
                  <TextField
                    variant="outlined"
                    label="Company Name"
                    id="site_ship_company"
                    onChange={siteEdit}
                    value={editedSite.site_ship_company}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_ship_company}
                    helperText={
                      siteValidations.site_ship_company && manditoryFieldMessage
                    }
                  />
                  <TextField
                    variant="outlined"
                    label="Address"
                    id="site_ship_address"
                    onChange={siteEdit}
                    value={editedSite.site_ship_address}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_ship_address}
                    helperText={
                      siteValidations.site_ship_address && manditoryFieldMessage
                    }
                  />
                  <TextField
                    variant="outlined"
                    label="City"
                    id="site_ship_city"
                    onChange={siteEdit}
                    value={editedSite.site_ship_city}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_ship_city}
                    helperText={
                      siteValidations.site_ship_city && manditoryFieldMessage
                    }
                  />
                  <FormControl sx={{ minWidth: { xs: "100%" }, p: 1, mt: 1 }}>
                    <InputLabel id="customer_state_label">State</InputLabel>
                    <Select
                      labelId="customer_state_label"
                      name="site_state"
                      id="site_ship_state"
                      label="State"
                      onChange={siteEdit}
                      value={editedSite.site_ship_state}
                      error={siteValidations.site_ship_state}
                    >
                      <MenuItem value="SELECT"> SELECT </MenuItem>
                      {usStates !== null &&
                        usStates.map((state) => (
                          <MenuItem key={state.name} value={state.abbreviation}>
                            {state.abbreviation}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <TextField
                    variant="outlined"
                    label="Zip"
                    id="site_ship_zip"
                    onChange={siteEdit}
                    value={editedSite.site_ship_zip}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                    error={siteValidations.site_ship_zip}
                    helperText={
                      siteValidations.site_ship_zip && manditoryFieldMessage
                    }
                  />
                </form>
              </CardContent>
            </Card>
            <Card elevation={4} sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h5" color="secondary" sx={{ mt: 1 }}>
                  Site Configuration Notes
                </Typography>
                <form>
                  <TextField
                    variant="outlined"
                    multiline
                    rows="10"
                    id="site_notes"
                    onChange={siteEdit}
                    value={editedSite.site_notes}
                    sx={{ minWidth: "100%", p: 1, mt: 2 }}
                  />
                </form>
              </CardContent>
            </Card>

            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ color: "white" }}
                  onClick={() => setOrderPage("siteSummary")}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ color: "white", float: "right" }}
                  onClick={editSiteToOrder}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : null}
      </Paper>
    </div>
  );
};

export default EditsiteToEdit;
