import React, { useState, useEffect } from "react";
import Galileo_logo from "../media/Galileo logo.png";
import "../css/Header.css";

const Header = () => {
  const [showNavBar, setShowNavBar] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showHomeLink, setShowHomeLink] = useState(false);
  const [currentPage, setCurrentPage] = useState("/");

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);
  useEffect(() => {
    if (currentPage === "/") {
      setShowHomeLink(false);
    } else {
      setShowHomeLink(true);
    }

    if (currentPage.includes("/admin")) {
      setShowNavBar(false);
    } else {
      setShowNavBar(true);
    }
  }, [currentPage]);

  const toggleMobileMenu = () => {
    if (showMobileMenu) {
      setShowMobileMenu(false);
    } else {
      setShowMobileMenu(true);
    }
  };
  if (showNavBar) {
    return (
      <div className="public_header_container">
        <div className="public_header_desktop_flexbox">
          <div className="public_header_logo">
            <a href="/">
              <img className="" src={Galileo_logo} alt="logo" />
            </a>
          </div>
          <div className="public_header_desktop_menu">
            <ul>
              {showHomeLink && (
                <li className="public_header_desktop_menu_items">
                  <a href="/">HOME</a>
                </li>
              )}
              <li className="public_header_desktop_menu_items ">
                <a
                  className={
                    currentPage === "/solutions" ? "CurrentPage" : undefined
                  }
                  href="/solutions"
                >
                  SOLUTIONS
                </a>
              </li>
              <li className="public_header_desktop_menu_items">
                <a
                  className={
                    currentPage === "/pricing" ? "CurrentPage" : undefined
                  }
                  href="/pricing"
                >
                  PRICING
                </a>
              </li>
              <li className="public_header_desktop_menu_items">
                <a
                  className={
                    currentPage === "/order" ? "CurrentPage" : undefined
                  }
                  href="/order"
                >
                  ORDER
                </a>
              </li>
              <li className="public_header_desktop_menu_items">
                <a
                  className={
                    currentPage === "/contact" ? "CurrentPage" : undefined
                  }
                  href="/contact"
                >
                  CONTACT
                </a>
              </li>
              <li className="public_header_desktop_menu_items">
                <a href="https://portal.galileoec.com/login">LOGIN</a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="public_header_mobile_menu_icon"
          onClick={toggleMobileMenu}
        >
          <div className="public_header_mobile_menu_icon_line"></div>
          <div className="public_header_mobile_menu_icon_line"></div>
          <div className="public_header_mobile_menu_icon_line"></div>
        </div>
        <div className="public_header_tagline">
          ENTERPRISE CONNECT &trade; - FAILOVER
        </div>
        {showMobileMenu && (
          <div className="public_header_mobile_menu_expanded">
            <div
              className="public_header_mobile_menu_exit_icon"
              onClick={toggleMobileMenu}
            >
              X
            </div>
            <div className="public_header_mobile_menu">
              <ul>
                {showHomeLink ? (
                  <li className="public_header_mobile_menu_items">
                    <a href="/">HOME</a>
                  </li>
                ) : null}
                <li className="public_header_mobile_menu_items">
                  <a
                    className={
                      currentPage === "/solutions" ? "CurrentPage" : undefined
                    }
                    href="/solutions"
                  >
                    SOLUTIONS
                  </a>
                </li>
                <li className="public_header_mobile_menu_items">
                  {" "}
                  <a
                    className={
                      currentPage === "/pricing" ? "CurrentPage" : undefined
                    }
                    href="/pricing"
                  >
                    PRICING
                  </a>
                </li>
                <li className="public_header_mobile_menu_items">
                  {" "}
                  <a
                    className={
                      currentPage === "/order" ? "CurrentPage" : undefined
                    }
                    href="/order"
                  >
                    ORDER
                  </a>
                </li>
                <li className="public_header_mobile_menu_items">
                  {" "}
                  <a
                    className={
                      currentPage === "/contact" ? "CurrentPage" : undefined
                    }
                    href="/contact"
                  >
                    CONTACT
                  </a>
                </li>
                <li className="public_header_mobile_menu_items">
                  <a href="https://portal.galileoec.com">LOGIN</a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default Header;
