import React, { useState, useEffect, useContext } from "react";
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";

import agentNavigationContext from "../../../context/agentNavigation/agentNavigationContext";

const OpportunityTable = ({ opportunities, setFocusedOpportunity }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [orderBy, setOrderBy] = useState("desc");
  const [order, setOrder] = useState("desc");
  const [searchBar, setSearchBar] = useState("");
  const [displayOpportunities, setDisplayopportunities] = useState(null);

  const { loadAgentPage } = useContext(agentNavigationContext);

  function viewHandler(opportunity) {
    loadAgentPage("view");
    setFocusedOpportunity(opportunity);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    if (opportunities === undefined) return;
    search();
    // eslint-disable-next-line
  }, [searchBar, opportunities]);

  const search = () => {
    if (opportunities !== null && opportunities !== undefined) {
      const searchResults = opportunities.filter((opportunity) => {
        const regex = new RegExp(`${searchBar}`, "gi");

        return opportunity.customer_company.match(regex);
      });

      setDisplayopportunities(searchResults);
    }
  };

  const handleSearch = (e) => {
    setSearchBar(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <Paper
      sx={{
        py: 2,
        px: { xs: 1, sm: 2, md: 10, l: 10, xl: 2 },
        minHeight: "80vh",
        overflow: "hidden",
      }}
    >
      <>
        <TableContainer sx={{ maxHeight: 700, mt: 2 }}>
          <Table size="large" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#2e68b1" }}>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    backgroundColor: "#2e68b1",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRequestSort("customer_company")}
                >
                  STATUS
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    backgroundColor: "#2e68b1",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRequestSort("company")}
                >
                  COMPANY
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    backgroundColor: "#2e68b1",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRequestSort("mobileID")}
                >
                  # OF SITES
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    backgroundColor: "#2e68b1",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRequestSort("carrier")}
                >
                  MONTHLY
                </TableCell>
                <TableCell
                  sx={{
                    color: "#e2e2e2",
                    textAlign: "center",
                    backgroundColor: "#2e68b1",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRequestSort("usage")}
                >
                  UPFRONT
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayOpportunities !== null &&
              displayOpportunities !== undefined
                ? displayOpportunities
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((opportunity) => (
                      <TableRow
                        hover
                        key={opportunity._id}
                        onClick={() => viewHandler(opportunity)}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell sx={{ textAlign: "center" }}>
                          {opportunity.status.toUpperCase()}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {opportunity.customer_company}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {opportunity.sites.length}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          ${opportunity.order_monthly.toFixed(2)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          ${opportunity.order_upfront.toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        {displayOpportunities !== null &&
        displayOpportunities !== undefined &&
        displayOpportunities.length > 12 ? (
          <TablePagination
            rowsPerPageOptions={[12, 25, 50, 100]}
            component="div"
            count={displayOpportunities.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              color: "#e2e2e2",
              backgroundColor: "#2e68b1",
            }}
          />
        ) : null}
      </>
    </Paper>
  );
};

export default OpportunityTable;
