import React, { useState } from "react";
import ContactEmail from "../../template/ContactEmail";
import axios from "axios";
import "../../css/Contact.css";
import {
  TextField,
  Card,
  CardContent,
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";

const Contact = () => {
  const [contactFormData, setContactFormData] = useState({
    company: "",
    contact: "",
    phone: "",
    email: "",
    message: "",
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const formEntry = (e) => {
    setContactFormData({ ...contactFormData, [e.target.id]: e.target.value });
  };

  const resetForm = () => {
    setContactFormData({
      company: "",
      contact: "",
      phone: "",
      email: "",
      message: "",
    });
  };

  const submitContactForm = async () => {
    const emailMessagebody = ContactEmail(contactFormData);
    const emailMessage = {
      company: contactFormData.company,
      contact: contactFormData.contact,
      phone: contactFormData.phone,
      email: contactFormData.email,
      message: contactFormData.message,
      subject: emailMessagebody.subject,
      html: emailMessagebody.html,
      text: emailMessagebody.text,
    };

    await axios
      .post("/api/contactUs", emailMessage)
      .then((response) => {
        if (response.status === 200) {
          setShowSuccessMessage(true);
          setContactFormData({
            company: "",
            contact: "",
            phone: "",
            email: "",
            message: "",
          });

          window.scroll(0, 0);

          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        }
      })
      .catch((error) => {
        window.scroll(0, 0);
        alert("ERROR SENDING EMAIL");
      });
  };

  return (
    <div className="public_contact_container">
      {showSuccessMessage && (
        <div className="public_contact_success_message">
          <div className="public_contact_success_message_text">
            YOUR CONTACT MESSAGE HAS BEEN SUBMITTED
          </div>
        </div>
      )}
      <Grid container flexDirection="row" sx={{ p: 4 }}>
        <Grid item xs={12} sm={6}>
          <Card elevation={4}>
            <CardContent>
              <Typography
                variant="h4"
                color="primary"
                sx={{ textAlign: "center", mt: 2 }}
              >
                Contact Us
              </Typography>
              <form>
                <TextField
                  variant="outlined"
                  label="Company"
                  id="company"
                  onChange={formEntry}
                  autoComplete="off"
                  value={contactFormData.company}
                  sx={{ minWidth: "100%", p: 1, mt: 2 }}
                />
                <TextField
                  variant="outlined"
                  label="Name"
                  id="contact"
                  onChange={formEntry}
                  autoComplete="off"
                  value={contactFormData.contact}
                  sx={{ minWidth: "100%", p: 1 }}
                />
                <TextField
                  variant="outlined"
                  label="Phone"
                  id="phone"
                  onChange={formEntry}
                  autoComplete="off"
                  value={contactFormData.phone}
                  sx={{ minWidth: "100%", p: 1 }}
                />
                <TextField
                  variant="outlined"
                  label="Email"
                  id="email"
                  onChange={formEntry}
                  autoComplete="off"
                  value={contactFormData.email}
                  sx={{ minWidth: "100%", p: 1 }}
                />
                <TextField
                  variant="outlined"
                  id="message"
                  onChange={formEntry}
                  autoComplete="off"
                  multiline
                  rows="6"
                  value={contactFormData.message}
                  sx={{ minWidth: "100%", p: 1 }}
                />
              </form>
              <Box>
                <Grid container sx={{ my: 3 }}>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      size="large"
                      color="error"
                      sx={{ color: "white", ml: 2, mt: 2 }}
                      onClick={resetForm}
                    >
                      Reset
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{ color: "white", mr: 2, mt: 2, float: "right" }}
                      onClick={submitContactForm}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container sx={{ mt: 4 }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant="h4" color="primary" sx={{ mt: 3 }}>
                Sales
              </Typography>
              <Typography variant="h5" color="secondary">
                888-939-1100 option 1
              </Typography>
              <Typography variant="h5" color="secondary">
                info@abttelecom.com
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant="h4" color="primary" sx={{ mt: 3 }}>
                Support
              </Typography>
              <Typography variant="h5" color="secondary">
                888-939-1100 option 2
              </Typography>
              <Typography variant="h5" color="secondary">
                support@abttelecom.com
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant="h4" color="primary" sx={{ mt: 3 }}>
                Address
              </Typography>
              <Typography variant="h5" color="secondary">
                9881 Broken Land Parkway, Suite 202
              </Typography>
              <Typography variant="h5" color="secondary">
                Columbia, MD 21046
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contact;
