import React, { useState, useContext } from "react";
import AlertContext from "../../context/alert/alertContext";
import {
  Avatar,
  Button,
  TextField,
  Typography,
  Container,
  Box,
} from "@mui/material/";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Controls from "../../controls/Controls";
import axios from "axios";
import PasswordValidator from "../../utils/PasswordValidator";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const ResetPassword = (props) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { setAlert } = useContext(AlertContext);
  const token = props.match.params.token;

  const [newPassword, setNewPassword] = useState({
    password: "",
    password2: "",
    resetPasswordToken: token,
  });

  const handleInput = (e) => {
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value });

    if (e.target.name === "password2") {
      if (newPassword === e.target.value) {
        setError(false);
        setErrorMessage("");
      }
    }
  };

  const submitChange = async (e) => {
    e.preventDefault();
    if (newPassword.password === newPassword.password2) {
      if (PasswordValidator(newPassword)) {
        await axios
          .post("/api/auth/updatepassword", newPassword, config)
          .then((response) => {
            if (response.data === "Invalid Token") {
              props.history.push("/forgotpassword");
              setAlert(true, "Token Expired. Send new reset request", "error");
            } else if (response.data === "Password Reset") {
              props.history.push("/login");
              setAlert(true, "Password Changed", "success");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setAlert(true, "Password not long enough", "error");
        setError(true);
        setErrorMessage("Password must be 8 characters long");
      }
    } else {
      setAlert(true, "Passwords must match", "error");
      setError(true);
      setErrorMessage("Passwords must match");
    }
  };
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 9,
          marginBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#2e66b0" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <Box component="form" onSubmit={submitChange} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            label="Enter New Password"
            type="password"
            name="password"
            autoComplete="email"
            autoFocus
            onChange={handleInput}
            error={error}
            helperText={errorMessage}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Re-Type New Password"
            type="password"
            name="password2"
            autoComplete="email"
            onChange={handleInput}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Update
          </Button>
        </Box>
        <Controls.NewAlert />
      </Box>
    </Container>
  );
};

export default ResetPassword;
