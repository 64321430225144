import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DateInput from "./DateInput";
import Button from "./Button";
import NewAlert from "./NewAlert";

const Controls = {
  Input,
  RadioGroup,
  Select,
  Checkbox,
  DateInput,
  Button,
  NewAlert,
};
export default Controls;
