import React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";

const Sites = ({
  displaySite,
  readOnly,
  formEntry,
  editedOpportunity,
  focusedSite,
  setFocusedSite,
}) => {
  return (
    <Grid container>
      <Grid item xs={2}>
        <Typography sx={{ fontWeight: "bold" }}>SITES</Typography>
        {editedOpportunity.sites !== undefined &&
          editedOpportunity.sites.map((site) => (
            <Box
              key={site.site_name}
              sx={{
                backgroundColor:
                  focusedSite === site.site_name ? "orange" : "white",
                // mt: 0.5,
                p: 1,
                cursor: "pointer",
                borderTop: 1,
                borderLeft: 1,
                borderBottom: 1,
                borderRight: 1,
              }}
              onClick={() => setFocusedSite(site.site_name)}
            >
              <Typography
                variant="body1"
                sx={{
                  color: focusedSite === site.site_name ? "white" : "black",
                  fontWeight: "bold",
                }}
              >
                {site.site_name}
              </Typography>
            </Box>
          ))}
      </Grid>
      <Grid item xs={10} sx={{ pl: 2, pr: 1 }}>
        {displaySite !== undefined && (
          <>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant="body1" sx={{ fontWeight: "light", pl: 2 }}>
                  PLAN CODE: {displaySite.plan_sku}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "light", textAlign: "right" }}
                >
                  MONTHLY: ${parseInt(displaySite.site_monthly).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "light",
                    textAlign: "right",
                    pr: 2,
                  }}
                >
                  UPFRONT: ${parseInt(displaySite.site_upfront).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={12} xl={6} sx={{ mt: 2 }}>
                <TextField
                  variant="outlined"
                  label="Site Company"
                  id="site_company"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={displaySite.site_company}
                  value={displaySite.site_company}
                  sx={{ minWidth: "100%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
                <TextField
                  variant="outlined"
                  label="Site Address"
                  id="site_address"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={displaySite.site_address}
                  value={displaySite.site_address}
                  sx={{ minWidth: "100%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
                <TextField
                  variant="outlined"
                  label="City"
                  id="site_city"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={displaySite.site_city}
                  value={displaySite.site_city}
                  sx={{ width: "60%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
                <TextField
                  variant="outlined"
                  label="State"
                  id="site_state"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={displaySite.site_ship_state}
                  value={displaySite.site_ship_state}
                  sx={{ width: "15%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
                <TextField
                  variant="outlined"
                  label="Zip"
                  id="site_zip"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={displaySite.site_zip}
                  value={displaySite.site_zip}
                  sx={{ width: "25%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
              </Grid>
              <Grid item xs={12} xl={6} sx={{ mt: 2 }}>
                <TextField
                  variant="outlined"
                  label="Shipping Company"
                  id="site_ship_company"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={displaySite.site_ship_company}
                  value={displaySite.site_ship_company}
                  sx={{ minWidth: "100%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
                <TextField
                  variant="outlined"
                  label="Shipping Address"
                  id="site_ship_address"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={displaySite.site_ship_address}
                  value={displaySite.site_ship_address}
                  sx={{ minWidth: "100%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
                <TextField
                  variant="outlined"
                  label="City"
                  id="site_ship_city"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={displaySite.site_ship_city}
                  value={displaySite.site_ship_city}
                  sx={{ width: "60%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
                <TextField
                  variant="outlined"
                  label="State"
                  id="site_ship_state"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={displaySite.site_ship_state}
                  value={displaySite.site_ship_state}
                  sx={{ width: "15%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
                <TextField
                  variant="outlined"
                  label="Zip"
                  id="site_ship_zip"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={displaySite.site_ship_zip}
                  value={displaySite.site_ship_zip}
                  sx={{ width: "25%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              label="Site Notes"
              id="site_notes"
              size="small"
              multiline
              rows={4}
              disabled={readOnly}
              onChange={formEntry}
              defaultValue={displaySite.site_notes}
              value={displaySite.site_notes}
              sx={{ width: "100%", p: 1, mt: 1 }}
              // error={contactValidation.company}
              // helperText={contactValidation.company && manditoryFieldMessage}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Sites;
