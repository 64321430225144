import React from "react";
import "../css/Footer.css";
import ABTlogo from "../media/AB&T.png";

const Footer = () => {
  return (
    <div className="public_footer_container">
      <div className="public_header_mobile_flexbox">
        <img src={ABTlogo} alt="AB&T Telecom Logo" />
        <div className="public_footer_contact_info">
          <ul>
            <li className="public_footer_contact_info_items">
              CALL: <span>1-888-939-1100</span>
            </li>
            <li className="public_footer_contact_info_items">
              EMAIL: <span>INFO@ABTTELECOM.COM</span>
            </li>
            <li className="public_footer_contact_info_items">
              URL: <span>WWW.GALILEOEC.COM</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
