const contactEmail = (data) => {
  const htmlMessage = `
<br>
A Contact email has been submitted from the Galileo Enterprise Connect WEBSITE
<br>
<br>
Name: ${data.contact} <br>
Company: ${data.company} <br>
Phone: ${data.phone}<br>
Email: ${data.email}<br>
Message:<br>
<p>
${data.message}
</p>
`;

  const textMessage = `
A Contact email has been submitted from the Galileo Enterprise Connect WEBSITE


Contact: ${data.contact}
Company: ${data.company}
Phone: ${data.phone}
Email: ${data.email}
Message:
${data.message}

`;

  return {
    subject: "GALILEO ENTERPRISE CONNECT WEBSITE CONTACT FORM",
    html: htmlMessage,
    text: textMessage,
  };
};

export default contactEmail;
