import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import mmddyy from "../../../utils/mmddyy";
import Paper from "@mui/material/Paper";

const OrderList = ({ orders, viewOrder }) => {
  return (
    <React.Fragment>
      <Paper
        elevation={6}
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Title>Online Orders</Title>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ background: "#1976d2" }}>
              <TableCell sx={{ color: "white" }}>Order Date</TableCell>
              <TableCell sx={{ color: "white" }}>Customer Name</TableCell>
              <TableCell sx={{ color: "white" }} align="right">
                Monthly
              </TableCell>
              <TableCell sx={{ color: "white" }} align="right">
                Upfront
              </TableCell>
              <TableCell align="center" sx={{ color: "white" }}>
                # of Sites
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders !== null &&
              orders.map((order) => (
                <TableRow
                  key={order._id}
                  hover={true}
                  sx={{ cursor: "pointer" }}
                  onClick={() => viewOrder(order)}
                >
                  <TableCell className="pointer">
                    {mmddyy(order.register_date)}
                  </TableCell>
                  <TableCell className="pointer">
                    {order.customer_company}
                  </TableCell>
                  <TableCell align="right">
                    ${order.order_monthly.toFixed(2)}
                  </TableCell>
                  <TableCell align="right">
                    ${order.order_upfront.toFixed(2)}
                  </TableCell>
                  <TableCell align="center">{order.sites.length}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
};

export default OrderList;
