import React, { useContext, useState, useEffect } from "react";
import AlertContext from "../../../../context/alert/alertContext";
import AdminNavContext from "../../../../context/adminNavigation/adminNavContext";
import { useApi } from "../../../../api/useApi";
import { putResource, fetchResources } from "../../../../api/ApiFunctions";
import { useForm, Form } from "../../../../controls/useForm";
import Controls from "../../../../controls/Controls";
import { Typography, Grid, Card } from "@mui/material";

const ViewUser = ({ userToEdit }) => {
  const { loadAdminPage } = useContext(AdminNavContext);
  const [currentUsers, setCurrentUsers] = useState(null);
  const [noEdit, setNoEdit] = useState(true);
  const { setAlert } = useContext(AlertContext);
  const [fetchedData, setFectchDataResponse] = useApi(() =>
    fetchResources("auth/all")
  );

  const updateUser = async (e) => {
    e.preventDefault();
    const errorCheck = validateBeforeSubmit();
    if (errorCheck) {
      const dataToSubmit = {
        First_Name: values.First_Name,
        Last_Name: values.Last_Name,
        Username: values.Username,
        Role: values.Role,
        Password: values.Password,
        active: true,
      };

      await putResource("auth", dataToSubmit, userToEdit._id)
        .then((response) => {
          resetForm();
          loadAdminPage("usersList");
          setAlert(true, "User Edited Successfully", "success");
        })
        .catch((error) => {
          setAlert(true, "Server Error", "error");
        });
    }
  };

  const validateBeforeSubmit = () => {
    let temp = { ...errors };
    temp.Username =
      values.Username.length !== 0 ? "" : "This field is required.";
    temp.First_Name =
      values.First_Name.length !== 0 ? "" : "This field is required.";
    temp.Last_Name =
      values.Last_Name.length !== 0 ? "" : "This field is required.";
    temp.Role = values.Role.length !== 0 ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    setFectchDataResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fetchedData.error === null) {
      setCurrentUsers(fetchedData.data);
    }
  }, [fetchedData]);

  const { values, errors, setErrors, resetForm, handleInputChange } = useForm(
    userToEdit,
    false
  );

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const customStyles = {
    input: {
      margin: 1,
      minWidth: "95%",
      ":focused": {
        color: "red",
      },
    },
  };

  const cancelEdit = () => {
    toggleEdit();
    resetForm();
  };

  const toggleEdit = () => {
    setNoEdit(!noEdit);
  };

  return (
    <>
      <Typography variant="h6" sx={{ color: "#1976d2" }}>
        User Profile
      </Typography>
      <Card elevation={4} sx={{ p: 4 }}>
        <Form onSubmit={updateUser}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="First_Name"
                label="First Name"
                value={values.First_Name}
                onChange={handleInputChange}
                error={errors.First_Name}
                sx={customStyles.input}
                disabled={noEdit}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="Last_Name"
                label="Last Name"
                value={values.Last_Name}
                onChange={handleInputChange}
                error={errors.Last_Name}
                sx={customStyles.input}
                disabled={noEdit}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="Username"
                label="Username"
                value={values.Username}
                onChange={handleInputChange}
                error={errors.Username}
                sx={customStyles.input}
                disabled={noEdit}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="Role"
                label="Role"
                value={values.Role}
                onChange={handleInputChange}
                error={errors.Role}
                sx={customStyles.input}
                disabled={noEdit}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Controls.Input
                type="password"
                name="Password"
                label="Password"
                value={values.Password}
                onChange={handleInputChange}
                error={errors.Password}
                sx={customStyles.input}
                disabled={noEdit}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                type="password"
                name="Password2"
                label="Retype Password"
                value={values.Password2}
                onChange={handleInputChange}
                error={errors.Password2}
                sx={customStyles.input}
                disabled={noEdit}
              />
            </Grid>
          </Grid>
        </Form>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{ mt: 2, mr: 3 }}
        >
          <Grid item>
            {noEdit ? (
              <Controls.Button
                text="Back"
                type="submit"
                color="error"
                onClick={() => loadAdminPage("usersList")}
              />
            ) : (
              <Controls.Button
                text="Cancel"
                type="submit"
                color="error"
                onClick={cancelEdit}
              />
            )}
            {noEdit ? (
              <Controls.Button
                text="Edit"
                type="submit"
                onClick={toggleEdit}
                color="success"
              />
            ) : (
              <Controls.Button
                text="Update"
                type="submit"
                onClick={updateUser}
                color="success"
              />
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ViewUser;
