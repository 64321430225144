import React, { useState } from "react";
import ContactValidations from "../../utils/ContactValidations";
import usStates from "../../utils/States";
import Box from "@mui/material/Box";
import {
  TextField,
  Typography,
  Card,
  Grid,
  Paper,
  Button,
} from "@mui/material/";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const OrderContact = ({
  formEntry,
  CancelOrderEntry,
  setOrderPage,
  orderFormData,
}) => {
  const [contactValidation, setContactValidation] = useState({
    overall: true,
    company: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    order_contact_name: false,
    order_contact_phone: false,
    order_contact_email: false,
  });
  const nextPage = () => {
    const validated = ContactValidations(orderFormData);
    setContactValidation(validated);
    if (validated.overall) {
      setOrderPage("siteSummary");
    }
    window.scroll(0, 0);
  };

  const manditoryFieldMessage = "------ MANDITORY FIELD ------";

  return (
    <div className="public_order_container public_order_contacts_container">
      <Paper elevation={4} sx={{ p: 2 }}>
        <Typography
          variant="h3"
          color="primary"
          sx={{ textAlign: "center", mt: 3 }}
        >
          Enterprise Connect &trade; Order Form
        </Typography>
        <Box sx={{ mt: 5, minWidth: "100%", p: 2 }}>
          <Typography
            variant="h4"
            color="secondary"
            sx={{ textAlign: "center" }}
          >
            CUSTOMER INFORMATION
          </Typography>
          <Card elevation={4} sx={{ p: 3, minWidth: "100%", mt: 2 }}>
            <TextField
              variant="outlined"
              label="Company Name"
              id="customer_company"
              onChange={formEntry}
              value={orderFormData.customer_company}
              sx={{ minWidth: "100%", p: 1 }}
              error={contactValidation.company}
              helperText={contactValidation.company && manditoryFieldMessage}
            />
            <TextField
              variant="outlined"
              label="Address"
              id="customer_address"
              onChange={formEntry}
              value={orderFormData.customer_address}
              sx={{ minWidth: "100%", p: 1 }}
              error={contactValidation.address}
              helperText={contactValidation.address && manditoryFieldMessage}
            />
            <TextField
              variant="outlined"
              label="City"
              id="customer_city"
              onChange={formEntry}
              value={orderFormData.customer_city}
              sx={{ minWidth: { xs: "100%", sm: "45%" }, p: 1 }}
              error={contactValidation.city}
              helperText={contactValidation.city && manditoryFieldMessage}
            />
            <FormControl sx={{ minWidth: { xs: "100%", sm: "20%" }, p: 1 }}>
              <InputLabel id="customer_state_label">State</InputLabel>
              <Select
                labelId="customer_state_label"
                name="customer_state"
                label="State"
                onChange={formEntry}
                value={orderFormData.customer_state}
                error={contactValidation.state}
              >
                <MenuItem value="SELECT"> SELECT </MenuItem>
                {usStates !== null &&
                  usStates.map((state) => (
                    <MenuItem key={state.name} value={state.abbreviation}>
                      {state.abbreviation}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              label="Zip"
              id="customer_zip"
              onChange={formEntry}
              value={orderFormData.customer_zip}
              sx={{ minWidth: { xs: "100%", sm: "20%" }, p: 1 }}
              error={contactValidation.zip}
              helperText={contactValidation.zip && manditoryFieldMessage}
            />
          </Card>
        </Box>
        <Typography
          variant="h4"
          color="secondary"
          sx={{ textAlign: "center", mt: 5 }}
        >
          CONTACT INFORMATION
        </Typography>
        <Grid container>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 1, minWidth: "100%", p: 2 }}>
              <Card elevation={4} sx={{ p: 1, minWidth: "100%", mt: 2 }}>
                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{ textAlign: "left" }}
                >
                  ORDER
                </Typography>
                <TextField
                  variant="outlined"
                  label="Name"
                  id="order_contact_name"
                  onChange={formEntry}
                  value={orderFormData.order_contact_name}
                  sx={{ minWidth: "100%", p: 1 }}
                  error={contactValidation.order_contact_name}
                  helperText={
                    contactValidation.order_contact_name &&
                    manditoryFieldMessage
                  }
                />
                <TextField
                  variant="outlined"
                  label="Phone"
                  id="order_contact_phone"
                  onChange={formEntry}
                  value={orderFormData.order_contact_phone}
                  sx={{ minWidth: "100%", p: 1 }}
                  error={contactValidation.order_contact_phone}
                  helperText={
                    contactValidation.order_contact_phone &&
                    manditoryFieldMessage
                  }
                />
                <TextField
                  variant="outlined"
                  label="Email"
                  id="order_contact_email"
                  onChange={formEntry}
                  value={orderFormData.order_contact_email}
                  sx={{ minWidth: { xs: "100%", sm: "100%" }, p: 1 }}
                  error={contactValidation.order_contact_email}
                  helperText={
                    contactValidation.order_contact_email &&
                    manditoryFieldMessage
                  }
                />
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 1, minWidth: "100%", p: 2 }}>
              <Card elevation={4} sx={{ p: 1, minWidth: "100%", mt: 2 }}>
                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{ textAlign: "left" }}
                >
                  TECHNICAL
                </Typography>
                <TextField
                  variant="outlined"
                  label="Name"
                  id="technical_contact_name"
                  onChange={formEntry}
                  value={orderFormData.technical_contact_name}
                  sx={{ minWidth: "100%", p: 1 }}
                />
                <TextField
                  variant="outlined"
                  label="Phone"
                  id="technical_contact_phone"
                  onChange={formEntry}
                  value={orderFormData.technical_contact_phone}
                  sx={{ minWidth: "100%", p: 1 }}
                />
                <TextField
                  variant="outlined"
                  label="Email"
                  id="technical_contact_email"
                  onChange={formEntry}
                  value={orderFormData.technical_contact_email}
                  sx={{ minWidth: { xs: "100%", sm: "100%" }, p: 1 }}
                />
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 1, minWidth: "100%", p: 2 }}>
              <Card elevation={4} sx={{ p: 1, minWidth: "100%", mt: 2 }}>
                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{ textAlign: "left" }}
                >
                  BILLING
                </Typography>
                <TextField
                  variant="outlined"
                  label="Name"
                  id="billing_contact_name"
                  onChange={formEntry}
                  value={orderFormData.billing_contact_name}
                  sx={{ minWidth: "100%", p: 1 }}
                />
                <TextField
                  variant="outlined"
                  label="Phone"
                  id="billing_contact_phone"
                  onChange={formEntry}
                  value={orderFormData.billing_contact_phone}
                  sx={{ minWidth: "100%", p: 1 }}
                />
                <TextField
                  variant="outlined"
                  label="Email"
                  id="billing_contact_email"
                  onChange={formEntry}
                  value={orderFormData.billing_contact_email}
                  sx={{ minWidth: { xs: "100%", sm: "100%" }, p: 1 }}
                />
              </Card>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ minWidth: "100%", p: 2 }}>
          <Typography
            variant="h5"
            color="secondary"
            sx={{ textAlign: "center" }}
          >
            REFERRAL INFORMATION
          </Typography>
          <Typography variant="subtitle1">
            Please provide the name and company of the person that referred you
            to AB&T Telecom
          </Typography>
          <TextField
            variant="outlined"
            sx={{ minWidth: "100%" }}
            id="referred_by"
            onChange={formEntry}
            label="Referred By"
            defaultValue={orderFormData.referred_by}
          />
        </Box>

        <Box>
          <Grid container>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                color="error"
                sx={{ color: "white", ml: 2, mt: 2 }}
                onClick={CancelOrderEntry}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ color: "white", mr: 2, mt: 2, float: "right" }}
                onClick={nextPage}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};

export default OrderContact;
