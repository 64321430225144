import React, { useState, useEffect } from "react";
// import Controls from "../../controls/Controls";
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useApi } from "../../api/useApi";
import { fetchResources } from "../../api/ApiFunctions";
import FilterListIcon from "@mui/icons-material/FilterList";

const BundleSelector = ({
  selectedHardware,
  setSelectedHardware,
  selectedTerm,
  setSelectedTerm,
  selectedProvider,
  setSelectedProvider,
}) => {
  const [terms, setTerms] = useState(null);
  const [hardware, setHardware] = useState(null);
  const [providers, setProviders] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [bundlesData, setBundlesDataResponse] = useApi(() =>
    fetchResources("bundles")
  );

  useEffect(() => {
    setBundlesDataResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    var hardwareList = [];

    if (bundlesData.data !== null) {
      bundlesData.data.map((bundle) => {
        function containsObject(obj, list) {
          var i;
          for (i = 0; i < list.length; i++) {
            if (list[i].title === obj.manufacturer) {
              return true;
            }
          }

          return false;
        }

        const contains = containsObject(bundle, hardwareList);

        if (!contains && bundle.active) {
          hardwareList.push({
            _id: bundle._id,
            title: bundle.manufacturer,
            value: bundle.manufacturer,
          });
        }
        return null;
      });

      setHardware(hardwareList);
    }
    // eslint-disable-next-line
  }, [bundlesData]);

  useEffect(() => {
    var termsList = [];

    if (bundlesData.data !== null) {
      bundlesData.data.map((bundle) => {
        function containsObject(obj, list) {
          var i;
          for (i = 0; i < list.length; i++) {
            if (list[i].title === obj.term) {
              return true;
            }
          }

          return false;
        }

        const contains = containsObject(bundle, termsList);

        if (!contains && bundle.active) {
          termsList.push({
            _id: bundle._id,
            title: bundle.term,
            value: bundle.term,
          });
        }
        return null;
      });

      setTerms(termsList);
    }
    // eslint-disable-next-line
  }, [bundlesData]);

  useEffect(() => {
    var providerList = [];

    if (bundlesData.data !== null) {
      bundlesData.data.map((bundle) => {
        function containsObject(obj, list) {
          var i;
          for (i = 0; i < list.length; i++) {
            if (list[i].title === obj.provider) {
              return true;
            }
          }

          return false;
        }

        const contains = containsObject(bundle, providerList);

        if (!contains && bundle.active) {
          providerList.push({
            _id: bundle._id,
            title: bundle.provider,
            value: bundle.provider,
          });
        }
        return null;
      });

      setProviders(providerList);
    }
    // eslint-disable-next-line
  }, [bundlesData]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const changeHardware = (e) => {
    setSelectedHardware(e.target.value);
  };
  const changeTerm = (e) => {
    setSelectedTerm(e.target.value);
  };
  const changeProvider = (e) => {
    setSelectedProvider(e.target.value);
  };

  return (
    <div className="bundleSelector_container">
      <Tooltip title="Filter Bundles">
        <IconButton onClick={toggleFilters} sx={{ mb: 2 }}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      {showFilters ? (
        <form id="bundle filters">
          <Grid container sx={{ mt: 2, padding: 2 }} flexDirection="row">
            <Grid item xs={12} md={2}>
              <Typography variant="h6" sx={{ mt: 2 }}>
                FILTER BUNDLES:{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} sx={{ ml: 2, mt: { xs: 2, sm: 0 } }}>
              <FormControl variant="outlined">
                <InputLabel>Manufacturer</InputLabel>
                <Select
                  name="selectedHardware"
                  value={selectedHardware}
                  onChange={changeHardware}
                  options={hardware}
                  sx={{ minWidth: "300px" }}
                >
                  <MenuItem key="ALL" value="ALL">
                    ALL
                  </MenuItem>
                  {hardware !== null &&
                    hardware.map((item) => (
                      <MenuItem key={item._id} value={item.value}>
                        {item.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} sx={{ ml: 2, mt: { xs: 2, sm: 0 } }}>
              <FormControl>
                <InputLabel>Term</InputLabel>
                <Select
                  name="selectedTerm"
                  value={selectedTerm}
                  onChange={changeTerm}
                  sx={{ minWidth: "300px" }}
                >
                  <MenuItem key="ALL" value="ALL">
                    ALL
                  </MenuItem>
                  {terms !== null &&
                    terms.map((item) => (
                      <MenuItem key={item._id} value={item.value}>
                        {item.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3} sx={{ ml: 2, mt: { xs: 2, sm: 0 } }}>
              <FormControl>
                <InputLabel>Provider</InputLabel>
                <Select
                  name="selectedProvider"
                  value={selectedProvider}
                  onChange={changeProvider}
                  sx={{ minWidth: "300px" }}
                >
                  <MenuItem key="ALL" value="ALL">
                    ALL
                  </MenuItem>
                  {providers !== null &&
                    providers.map((item) => (
                      <MenuItem key={item._id} value={item.value}>
                        {item.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      ) : null}
    </div>
  );
};

export default BundleSelector;
