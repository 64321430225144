import React from "react";
import { Paper, TextField, Typography } from "@mui/material";

const Technical = ({ newOpportunity, setNewOpportunity, setBottomNav }) => {
  const formEntry = (e) => {
    if (e.target.name === "customer_state") {
      setNewOpportunity({ ...newOpportunity, customer_state: e.target.value });
    } else {
      setNewOpportunity({ ...newOpportunity, [e.target.id]: e.target.value });
    }
  };

  return (
    <Paper
      sx={{
        mt: 2,
        py: 5,
        px: { xs: 1, sm: 2, md: 10, l: 10, xl: 4 },
        minHeight: "70vh",
        overflow: "hidden",
      }}
    >
      <Typography variant="h6" color="secondary" sx={{ textAlign: "left" }}>
        TECHNICAL DETAILS
      </Typography>
      <TextField
        variant="outlined"
        label="Techinal Contact Name"
        id="technical_contact_name"
        onChange={formEntry}
        value={newOpportunity.technical_contact_name}
        sx={{ minWidth: "100%", p: 1, mt: 1 }}
      />
      <TextField
        variant="outlined"
        label="Phone"
        id="technical_contact_phone"
        onChange={formEntry}
        value={newOpportunity.technical_contact_phone}
        sx={{ minWidth: "100%", p: 1, mt: 1 }}
      />
      <TextField
        variant="outlined"
        label="Email"
        id="technical_contact_email"
        onChange={formEntry}
        value={newOpportunity.technical_contact_email}
        sx={{ minWidth: { xs: "100%", sm: "100%" }, p: 1, mt: 1 }}
      />
      <TextField
        variant="outlined"
        label="Technical Notes"
        id="technical_notes"
        multiline
        rows={4}
        onChange={formEntry}
        value={newOpportunity.technical_notes}
        sx={{ minWidth: { xs: "100%", sm: "100%" }, p: 1, mt: 1 }}
      />
    </Paper>
  );
};

export default Technical;
