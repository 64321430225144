import React, { useContext, useState } from "react";
import AdminNavContext from "../../../../context/adminNavigation/adminNavContext";
import PlanSizesList from "./PlanSizesList";
import EditPlanSize from "./EditPlanSize";
import NewPlanSize from "./NewPlanSize";

const Terms = () => {
  const { currentPage } = useContext(AdminNavContext);
  const [planSizeToEdit, setPlanSizeToEdit] = useState(null);

  switch (currentPage) {
    default:
    case "planSizeList":
      return <PlanSizesList setPlanSizeToEdit={setPlanSizeToEdit} />;
    case "createPlanSize":
      return <NewPlanSize />;
    case "editPlanSize":
      return <EditPlanSize planSizeToEdit={planSizeToEdit} />;
  }
};

export default Terms;
