import React, { useContext, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AgentNavigationContext from "../../context/agentNavigation/agentNavigationContext";
import CreateOpportunities from "./create/CreateOpportunities";
import OpenOpportunities from "./lists/OpenOpportunities";
import ClosedOpportunities from "./lists/ClosedOpportunities";
import ViewOpportunity from "./view/ViewOpportunity";

const DashboardBody = () => {
  const { currentPage } = useContext(AgentNavigationContext);
  const [focusedOpportunity, setFocusedOpportunity] = useState({});

  switch (currentPage) {
    default:
    case "opportunities":
    case "open":
      return (
        <div>
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <OpenOpportunities
                  setFocusedOpportunity={setFocusedOpportunity}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    case "view":
      return (
        <div>
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <ViewOpportunity focusedOpportunity={focusedOpportunity} />
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    case "new":
      return (
        <div>
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <CreateOpportunities />
              </Grid>
            </Grid>
          </Container>
        </div>
      );

    case "closed":
      return (
        <div>
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <ClosedOpportunities
                  setFocusedOpportunity={setFocusedOpportunity}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      );
  }
};

export default DashboardBody;
