import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import OrderContact from "./OrderContact";
import OrderBilling from "./OrderBilling";
import OrderSiteSummary from "./OrderSiteSummary";
import OrderSummary from "./OrderSummary";
import OrderAddSite from "./OrderAddSite";
import ViewSiteOrder from "./ViewSiteOrder";
import EditSiteOrder from "./EditSiteOrder";
import axios from "axios";
import "../../css/Orders.css";

const Order = () => {
  const [orderPage, setOrderPage] = useState("contact");
  const [viewSiteIndex, setViewSiteIndex] = useState(null);
  const [bundles, setBundles] = useState(null);
  const [orderFormData, setOrderFormData] = useState({
    customer_company: "",
    customer_address: "",
    customer_city: "",
    customer_state: "SELECT",
    customer_zip: "",
    order_contact_name: "",
    order_contact_phone: "",
    order_contact_email: "",
    technical_contact_name: "",
    technical_contact_phone: "",
    technical_contact_email: "",
    billing_contact_name: "",
    billing_contact_phone: "",
    billing_contact_email: "",
    order_monthly: 0,
    order_upfront: 0,
    sites: [],
    card_type: "",
    card_number: "",
    card_date: "",
    card_dateA: "",
    card_dateB: "",
    card_code: "",
    card_name: "",
    card_company: "",
    card_address: "",
    card_city: "",
    card_state: "",
    card_zip: "",
    referred_by: "",
  });

  useEffect(() => {
    getBundles();
  }, []);

  const getBundles = async () => {
    const res = await axios.get("/api/bundles");
    setBundles(res.data);
  };

  const formEntry = (e) => {
    if (e.target.id === undefined) {
      setOrderFormData({ ...orderFormData, [e.target.name]: e.target.value });
    } else {
      setOrderFormData({ ...orderFormData, [e.target.id]: e.target.value });
    }
  };

  const CancelOrderEntry = () => {
    setOrderFormData({
      customer_company: "",
      customer_address: "",
      customer_city: "",
      customer_state: "SELECT",
      customer_zip: "",
      order_contact_name: "",
      order_contact_phone: "",
      order_contact_email: "",
      technical_contact_name: "",
      technical_contact_phone: "",
      technical_contact_email: "",
      billing_contact_name: "",
      billing_contact_phone: "",
      billing_contact_email: "",
      order_monthly: 0,
      order_upfront: 0,
      sites: [],
      card_type: "",
      card_number: "",
      card_date: "",
      card_dateA: "",
      card_dateB: "",
      card_code: "",
      card_name: "",
      card_company: "",
      card_address: "",
      card_city: "",
      card_state: "",
      card_zip: "",
      referred_by: "",
    });
    setOrderPage("cancelOrderEntry");
    window.scroll(0, 0);
  };

  const backButton = () => {
    window.scroll(0, 0);
  };

  window.addEventListener("popstate", backButton);
  window.history.pushState({}, "Order", "/order");

  switch (orderPage) {
    default:
    case "contact":
      return (
        <OrderContact
          formEntry={formEntry}
          CancelOrderEntry={CancelOrderEntry}
          orderFormData={orderFormData}
          setOrderPage={setOrderPage}
        />
      );
    case "billing":
      return (
        <OrderBilling
          formEntry={formEntry}
          CancelOrderEntry={CancelOrderEntry}
          orderFormData={orderFormData}
          setOrderPage={setOrderPage}
          setOrderFormData={setOrderFormData}
        />
      );
    case "siteSummary":
      return (
        <OrderSiteSummary
          formEntry={formEntry}
          CancelOrderEntry={CancelOrderEntry}
          setOrderPage={setOrderPage}
          orderFormData={orderFormData}
          setViewSiteIndex={setViewSiteIndex}
        />
      );
    case "orderSummary":
      return (
        <OrderSummary
          formEntry={formEntry}
          CancelOrderEntry={CancelOrderEntry}
          setOrderPage={setOrderPage}
          orderFormData={orderFormData}
          setOrderFormData={setOrderFormData}
        />
      );
    case "addSite":
      return (
        <OrderAddSite
          bundles={bundles}
          formEntry={formEntry}
          CancelOrderEntry={CancelOrderEntry}
          setOrderPage={setOrderPage}
          orderFormData={orderFormData}
          setOrderFormData={setOrderFormData}
        />
      );
    case "viewSite":
      return (
        <ViewSiteOrder
          viewSiteIndex={viewSiteIndex}
          setViewSiteIndex={setViewSiteIndex}
          bundles={bundles}
          formEntry={formEntry}
          CancelOrderEntry={CancelOrderEntry}
          setOrderPage={setOrderPage}
          orderFormData={orderFormData}
          setOrderFormData={setOrderFormData}
        />
      );

    case "editSite":
      return (
        <EditSiteOrder
          viewSiteIndex={viewSiteIndex}
          setViewSiteIndex={setViewSiteIndex}
          bundles={bundles}
          formEntry={formEntry}
          CancelOrderEntry={CancelOrderEntry}
          setOrderPage={setOrderPage}
          orderFormData={orderFormData}
          setOrderFormData={setOrderFormData}
        />
      );
    case "cancelOrderEntry":
      return <Redirect push to="/" />;
  }
};

export default Order;
