import React from "react";
import { Grid, TextField } from "@mui/material";

export const CustomerInformation = ({
  focusedOpportunity,
  editedOpportunity,
  formEntry,
  readOnly,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} xl={6}>
        <TextField
          variant="outlined"
          label="Company Name"
          id="customer_company"
          size="small"
          disabled={readOnly}
          onChange={formEntry}
          defaultValue={focusedOpportunity.customer_company}
          value={editedOpportunity.customer_company}
          sx={{ minWidth: "100%", p: 1, mt: 1 }}
          // error={contactValidation.company}
          // helperText={contactValidation.company && manditoryFieldMessage}
        />

        <TextField
          variant="outlined"
          label="Company Address"
          id="customer_address"
          size="small"
          disabled={readOnly}
          onChange={formEntry}
          defaultValue={focusedOpportunity.customer_address}
          value={editedOpportunity.customer_address}
          sx={{ minWidth: "100%", p: 1, mt: 1 }}
          // error={contactValidation.company}
          // helperText={contactValidation.company && manditoryFieldMessage}
        />

        <TextField
          variant="outlined"
          label="City"
          id="customer_city"
          size="small"
          disabled={readOnly}
          onChange={formEntry}
          defaultValue={focusedOpportunity.customer_city}
          value={editedOpportunity.customer_city}
          sx={{ width: "60%", p: 1, mt: 1 }}
          // error={contactValidation.company}
          // helperText={contactValidation.company && manditoryFieldMessage}
        />
        <TextField
          variant="outlined"
          label="State"
          id="customer_state"
          size="small"
          disabled={readOnly}
          onChange={formEntry}
          defaultValue={focusedOpportunity.customer_state}
          value={editedOpportunity.customer_state}
          sx={{ width: "15%", p: 1, mt: 1 }}
          // error={contactValidation.company}
          // helperText={contactValidation.company && manditoryFieldMessage}
        />

        <TextField
          variant="outlined"
          label="Zip"
          id="customer_zip"
          size="small"
          disabled={readOnly}
          onChange={formEntry}
          defaultValue={focusedOpportunity.customer_zip}
          value={editedOpportunity.customer_zip}
          sx={{ width: "25%", p: 1, mt: 1 }}
          // error={contactValidation.company}
          // helperText={contactValidation.company && manditoryFieldMessage}
        />
      </Grid>
      <Grid item xs={12} xl={6}>
        <TextField
          variant="outlined"
          label="Order Contact"
          id="order_contact_name"
          size="small"
          disabled={readOnly}
          onChange={formEntry}
          defaultValue={focusedOpportunity.order_contact_name}
          value={editedOpportunity.order_contact_name}
          sx={{ minWidth: "100%", p: 1, mt: 1 }}
          // error={contactValidation.company}
          // helperText={contactValidation.company && manditoryFieldMessage}
        />

        <TextField
          variant="outlined"
          label="Phone"
          id="order_contact_phone"
          size="small"
          disabled={readOnly}
          onChange={formEntry}
          defaultValue={focusedOpportunity.order_contact_phone}
          value={editedOpportunity.order_contact_phone}
          sx={{ minWidth: "100%", p: 1, mt: 1 }}
          // error={contactValidation.company}
          // helperText={contactValidation.company && manditoryFieldMessage}
        />

        <TextField
          variant="outlined"
          label="Email"
          id="order_contact_email"
          size="small"
          disabled={readOnly}
          onChange={formEntry}
          defaultValue={focusedOpportunity.order_contact_email}
          value={editedOpportunity.order_contact_email}
          sx={{ minWidth: "100%", p: 1, mt: 1 }}
          // error={contactValidation.company}
          // helperText={contactValidation.company && manditoryFieldMessage}
        />
      </Grid>
    </Grid>
  );
};
