import React, { useState, useContext } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import SmellCoffee from "../../media/smellCoffee.jpg";
import NewAlert from "../../controls/NewAlert";
import AlertContext from "../../context/alert/alertContext";
import axios from "axios";
import DemoRequest from "../../template/DemoRequest";

const initialValues = {
  name: "",
  company: "",
  phone: "",
  email: "",
};

const Demo012022 = () => {
  const { setAlert } = useContext(AlertContext);
  const [requester, setRequester] = useState(initialValues);

  const formEntry = (e) => {
    setRequester({ ...requester, [e.target.name]: e.target.value });
  };

  const [errors, setErrors] = useState({
    name: false,
    company: false,
    phone: false,
    email: false,
  });

  const helperText = {
    name: "Please enter your name",
    company: "Please enter your company name",
    phone: "Please enter your phone",
    email: "Please enter your email",
  };

  const validation = () => {
    var validated = { ...errors };

    if (requester.name === "") {
      validated.name = true;
    } else {
      validated.name = false;
    }
    if (requester.company === "") {
      validated.company = true;
    } else {
      validated.company = false;
    }
    if (requester.email === "") {
      validated.email = true;
    } else {
      validated.email = false;
    }
    if (requester.phone === "") {
      validated.phone = true;
    } else {
      validated.phone = false;
    }

    setErrors(validated);
    return Object.values(validated).every((x) => x === false);
  };

  const submitForm = async () => {
    if (validation()) {
      const emailMessagebody = DemoRequest(requester);

      const emailMessage = {
        company: requester.company,
        name: requester.name,
        phone: requester.phone,
        email: requester.email,
        subject: emailMessagebody.subject,
        html: emailMessagebody.html,
        text: emailMessagebody.text,
      };

      try {
        await axios.post("/api/contactUs/demoRequest", emailMessage);
        setAlert(
          true,
          "Submitted! we will contact you as soon as possible",
          "success"
        );
        setRequester(initialValues);
      } catch (error) {
        setAlert(true, "Server Error", "error");
      }
    }
    setAlert(true, "Please Enter ALL Manditory Fields", "error");
  };
  return (
    <Paper elevation={6} sx={{ p: { xs: 1, md: 6 } }}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <img
            src={SmellCoffee}
            alt="women smelling coffee"
            width="100%"
            height="100%"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: { xs: 2, md: 4 }, minHeight: "100%" }}>
            <CardContent>
              <Typography
                variant="h4"
                sx={{ textAlign: "center", width: "100%", p: { xs: 1, md: 2 } }}
                color="secondary"
              >
                FOR A{" "}
                <span>
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: "center",
                      width: "100%",

                      display: "inline",
                      textDecoration: "underline",
                    }}
                    color="primary"
                  >
                    FREE
                  </Typography>
                </span>{" "}
                ENTERPRISE CONNECT DEMO & $10 STARBUCKS GIFT CARD COMPLETE THE
                FORM BELOW
              </Typography>
              <TextField
                variant="filled"
                label="Name"
                name="name"
                value={requester.name}
                onChange={formEntry}
                sx={{ width: "100%", mt: 1 }}
                error={errors.name}
                helperText={errors.name && helperText.name}
              />
              <TextField
                variant="filled"
                label="Company"
                name="company"
                value={requester.company}
                onChange={formEntry}
                sx={{ width: "100%", mt: 1 }}
                error={errors.company}
                helperText={errors.company && helperText.company}
              />
              <TextField
                variant="filled"
                label="Phone"
                name="phone"
                onChange={formEntry}
                value={requester.phone}
                sx={{ width: "100%", mt: 1 }}
                error={errors.phone}
                helperText={errors.phone && helperText.email}
              />
              <TextField
                variant="filled"
                label="Email"
                name="email"
                onChange={formEntry}
                value={requester.email}
                sx={{ width: "100%", mt: 1 }}
                error={errors.email}
                helperText={errors.email && helperText.email}
              />
              <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ float: "right" }}
                    onClick={submitForm}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <NewAlert />
    </Paper>
  );
};

export default Demo012022;
