import { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { CustomerInformation } from "./components/CustomerInformation";
import Sites from "./components/Sites";
import TechnicalInformation from "./components/TechnicalInformation";

export default function ViewOpportunity({ focusedOpportunity }) {
  const [lowerTab, setLowerTab] = useState("sites");
  const [readOnly, setReadOnly] = useState(true);
  const [editedOpportunity, setEditedOpportunity] = useState({});
  const [focusedSite, setFocusedSite] = useState("");
  const [displaySite, setDisplaySite] = useState({});

  useEffect(() => {
    setEditedOpportunity({
      customer_address: focusedOpportunity.customer_address,
      customer_city: focusedOpportunity.customer_city,
      customer_company: focusedOpportunity.customer_company,
      customer_state: focusedOpportunity.customer_state,
      customer_zip: focusedOpportunity.customer_zip,
      opportunity_owner_id: focusedOpportunity.opportunity_owner_id,
      order_contact_email: focusedOpportunity.order_contact_email,
      order_contact_name: focusedOpportunity.order_contact_name,
      order_contact_phone: focusedOpportunity.order_contact_phone,
      order_monthly: focusedOpportunity.order_monthly,
      order_upfront: focusedOpportunity.order_upfront,
      sites: focusedOpportunity.sites,
      status: focusedOpportunity.status,
      technical_contact_phone: focusedOpportunity.technical_contact_phone,
      technical_contact_name: focusedOpportunity.technical_contact_name,
      technical_contact_email: focusedOpportunity.technical_contact_email,
      technical_notes: focusedOpportunity.technical_notes,
    });

    setFocusedSite(focusedOpportunity.sites[0].site_name);
  }, [focusedOpportunity]);

  useEffect(() => {
    const site = focusedOpportunity.sites.filter(
      (site) => site.site_name === focusedSite
    );

    setDisplaySite(site[0]);

    //eslint-disable-next-line
  }, [focusedSite]);

  const formEntry = (e) => {
    setEditedOpportunity({
      ...editedOpportunity,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <Paper
      sx={{
        py: 2,
        px: { xs: 1, sm: 2, md: 10, l: 10, xl: 4 },
        minHeight: "85vh",
        overflow: "hidden",
      }}
    >
      <Grid container sx={{ pb: 4 }}>
        <Grid item xs={4}></Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        ></Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
          }}
        >
          <Button sx={{ backgroundColor: "orange", color: "white", mr: 1 }}>
            SEND QUOTE
          </Button>
          <Button sx={{ backgroundColor: "green", color: "white" }}>
            SEND CONTRACT
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="h6" sx={{ mb: 2, ml: 1 }}>
            Opportunity ID: {focusedOpportunity._id}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" sx={{ mb: 2, textAlign: "right" }}>
            MONTHLY: ${parseInt(focusedOpportunity.order_monthly).toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" sx={{ mb: 2, mr: 1, textAlign: "right" }}>
            UPFRONT: ${parseInt(focusedOpportunity.order_upfront).toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
      <Card elevation={4}>
        <CardContent>
          <CustomerInformation
            focusedOpportunity={focusedOpportunity}
            editedOpportunity={editedOpportunity}
            formEntry={formEntry}
            readOnly={readOnly}
          />
        </CardContent>
      </Card>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={1}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: lowerTab === "sites" ? "orange" : "#F5F9FF",
              borderRadius: 1,
              p: 1,
              cursor: "pointer",
            }}
            onClick={() => setLowerTab("sites")}
          >
            <Typography
              variant="body1"
              sx={{
                color: lowerTab === "sites" ? "white" : "black",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              SITES
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box
            sx={{
              width: "100%",
              backgroundColor: lowerTab === "tech" ? "orange" : "#F5F9FF",
              borderRadius: 1,
              p: 1,
              cursor: "pointer",
            }}
            onClick={() => setLowerTab("tech")}
          >
            <Typography
              variant="body1"
              sx={{
                color: lowerTab === "tech" ? "white" : "black",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              TECHNICAL
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Card elevation={4} sx={{ p: 2 }}>
        <CardContent>
          {lowerTab === "sites" ? (
            <Sites
              displaySite={displaySite}
              readOnly={readOnly}
              formEntry={formEntry}
              editedOpportunity={editedOpportunity}
              focusedSite={focusedSite}
              setFocusedSite={setFocusedSite}
            />
          ) : (
            <TechnicalInformation
              displaySite={displaySite}
              readOnly={readOnly}
              formEntry={formEntry}
              editedOpportunity={editedOpportunity}
              focusedSite={focusedSite}
              setFocusedSite={setFocusedSite}
              focusedOpportunity={focusedOpportunity}
            />
          )}
        </CardContent>
      </Card>
    </Paper>
  );
}
