import React, { useState } from "react";
import BundleInformaiton from "./BundleInformation";
import BundlePricing from "./BundlePricing";
import OrderShippingProcess from "./OrderShippingProcess";
import "../../css/Pricing.css";

const Pricing = () => {
  const [selectedHardware, setSelectedHardware] = useState("ALL");
  const [selectedTerm, setSelectedTerm] = useState("ALL");
  const [selectedProvider, setSelectedProvider] = useState("ALL");

  return (
    <div className="public_pricing_container">
      <BundleInformaiton
        selectedHardware={selectedHardware}
        selectedTerm={selectedTerm}
      />
      <BundlePricing
        selectedProvider={selectedProvider}
        setSelectedProvider={setSelectedProvider}
        selectedHardware={selectedHardware}
        setSelectedHardware={setSelectedHardware}
        selectedTerm={selectedTerm}
        setSelectedTerm={setSelectedTerm}
      />
      <OrderShippingProcess />
    </div>
  );
};

export default Pricing;
