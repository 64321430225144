import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import BundleSelector from "./BundleSelector";

const BundlePricing = ({
  selectedHardware,
  setSelectedHardware,
  setSelectedTerm,
  selectedTerm,
  selectedProvider,
  setSelectedProvider,
}) => {
  const [displayedBundles, setDisplayedBundles] = useState(null);
  const [bundles, setBundles] = useState(null);

  const getBundles = async () => {
    const res = await axios.get("/api/bundles/active");
    setBundles(res.data);
  };

  useEffect(() => {
    getBundles();
  }, []);

  useEffect(() => {
    var filteredHardware = [];
    var filteredProviders = [];
    var filteredBundles = [];
    if (bundles !== null) {
      if (selectedHardware === "ALL") {
        bundles.map((bundle) => filteredHardware.push(bundle));
      } else {
        const filtered = bundles.filter(
          (item) => item.manufacturer === selectedHardware
        );
        filtered.map((bundle) => filteredHardware.push(bundle));
      }
      if (selectedProvider === "ALL") {
        filteredHardware.map((bundle) => filteredProviders.push(bundle));
      } else {
        const filtered2 = filteredHardware.filter(
          (item) => item.provider === selectedProvider
        );

        filtered2.map((bundle) => filteredProviders.push(bundle));
      }

      if (selectedTerm === "ALL") {
        filteredProviders.map((bundle) => filteredBundles.push(bundle));
      } else {
        const filtered2 = filteredProviders.filter(
          (item) => item.term === selectedTerm
        );

        filtered2.map((bundle) => filteredBundles.push(bundle));
      }
      setDisplayedBundles(filteredBundles);
    }
    // eslint-disable-next-line
  }, [selectedHardware, bundles, selectedTerm, selectedProvider]);

  return (
    <>
      <Typography variant="h4" color="secondary" sx={{ mt: 4, ml: 2 }}>
        Pricing and plans
      </Typography>
      <Paper elevation={4} sx={{ padding: 3, mt: 2 }}>
        <BundleSelector
          selectedHardware={selectedHardware}
          setSelectedHardware={setSelectedHardware}
          selectedTerm={selectedTerm}
          setSelectedTerm={setSelectedTerm}
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
        />
        <Table size="small">
          <TableHead sx={{ bgcolor: "primary.main" }}>
            <TableRow>
              <TableCell
                sx={{
                  display: { xs: "none", lg: "block", color: "white" },
                  textAlign: "center",
                }}
              >
                Manufacturer
              </TableCell>
              <TableCell sx={{ textAlign: "center", color: "white" }}>
                Size
              </TableCell>
              <TableCell sx={{ textAlign: "center", color: "white" }}>
                Service Provider
              </TableCell>
              <TableCell sx={{ textAlign: "center", color: "white" }}>
                Term
              </TableCell>
              <TableCell sx={{ textAlign: "center", color: "white" }}>
                Monthly
              </TableCell>
              <TableCell sx={{ textAlign: "center", color: "white" }}>
                Upfront
              </TableCell>
              <TableCell
                sx={{
                  display: { xs: "none", lg: "block", color: "white" },
                  textAlign: "center",
                }}
              >
                Overage*
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedBundles !== null
              ? displayedBundles.map((bundle) => (
                  <TableRow key={bundle._id}>
                    <TableCell
                      sx={{
                        display: { xs: "none", lg: "block" },
                        textAlign: "center",
                      }}
                    >
                      {bundle.manufacturer} - {bundle.model}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {bundle.size}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {bundle.provider}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {bundle.term}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      ${bundle.mrc.toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      ${bundle.nrc.toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        display: { xs: "none", lg: "block" },
                        textAlign: "center",
                      }}
                    >
                      ${bundle.overage.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
        <Typography variant="caption">
          * overage is billed per 1gb in 1gb increments / ** PROMOTIONAL Pricing
          until 12-31-2023 / ++ Pricing does not include Verizon Economic
          Adjustment Fee $2.95
        </Typography>
      </Paper>
    </>
  );
};

export default BundlePricing;
