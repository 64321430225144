import React, { useState, useEffect } from "react";
import usStates from "../../../../../utils/States";
import SiteValidations from "../../validations/SiteValidations";
import {
  Paper,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Card,
  CardContent,
  Button,
  Checkbox,
  Box,
  TextField,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import axios from "axios";

const AddSite = ({
  setBottomNav,
  setPageView,
  newOpportunity,
  setNewOpportunity,
}) => {
  const [planHasBeenSelected, setPlanHasBeenSelected] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [displayedBundles, setDisplayedBundles] = useState(null);
  const [siteValidations, setSiteValidations] = useState({
    overall: true,
    plan_sku: true,
    site_name: false,
    site_contact_name: false,
    site_contact_phone: false,
    site_contact_email: false,
    site_address: false,
    site_city: false,
    site_state: false,
    site_zip: false,
    site_ship_attention: false,
    site_ship_address: false,
    site_ship_city: false,
    site_ship_zip: false,
  });
  const [selectedHardware, setSelectedHardware] = useState("ALL");
  const [selectedTerm, setSelectedTerm] = useState("ALL");
  const [selectedProvider, setSelectedProvider] = useState("ALL");
  const [siteOrder, setSiteOrder] = useState({
    plan_sku: "",
    site_monthly: 0,
    site_upfront: 0,
    site_name: "",
    site_company: "",
    site_contact_name: "",
    site_contact_phone: "",
    site_contact_email: "",
    site_address: "",
    site_city: "",
    site_state: "SELECT",
    site_zip: "",
    site_ship_company: "",
    site_ship_attention: "",
    site_ship_address: "",
    site_ship_city: "",
    site_ship_state: "SELECT",
    site_ship_zip: "",
    site_notes: "",
    site_term: "3 Year",
  });

  const [terms, setTerms] = useState(null);
  const [hardware, setHardware] = useState(null);
  const [providers, setProviders] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [bundlesData, setBundlesData] = useState(null);

  useEffect(() => {
    setBottomNav(false);

    return () => {
      setBottomNav(true);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    var hardwareList = [];

    if (bundlesData !== null) {
      bundlesData.map((bundle) => {
        function containsObject(obj, list) {
          var i;
          for (i = 0; i < list.length; i++) {
            if (list[i].title === obj.manufacturer) {
              return true;
            }
          }

          return false;
        }

        const contains = containsObject(bundle, hardwareList);

        if (!contains) {
          hardwareList.push({
            _id: bundle._id,
            title: bundle.manufacturer,
            value: bundle.manufacturer,
          });
        }
        return null;
      });

      setHardware(hardwareList);
    }
    // eslint-disable-next-line
  }, [bundlesData]);

  useEffect(() => {
    var termsList = [];

    if (bundlesData !== null) {
      bundlesData.map((bundle) => {
        function containsObject(obj, list) {
          var i;
          for (i = 0; i < list.length; i++) {
            if (list[i].title === obj.term) {
              return true;
            }
          }

          return false;
        }

        const contains = containsObject(bundle, termsList);

        if (!contains && bundle.active) {
          termsList.push({
            _id: bundle._id,
            title: bundle.term,
            value: bundle.term,
          });
        }
        return null;
      });

      setTerms(termsList);
    }
    // eslint-disable-next-line
  }, [bundlesData]);

  useEffect(() => {
    var providerList = [];

    if (bundlesData !== null) {
      bundlesData.map((bundle) => {
        function containsObject(obj, list) {
          var i;
          for (i = 0; i < list.length; i++) {
            if (list[i].title === obj.provider) {
              return true;
            }
          }

          return false;
        }

        const contains = containsObject(bundle, providerList);

        if (!contains && bundle.active) {
          providerList.push({
            _id: bundle._id,
            title: bundle.provider,
            value: bundle.provider,
          });
        }
        return null;
      });

      setProviders(providerList);
    }
    // eslint-disable-next-line
  }, [bundlesData]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const changeHardware = (e) => {
    setSelectedHardware(e.target.value);
  };
  const changeTerm = (e) => {
    setSelectedTerm(e.target.value);
  };
  const changeProvider = (e) => {
    setSelectedProvider(e.target.value);
  };

  useEffect(() => {
    if (bundlesData !== null) {
      const builtList = bundlesData.filter(
        (item) =>
          item.manufacturer === selectedHardware && item.term === selectedTerm
      );
      const sorted = builtList.sort((a, b) => a.mrc - b.mrc);

      setDisplayedBundles(sorted);
    }

    // eslint-disable-next-line
  }, [selectedHardware, selectedTerm, bundlesData]);

  const siteEntry = (e) => {
    if (e.target.id === undefined) {
      setSiteOrder({ ...siteOrder, [e.target.name]: e.target.value });
    } else {
      setSiteOrder({ ...siteOrder, [e.target.id]: e.target.value });
    }
  };

  const addSiteToOrder = () => {
    const validated = SiteValidations(siteOrder);
    setSiteValidations(validated);
    if (validated.overall) {
      var sitesList = [];
      if (newOpportunity.sites.length > 0) {
        newOpportunity.sites.map((site) => sitesList.push(site));
      }
      sitesList.push({
        site_name: siteOrder.site_name,
        site_company: siteOrder.site_company,
        plan_sku: siteOrder.plan_sku,
        site_monthly: siteOrder.site_monthly,
        site_upfront: siteOrder.site_upfront,
        site_contact_name: siteOrder.site_contact_name,
        site_contact_phone: siteOrder.site_contact_phone,
        site_contact_email: siteOrder.site_contact_email,
        site_address: siteOrder.site_address,
        site_city: siteOrder.site_city,
        site_state: siteOrder.site_state,
        site_zip: siteOrder.site_zip,
        site_ship_company: siteOrder.site_ship_company,
        site_ship_attention: siteOrder.site_ship_attention,
        site_ship_address: siteOrder.site_ship_address,
        site_ship_city: siteOrder.site_ship_city,
        site_ship_state: siteOrder.site_ship_state,
        site_ship_zip: siteOrder.site_ship_zip,
        site_notes: siteOrder.site_notes,
        site_term: siteOrder.site_term,
      });

      const newMRC =
        ((newOpportunity.order_monthly + siteOrder.site_monthly) * 100) / 100;
      const newNRC =
        ((newOpportunity.order_upfront + siteOrder.site_upfront) * 100) / 100;

      setNewOpportunity({
        ...newOpportunity,
        sites: sitesList,
        order_monthly: newMRC,
        order_upfront: newNRC,
      });

      setSiteOrder({
        plan_sku: "",
        site_monthly: 0,
        site_upfront: 0,
        site_name: "",
        site_company: "",
        site_contact_name: "",
        site_contact_phone: "",
        site_contact_email: "",
        site_address: "",
        site_city: "",
        site_state: "",
        site_zip: "",
        site_ship_company: "",
        site_ship_attention: "",
        site_ship_address: "",
        site_ship_city: "",
        site_ship_state: "",
        site_ship_zip: "",
        site_notes: "",
        site_term: "3 Year",
      });
      setPlanHasBeenSelected(false);
      setSelectedPlan(null);
      setDisplayedBundles(null);
      setSelectedHardware("ALL");
      setSelectedTerm("ALL");
      setSelectedProvider("ALL");

      setPageView("summary");
    } else {
      window.scroll(0, 0);
    }
  };

  const selectPlan = (bundle) => {
    if (selectedPlan === null) {
      setSelectedPlan(bundle);
      setSiteOrder({
        ...siteOrder,
        plan_sku: bundle.sku,
        site_monthly: bundle.mrc,
        site_upfront: bundle.nrc,
        site_term: bundle.term,
      });
    } else {
      setSelectedPlan(null);
      setSiteOrder({
        ...siteOrder,
        plan_sku: "",
        site_monthly: 0.0,
        site_upfront: 0.0,
        site_term: "3 Year",
      });
    }
  };

  const planChecked = (e) => {
    if (planHasBeenSelected) {
      setPlanHasBeenSelected(false);
    } else {
      setPlanHasBeenSelected(true);
    }
  };

  const resetPlan = () => {
    setPlanHasBeenSelected(false);
    setSelectedPlan(null);
    setSelectedTerm("ALL");

    setSiteOrder({
      ...siteOrder,
      plan_sku: "",
      site_monthly: 0.0,
      site_upfront: 0.0,
      site_term: null,
    });
  };

  const copyCompanyInformation = () => {
    setSiteOrder({
      ...siteOrder,
      site_name: "Main",
      site_company: newOpportunity.customer_company,
      site_address: newOpportunity.customer_address,
      site_city: newOpportunity.customer_city,
      site_state: newOpportunity.customer_state,
      site_zip: newOpportunity.customer_zip,
    });
  };

  const copyOrderContact = () => {
    setSiteOrder({
      ...siteOrder,
      site_contact_name: newOpportunity.order_contact_name,
      site_contact_email: newOpportunity.order_contact_email,
      site_contact_phone: newOpportunity.order_contact_phone,
    });
  };

  const copySiteAddress = () => {
    setSiteOrder({
      ...siteOrder,
      site_ship_company: siteOrder.site_company,
      site_ship_attention: siteOrder.site_contact_name,
      site_ship_address: siteOrder.site_address,
      site_ship_city: siteOrder.site_city,
      site_ship_state: siteOrder.site_state,
      site_ship_zip: siteOrder.site_zip,
    });
  };

  useEffect(() => {
    var filteredHardware = [];
    var filteredProviders = [];
    var filteredBundles = [];
    if (bundlesData !== null) {
      if (selectedHardware === "ALL") {
        bundlesData.map((bundle) => filteredHardware.push(bundle));
      } else {
        const filtered = bundlesData.filter(
          (item) => item.manufacturer === selectedHardware
        );
        filtered.map((bundle) => filteredHardware.push(bundle));
      }
      if (selectedProvider === "ALL") {
        filteredHardware.map((bundle) => filteredProviders.push(bundle));
      } else {
        const filtered2 = filteredHardware.filter(
          (item) => item.provider === selectedProvider
        );

        filtered2.map((bundle) => filteredProviders.push(bundle));
      }

      if (selectedTerm === "ALL") {
        filteredProviders.map((bundle) => filteredBundles.push(bundle));
      } else {
        const filtered2 = filteredProviders.filter(
          (item) => item.term === selectedTerm
        );

        filtered2.map((bundle) => filteredBundles.push(bundle));
      }
      setDisplayedBundles(filteredBundles);
    }
    // eslint-disable-next-line
  }, [selectedHardware, bundlesData, selectedTerm, selectedProvider]);

  const getBundles = async () => {
    const res = await axios.get("/api/bundles/active");
    setBundlesData(res.data);
  };

  useEffect(() => {
    getBundles();
  }, []);

  const manditoryFieldMessage = "------ MANDITORY FIELD ------";

  return (
    <>
      <Paper elevation={4} sx={{ p: 4, mt: 3 }}>
        <Typography
          variant="h4"
          color="primary"
          sx={{ textAlign: "center", mt: 3 }}
        >
          Add Location
        </Typography>

        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5" color="secondary" sx={{ mt: 3 }}>
                  1. Choose Bundle:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Filter">
                  <IconButton
                    onClick={toggleFilters}
                    sx={{ ml: 2, float: "right" }}
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            {showFilters ? (
              <form id="bundle filters">
                <Grid container sx={{ mt: 2, pl: 3 }} flexDirection="row">
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      FILTER BUNDLES:{" "}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ margin: " 16px 8px" }}>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{ ml: 2, mt: { xs: 2, sm: 0 } }}
                  >
                    <FormControl variant="outlined">
                      <InputLabel>Manufacturer</InputLabel>
                      <Select
                        name="selectedHardware"
                        value={selectedHardware}
                        onChange={changeHardware}
                        options={hardware}
                        sx={{ minWidth: "200px" }}
                      >
                        <MenuItem key="ALL" value="ALL">
                          ALL
                        </MenuItem>
                        {hardware !== null &&
                          hardware.map((item) => (
                            <MenuItem key={item._id} value={item.value}>
                              {item.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{ ml: 2, mt: { xs: 2, sm: 0 } }}
                  >
                    <FormControl>
                      <InputLabel>Term</InputLabel>
                      <Select
                        name="selectedTerm"
                        value={selectedTerm}
                        onChange={changeTerm}
                        sx={{ minWidth: "200px" }}
                      >
                        <MenuItem key="ALL" value="ALL">
                          ALL
                        </MenuItem>
                        {terms !== null &&
                          terms.map((item) => (
                            <MenuItem key={item._id} value={item.value}>
                              {item.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{ ml: 2, mt: { xs: 2, sm: 0 } }}
                  >
                    <FormControl>
                      <InputLabel>Provider</InputLabel>
                      <Select
                        name="selectedProvider"
                        value={selectedProvider}
                        onChange={changeProvider}
                        sx={{ minWidth: "200px" }}
                      >
                        <MenuItem key="ALL" value="ALL">
                          ALL
                        </MenuItem>
                        {providers !== null &&
                          providers.map((item) => (
                            <MenuItem key={item._id} value={item.value}>
                              {item.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            ) : null}
            <Table sx={{ mt: 2 }} size="small">
              <TableHead>
                <TableRow sx={{ background: "#2e66b0" }}>
                  <TableCell sx={{ color: "white" }}>Select</TableCell>
                  <TableCell sx={{ color: "white" }}>Equipment</TableCell>
                  <TableCell sx={{ color: "white" }}>Size</TableCell>
                  <TableCell sx={{ color: "white" }}>Service</TableCell>
                  <TableCell sx={{ color: "white" }}>Term</TableCell>
                  <TableCell sx={{ color: "white" }}>Monthly</TableCell>
                  <TableCell sx={{ color: "white" }}>Upfront</TableCell>
                  <TableCell sx={{ color: "white" }}>Overage</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedBundles !== null
                  ? displayedBundles.map((bundle) => (
                      <TableRow key={bundle._id} hover>
                        <TableCell>
                          <Checkbox
                            checked={
                              selectedPlan !== null &&
                              selectedPlan._id === bundle._id
                                ? true
                                : false
                            }
                            disabled={planHasBeenSelected}
                            onChange={() => selectPlan(bundle)}
                            onClick={planChecked}
                            id={bundle._id}
                          />
                        </TableCell>
                        <TableCell>
                          {bundle.manufacturer} - {bundle.model}
                        </TableCell>
                        <TableCell>{bundle.size}</TableCell>
                        <TableCell>{bundle.provider}</TableCell>
                        <TableCell>{bundle.term}</TableCell>
                        <TableCell>${bundle.mrc.toFixed(2)}</TableCell>
                        <TableCell>${bundle.nrc.toFixed(2)}</TableCell>
                        <TableCell>${bundle.overage.toFixed(2)}</TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
            <Box sx={{ mt: 2 }}>
              <Typography variant="caption" sx={{ p: 2 }}>
                * overage is billed per 1gb in 1gb increments
              </Typography>
              <Button
                variant="contained"
                size="small"
                color="primary"
                sx={{ color: "white", mb: 2, float: "right" }}
                onClick={resetPlan}
              >
                Reset Bundle
              </Button>
            </Box>
          </CardContent>
        </Card>

        {siteValidations !== null && !siteValidations.plan_sku ? (
          <Typography
            variant="h6"
            color="error"
            sx={{ background: "yellow", textAlign: "center", p: 2 }}
          >
            ***** MUST SELECT AN ABOVE BUNDLE *****
          </Typography>
        ) : null}

        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5" color="secondary" sx={{ mt: 3 }}>
                  2. Enter Site Information:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  sx={{ color: "white", mt: 1, float: "right" }}
                  onClick={copyCompanyInformation}
                >
                  Copy Customer
                </Button>
              </Grid>
            </Grid>
            <form>
              <TextField
                variant="outlined"
                label="Unique Site Name"
                id="site_name"
                onChange={siteEntry}
                value={siteOrder.site_name}
                sx={{ minWidth: "100%", p: 1 }}
                error={siteValidations.site_name}
                helperText={siteValidations.site_name && manditoryFieldMessage}
              />
              <TextField
                variant="outlined"
                label="Site Company Name"
                id="site_company"
                onChange={siteEntry}
                value={siteOrder.site_company}
                sx={{ minWidth: "100%", p: 1 }}
              />
              <TextField
                variant="outlined"
                label="Address"
                id="site_address"
                onChange={siteEntry}
                value={siteOrder.site_address}
                sx={{ minWidth: "100%", p: 1 }}
                error={siteValidations.site_address}
                helperText={
                  siteValidations.site_address && manditoryFieldMessage
                }
              />
              <TextField
                variant="outlined"
                label="City"
                id="site_city"
                onChange={siteEntry}
                value={siteOrder.site_city}
                sx={{ minWidth: { xs: "100%", sm: "45%" }, p: 1 }}
                error={siteValidations.site_city}
                helperText={siteValidations.site_city && manditoryFieldMessage}
              />
              <FormControl sx={{ minWidth: { xs: "100%", sm: "20%" }, p: 1 }}>
                <InputLabel id="customer_state_label">State</InputLabel>
                <Select
                  labelId="customer_state_label"
                  name="site_state"
                  id="site_state"
                  label="State"
                  onChange={siteEntry}
                  value={siteOrder.site_state}
                  error={siteValidations.site_state}
                >
                  <MenuItem value="SELECT"> SELECT </MenuItem>
                  {usStates !== null &&
                    usStates.map((state) => (
                      <MenuItem key={state.name} value={state.abbreviation}>
                        {state.abbreviation}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                label="Zip"
                id="site_zip"
                onChange={siteEntry}
                value={siteOrder.site_zip}
                sx={{ minWidth: { xs: "100%", sm: "20%" }, p: 1 }}
                error={siteValidations.site_zip}
                helperText={siteValidations.site_zip && manditoryFieldMessage}
              />
            </form>
          </CardContent>
        </Card>

        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5" color="secondary" sx={{ mt: 3 }}>
                  3. Enter Site Contact:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  sx={{ color: "white", mt: 1, float: "right" }}
                  onClick={copyOrderContact}
                >
                  Copy Order Contact
                </Button>
              </Grid>
            </Grid>
            <form>
              <TextField
                variant="outlined"
                label="Name"
                id="site_contact_name"
                onChange={siteEntry}
                value={siteOrder.site_contact_name}
                sx={{ minWidth: "100%", p: 1 }}
                error={siteValidations.site_contact_name}
                helperText={
                  siteValidations.site_contact_name && manditoryFieldMessage
                }
              />
              <TextField
                variant="outlined"
                label="Phone"
                id="site_contact_phone"
                onChange={siteEntry}
                value={siteOrder.site_contact_phone}
                sx={{ minWidth: "100%", p: 1 }}
                error={siteValidations.site_contact_phone}
                helperText={
                  siteValidations.site_contact_phone && manditoryFieldMessage
                }
              />
              <TextField
                variant="outlined"
                label="Email"
                id="site_contact_email"
                onChange={siteEntry}
                value={siteOrder.site_contact_email}
                sx={{ minWidth: "100%", p: 1 }}
                error={siteValidations.site_contact_email}
                helperText={
                  siteValidations.site_contact_email && manditoryFieldMessage
                }
              />
            </form>
          </CardContent>
        </Card>

        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h5" color="secondary" sx={{ mt: 3 }}>
                  4. Enter Shipping Information:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  sx={{ color: "white", mb: 2, float: "right" }}
                  onClick={copySiteAddress}
                >
                  Copy Site
                </Button>
              </Grid>
            </Grid>
            <form>
              <TextField
                variant="outlined"
                label="Attention"
                id="site_ship_attention"
                onChange={siteEntry}
                value={siteOrder.site_ship_attention}
                sx={{ minWidth: "100%", p: 1 }}
                error={siteValidations.site_ship_attention}
                helperText={
                  siteValidations.site_ship_attention && manditoryFieldMessage
                }
              />
              <TextField
                variant="outlined"
                label="Company Name"
                id="site_ship_company"
                onChange={siteEntry}
                value={siteOrder.site_ship_company}
                sx={{ minWidth: "100%", p: 1 }}
              />
              <TextField
                variant="outlined"
                label="Address"
                id="site_ship_address"
                onChange={siteEntry}
                value={siteOrder.site_ship_address}
                sx={{ minWidth: "100%", p: 1 }}
                error={siteValidations.site_ship_address}
                helperText={
                  siteValidations.site_ship_address && manditoryFieldMessage
                }
              />
              <TextField
                variant="outlined"
                label="City"
                id="site_ship_city"
                onChange={siteEntry}
                value={siteOrder.site_ship_city}
                sx={{ minWidth: { xs: "100%", sm: "45%" }, p: 1 }}
                error={siteValidations.site_ship_city}
                helperText={
                  siteValidations.site_ship_city && manditoryFieldMessage
                }
              />
              <FormControl sx={{ minWidth: { xs: "100%", sm: "20%" }, p: 1 }}>
                <InputLabel id="customer_state_label">State</InputLabel>
                <Select
                  labelId="site_ship_state"
                  name="site_ship_state"
                  id="site_ship_state"
                  label="State"
                  onChange={siteEntry}
                  value={siteOrder.site_ship_state}
                  error={siteValidations.site_ship_state}
                >
                  <MenuItem value="SELECT"> SELECT </MenuItem>
                  {usStates !== null &&
                    usStates.map((state) => (
                      <MenuItem key={state.name} value={state.abbreviation}>
                        {state.abbreviation}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                label="Zip"
                id="site_ship_zip"
                onChange={siteEntry}
                value={siteOrder.site_ship_zip}
                sx={{ minWidth: { xs: "100%", sm: "20%" }, p: 1 }}
                error={siteValidations.site_ship_zip}
                helperText={
                  siteValidations.site_ship_zip && manditoryFieldMessage
                }
              />
            </form>
          </CardContent>
        </Card>
        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h5" color="secondary">
              Standard Configuration Information
            </Typography>
            <Typography variant="body2" color="primary" sx={{ p: 2 }}>
              Our standard configuration is meant for internet backup for data
              connectivity. The Enterprise Connect router is configurated with a
              single public static IP address from the wireless service
              provider. The router passes through this public static IP address
              to the LAN Ports of the router. The customer's firewall WAN port
              is connected to the router's LAN port. The firewall configuration
              of this WAN port is DHCP and the Enterprise Connect router will
              hand off the public static IP Address to the firewall WAN port.
              The Enterprise Connect router will pass through all traffic to the
              firewall.
            </Typography>
          </CardContent>
        </Card>
        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h5" color="secondary">
              5. Provide any Configuration Notes:
            </Typography>
            <form>
              <TextField
                variant="outlined"
                label="Configuration Notes"
                multiline
                rows="10"
                id="site_notes"
                onChange={siteEntry}
                value={siteOrder.site_notes}
                sx={{ minWidth: "100%", p: 1 }}
              />
            </form>
          </CardContent>
        </Card>

        <Box>
          <Grid container sx={{ my: 3 }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                color="error"
                sx={{ color: "white", ml: 2, mt: 2 }}
                onClick={() => setPageView("summary")}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ color: "white", mr: 2, mt: 2, float: "right" }}
                onClick={addSiteToOrder}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

export default AddSite;
