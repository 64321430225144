import { SET_CURRENT_PAGE } from "../types";

const agentNavigationReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    default:
      return state;
  }
};

export default agentNavigationReducer;
