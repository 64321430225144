const demoRequest = (data) => {
  const htmlMessage = `
    <br>
    An Enterprise Connect Verizon 5g Request has been filled by:
    <br>
    <br>
    Name: ${data.name} <br>
    Company: ${data.company} <br>
    Phone: ${data.phone}<br>
    Email: ${data.email}<br>
  
    `;

  const textMessage = `
    An Enterprise Connect Verizon 5g Request has been filled by:
    
    
    Contact: ${data.name}
    Company: ${data.company}
    Phone: ${data.phone}
    Email: ${data.email}
  
    
    `;

  return {
    subject: "Enterprise Connect Verizon 5g Information Request",
    html: htmlMessage,
    text: textMessage,
  };
};

export default demoRequest;
