import "../../css/OrderProcessing.css";

const OrderProcessingPage = () => {
  return (
    <div className="public_orderProcessing_container">
      <h2 className="public_orderProcessing_status">
        THANK YOU FOR YOUR ORDER
      </h2>
      <h2 className="public_orderProcessing_status">Processing Order</h2>
      <div className="public_orderProcessing_spinner"></div>
    </div>
  );
};

export default OrderProcessingPage;
