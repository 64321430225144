import React, { useState, useContext, useEffect } from "react";
import AdminNavContext from "../../../context/adminNavigation/adminNavContext";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Title from "../orders/Title";
import { Backdrop, FormControl, Grid, Typography } from "@mui/material";
import { useForm, Form } from "../../../controls/useForm";
import Controls from "../../../controls/Controls";
import Tooltip from "@mui/material/Tooltip";
import { putResource } from "../../../api/ApiFunctions";
import { useApi } from "../../../api/useApi";
import AlertContext from "../../../context/alert/alertContext";

const BundleList = ({ bundles, setBundles, setBundleToEdit }) => {
  const { loadAdminPage } = useContext(AdminNavContext);
  const { setAlert } = useContext(AlertContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [bundleIdToDelete, setBundleIdToDelete] = useState(null);
  const [bundlesToDisplay, setBundlesToDisplay] = useState(null);
  const [bundleData, setBundleDataResponse] = useApi(() =>
    putResource("bundles", bundleIdToDelete, bundleIdToDelete._id)
  );

  const deleteConfirmation = (bundle) => {
    var changedBundle = bundle;
    changedBundle.active = false;
    setShowDeleteConfirmation(true);
    setBundleIdToDelete(changedBundle);
  };

  const deleteBundle = (id) => {
    var bundle = bundleIdToDelete;
    bundle.active = false;
    setBundleDataResponse();
  };

  const cancelDelete = () => {
    document.getElementById("backDrop").style.display = "none";
    setShowDeleteConfirmation(false);
    setBundleIdToDelete(null);
  };

  const editBundle = (bundle) => {
    setBundleToEdit(bundle);
    loadAdminPage("editBundle");
  };

  const bundleFilterOptions = [
    { _id: "Active", title: "Active", value: true },
    { _id: "InActive", title: "InActive", value: false },
  ];

  const defaultFilter = {
    filter: true,
  };

  const { values, handleInputChange, resetForm } = useForm(
    defaultFilter,
    false
  );

  const filterBundles = () => {
    if (bundles !== null && values !== null) {
      const results = bundles.filter(
        (bundle) => bundle.active === values.filter
      );
      let sortedResults = results.sort((a, b) => a.sku.localeCompare(b.sku));
      setBundlesToDisplay(sortedResults);
    }
  };

  const customStyles = {
    input: {
      margin: 1,
      minWidth: "95%",
    },
  };

  useEffect(() => {
    filterBundles();
    // eslint-disable-next-line
  }, [values, bundles]);

  useEffect(() => {
    if (
      !bundleData.isFetching &&
      bundleData.isSuccess &&
      bundleData.error === null
    ) {
      const updatedBundleList = bundlesToDisplay.filter(
        (bundle) => bundle._id !== bundleIdToDelete._id
      );

      setBundlesToDisplay(updatedBundleList);
      setShowDeleteConfirmation(false);
      setBundleIdToDelete(null);
      setAlert(true, "DeActivated Bundle Successfully", "success");
    }
    // eslint-disable-next-line
  }, [bundleData]);

  if (showDeleteConfirmation) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showDeleteConfirmation}
        // onClick={handleClose}
      >
        <Paper
          elevation={3}
          sx={{
            position: "absolute",
            top: "15%",
            left: "20%",
            background: "white",
            width: "65%",
            height: "50%",
            px: 2,
          }}
        >
          <Grid container direction="row" justifyContent="left">
            <Grid item>
              <Typography
                variant="h4"
                gutterBottom
                color="primary"
                style={{ marginTop: "5%" }}
              >
                CONFIRM DEACTIVATE BUNDLE
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="sku"
                label="SKU"
                value={bundleIdToDelete.sku}
                sx={customStyles.input}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="term"
                label="Term"
                value={bundleIdToDelete.term}
                sx={customStyles.input}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Controls.Input
                label="Manufacturer"
                name="manufacturer"
                value={bundleIdToDelete.manufacturer}
                sx={customStyles.input}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="provider"
                label="Carrier"
                value={bundleIdToDelete.provider}
                sx={customStyles.input}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Controls.Input
                label="Model #"
                name="model"
                value={bundleIdToDelete.model}
                sx={customStyles.input}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="size"
                label="Plan Size"
                value={bundleIdToDelete.size}
                sx={customStyles.input}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} direction="row" sx={{ marginTop: "8px" }}>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                label="Monthly"
                name="mrc"
                value={bundleIdToDelete.mrc.toFixed(2)}
                sx={customStyles.input}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                label="Upfront"
                name="nrc"
                value={bundleIdToDelete.nrc.toFixed(2)}
                sx={customStyles.input}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controls.Input
                label="Overage"
                name="overage"
                value={bundleIdToDelete.overage.toFixed(2)}
                sx={customStyles.input}
                disabled
              />
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <Controls.Button
                color="secondary"
                text="Cancel"
                onClick={cancelDelete}
                style={{ margin: "30px 10px 0px 0px" }}
              />
              <Controls.Button
                color="primary"
                text="DeActivate"
                onClick={deleteBundle}
                style={{ margin: "30px 20px 0px 0px" }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Backdrop>
    );
  } else {
    return (
      <React.Fragment>
        <Title>EC Bundles</Title>
        <Grid container direction="row">
          <Grid item xs={2}>
            <Form>
              <FormControl variant="standard" sx={{ m: 1, minWidth: "85%" }}>
                <Controls.Select
                  name="filter"
                  label="Filter"
                  value={values.filter}
                  onChange={handleInputChange}
                  options={bundleFilterOptions}
                  sx={{ minWidth: "100%" }}
                />
              </FormControl>
            </Form>
          </Grid>

          <Grid item xs={2}>
            <Controls.Button
              color="secondary"
              text="RESET"
              size="small"
              style={{
                marginTop: "10%",
                marginLeft: "8px",
              }}
              onClick={resetForm}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs={10} />
          <Grid item>
            <Button
              variant="contained"
              onClick={() => loadAdminPage("createBundle")}
              className="bundle_list_create_button"
            >
              Create
            </Button>
          </Grid>
        </Grid>

        <Table
          size="small"
          sx={{
            marginTop: (theme) => theme.spacing(2),
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                background: "#1976d2",
              }}
            >
              <TableCell sx={{ color: "white" }}>SKU</TableCell>
              <TableCell sx={{ color: "white" }}>Hardware</TableCell>
              <TableCell sx={{ color: "white" }}>Model</TableCell>
              <TableCell sx={{ color: "white" }}>Provider</TableCell>
              <TableCell sx={{ color: "white" }}>Size</TableCell>
              <TableCell sx={{ color: "white" }}>Term</TableCell>
              <TableCell sx={{ color: "white" }}>Status</TableCell>
              <TableCell sx={{ color: "white" }} align="right">
                Monthly
              </TableCell>
              <TableCell sx={{ color: "white" }} align="right">
                Upfront
              </TableCell>
              <TableCell sx={{ color: "white" }} align="right">
                Overage
              </TableCell>
              <TableCell sx={{ color: "white" }} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bundlesToDisplay !== null &&
              bundlesToDisplay.map((bundle) => (
                <TableRow key={bundle._id} hover={true}>
                  <TableCell>{bundle.sku}</TableCell>
                  <TableCell>{bundle.manufacturer}</TableCell>
                  <TableCell>{bundle.model}</TableCell>
                  <TableCell>{bundle.provider}</TableCell>
                  <TableCell>{bundle.size}</TableCell>
                  <TableCell>{bundle.term}</TableCell>
                  <TableCell>{bundle.active ? "Active" : "InActive"}</TableCell>
                  <TableCell align="right">${bundle.mrc.toFixed(2)}</TableCell>
                  <TableCell align="right">${bundle.nrc.toFixed(2)}</TableCell>
                  <TableCell align="right">
                    ${bundle.overage.toFixed(2)}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Bundle">
                      <EditIcon
                        className="pointer"
                        onClick={() => editBundle(bundle)}
                      />
                    </Tooltip>
                    {bundle.active && (
                      <Tooltip title="DeActivate Bundle">
                        <DeleteForeverIcon
                          color="secondary"
                          className="pointer bundleList_delete"
                          onClick={() => deleteConfirmation(bundle)}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
};

export default BundleList;
