const SiteValidations = (data) => {
  var checkResults = { overall: true };
  if (data.plan_sku.length > 0) {
    checkResults.plan_sku = true;
  } else {
    checkResults.overall = false;
    checkResults.plan_sku = false;
  }
  if (data.site_name.length > 0) {
    checkResults.site_name = false;
  } else {
    checkResults.overall = false;
    checkResults.site_name = true;
  }
  if (data.site_contact_name.length > 0) {
    checkResults.site_contact_name = false;
  } else {
    checkResults.overall = false;
    checkResults.site_contact_name = true;
  }
  if (data.site_contact_phone.length > 0) {
    checkResults.site_contact_phone = false;
  } else {
    checkResults.overall = false;
    checkResults.site_contact_phone = true;
  }
  if (data.site_contact_email.length > 0) {
    checkResults.site_contact_email = false;
  } else {
    checkResults.overall = false;
    checkResults.site_contact_email = true;
  }
  if (data.site_address.length > 0) {
    checkResults.site_address = false;
  } else {
    checkResults.overall = false;
    checkResults.site_address = true;
  }
  if (data.site_city.length > 0) {
    checkResults.site_city = false;
  } else {
    checkResults.overall = false;
    checkResults.site_city = true;
  }
  if (data.site_state !== "SELECT") {
    checkResults.site_state = false;
  } else {
    checkResults.overall = false;
    checkResults.site_state = true;
  }
  if (data.site_zip.length > 0) {
    checkResults.site_zip = false;
  } else {
    checkResults.overall = false;
    checkResults.site_zip = true;
  }
  if (data.site_ship_attention.length > 0) {
    checkResults.site_ship_attention = false;
  } else {
    checkResults.overall = false;
    checkResults.site_ship_attention = true;
  }
  if (data.site_ship_address.length > 0) {
    checkResults.site_ship_address = false;
  } else {
    checkResults.overall = false;
    checkResults.site_ship_address = true;
  }
  if (data.site_ship_city.length > 0) {
    checkResults.site_ship_city = false;
  } else {
    checkResults.overall = false;
    checkResults.site_ship_city = true;
  }
  if (data.site_ship_state !== "SELECT") {
    checkResults.site_ship_state = false;
  } else {
    checkResults.overall = false;
    checkResults.site_ship_state = true;
  }
  if (data.site_ship_zip.length > 0) {
    checkResults.site_ship_zip = false;
  } else {
    checkResults.overall = false;
    checkResults.site_ship_zip = true;
  }

  return checkResults;
};

export default SiteValidations;
