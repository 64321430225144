import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
// import OrderProcessingPage from "./OrderProcessingPage";
// import OrderSubmittedSuccessfully from "./OrderSubmittedSuccessfully";
import axios from "axios";
import {
  Paper,
  Typography,
  Card,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Button,
} from "@mui/material";
const Summary = ({ newOpportunity }) => {
  const [orderStatus, setOrderStatus] = useState("processing");
  const [orderResponse, setOrderResponse] = useState("yellow");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    if (orderResponse === "green") {
      setOrderStatus("complete");
    }
  }, [orderResponse]);

  // const submitOrder = () => {
  //   window.scroll(0, 0);
  //   setOrderStatus("ordered");

  //   axios
  //     .post("/api/opportunity", newOpportunity, config)
  //     .then((response) => {
  //       setOrderStatus("success");
  //       setTimeout(() => setOrderResponse("green"), 3000);
  //     })
  //     .catch((err) => {
  //       console.log("Submission Error");
  //       console.log(err);
  //     });
  // };

  if (orderStatus === "processing") {
    return (
      <>
        <Paper elevation={4} sx={{ p: 2, mt: 2 }}>
          <Grid container sx={{ my: 1 }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                color="error"
                sx={{ color: "white", ml: 2, mt: 2 }}
                // onClick={() => setPage("")}
              >
                SEND QUOTE
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ color: "white", mr: 2, mt: 2, float: "right" }}
                // onClick={submitOrder}
              >
                SEND CONTRACT
              </Button>
            </Grid>
          </Grid>
          <Typography
            variant="h4"
            color="secondary"
            sx={{ mt: 3, textAlign: "center" }}
          >
            Opportunity Summary
          </Typography>
          <Grid container>
            <Grid item xs={12} lg={5}>
              <Card sx={{ mt: 2 }} elevation={4}>
                <CardContent>
                  <Grid container>
                    <Grid item>
                      <Typography variant="h6" color="secondary">
                        Customer
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold" }}
                        color="primary"
                      >
                        Company:{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {newOpportunity.customer_company}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold" }}
                        color="primary"
                      >
                        Address:{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {newOpportunity.customer_address}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold" }}
                        color="primary"
                      >
                        City:{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {newOpportunity.customer_city}{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", ml: 1 }}
                        color="primary"
                      >
                        State:{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {newOpportunity.customer_state}{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", ml: 1 }}
                        color="primary"
                      >
                        Zip:{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {newOpportunity.customer_zip}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={0} lg={2}></Grid>
            <Grid item xs={12} lg={5}>
              <Card sx={{ mt: 2 }} elevation={4}>
                <CardContent>
                  <Grid container>
                    <Grid item>
                      <Typography variant="h6" color="secondary">
                        Contact
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold" }}
                        color="primary"
                      >
                        Contact:{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {newOpportunity.order_contact_name}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold" }}
                        color="primary"
                      >
                        Phone:{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {newOpportunity.order_contact_phone}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold" }}
                        color="primary"
                      >
                        Email:{" "}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {newOpportunity.order_contact_email}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Card sx={{ mt: 2 }} elevation={4}>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography variant="h6" color="secondary">
                    Site Summary
                  </Typography>
                </Grid>
              </Grid>
              <Table sx={{ mt: 1 }} size="small">
                <TableHead>
                  <TableRow sx={{ background: "#2e66b0" }}>
                    <TableCell sx={{ color: "white" }}>Site Name</TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Monthly
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Upfront
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      TERM
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newOpportunity.sites.length > 0 ? (
                    newOpportunity.sites.map((site) => (
                      <TableRow key={site.site_name}>
                        <TableCell>{site.site_name}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          ${site.site_monthly.toFixed(2)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          ${site.site_upfront.toFixed(2)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {site.site_term}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>NO SITES ADDED</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card elevation={4} sx={{ mt: 2, p: 2 }}>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    Total Monthly Charges:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    ${newOpportunity.order_monthly.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    Total Upfront Charges:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    ${newOpportunity.order_upfront.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box></Box>
        </Paper>
      </>
    );
    // } else if (orderStatus === "ordered") {
    //   return <OrderProcessingPage />;
    // } else if (orderStatus === "success") {
    //   return <OrderSubmittedSuccessfully />;
  } else if (orderStatus === "complete") {
    return <Redirect push to="/" />;
  } else {
    return <Redirect push to="/" />;
  }
};

export default Summary;
