const initialValues = {
  customer_company: "",
  customer_address: "",
  customer_city: "",
  customer_state: "",
  customer_zip: "",
  order_contact_name: "",
  order_contact_phone: "",
  order_contact_email: "",
  technical_contact_name: "",
  technical_contact_phone: "",
  technical_contact_email: "",
  technical_notes: "",
  order_monthly: 0.0,
  order_upfront: 0.0,
  sites: [],
  referred_by: "",
  status: "open",
  disposition: "",
};

export default initialValues;
