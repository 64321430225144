import React, { useState, useContext, useEffect } from "react";
import AdminNavContext from "../../../../context/adminNavigation/adminNavContext";
import { Grid, Typography } from "@mui/material";
import { useForm, Form } from "../../../../controls/useForm";
import Controls from "../../../../controls/Controls";
import { useApi } from "../../../../api/useApi";
import { putResource, fetchResources } from "../../../../api/ApiFunctions";
import AlertContext from "../../../../context/alert/alertContext";

function EditPlanSize({ planSizeToEdit }) {
  const { loadAdminPage } = useContext(AdminNavContext);
  const [currentPlanSizes, setCurrentPlanSizes] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const [fetchedData, setFectchDataResponse] = useApi(() =>
    fetchResources("plansizes")
  );

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("title" in fieldValues)
      temp.title =
        fieldValues.title.length === 0 ? "" : "This field is required.";
    if ("title" in fieldValues) {
      const checkForDuplicate = currentPlanSizes.filter(
        (size) => size.title.toLowerCase() === values.title.toLowerCase()
      );
      if (checkForDuplicate.length > 0) {
        temp.title =
          "Plan Size Must be Unique - Please update to unique Plan Size";
      } else {
        temp.title = "";
      }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    setFectchDataResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fetchedData.data !== null) {
      const updatedTermsList = fetchedData.data.filter(
        (term) => term._id !== planSizeToEdit._id
      );

      setCurrentPlanSizes(updatedTermsList);
    }
    // eslint-disable-next-line
  }, [fetchedData]);

  const submitPlanSize = async (e) => {
    e.preventDefault();
    if (values.title.length > 0 && validate()) {
      const dataToSubmit = {
        title: values.title,
        value: values.title,
        active: values.active,
      };

      await putResource("plansizes", dataToSubmit, values._id)
        .then((response) => {
          resetForm();
          loadAdminPage("planSizeList");
          setAlert(true, "Plan Size Edited Successfully", "success");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setErrors({ ...errors, title: "This field is required." });
    }
  };

  const { values, errors, setErrors, resetForm, handleInputChange } = useForm(
    planSizeToEdit,
    true,
    validate
  );

  const customStyles = {
    input: {
      margin: 1,
      minWidth: "80%",
      ":focused": {
        color: "red",
      },
    },
  };
  return (
    <>
      <Typography variant="h6" sx={{ color: "#1976d2" }}>
        Edit Plan Size
      </Typography>
      <Form onSubmit={submitPlanSize}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Controls.Input
              name="title"
              label="Plan Size"
              value={values.title}
              onChange={handleInputChange}
              error={errors.title}
              sx={customStyles.input}
            />
          </Grid>
          <Grid item>
            <Controls.Checkbox
              name="active"
              label="Active"
              value={values.active}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </Form>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item>
          <Controls.Button
            text="Cancel"
            color="error"
            onClick={() => loadAdminPage("termsList")}
          />
          <Controls.Button
            text="Update"
            type="submit"
            onClick={submitPlanSize}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default EditPlanSize;
