import React, { useContext } from "react";
import adminNavContext from "../../../context/adminNavigation/adminNavContext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Paper,
} from "@mui/material";

const viewStyle = {
  label: {
    fontWeight: "bold",
    marginRight: (theme) => theme.spacing(1),
    minWidth: "150px",
  },
  data: {},
};

const CustomerInfo = (orderToView) => {
  return (
    <Card
      sx={{ minWidth: "100%", marginTop: (theme) => theme.spacing(2) }}
      elevation={5}
    >
      <CardContent>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CUSTOMER NAME:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.customer_company}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              ADDRESS:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.customer_address}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CITY:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.customer_city}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              STATE:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.customer_state}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              ZIP CODE:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.customer_zip}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const OrderContacts = (orderToView) => {
  return (
    <Card
      sx={{ minWidth: "100%", marginTop: (theme) => theme.spacing(2) }}
      elevation={5}
    >
      <CardContent>
        <Grid container>
          <Grid item>
            <Typography variant="h5" component="div">
              Order Contact
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              NAME:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.order_contact_name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              PHONE:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.order_contact_phone}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              EMAIL:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.order_contact_email}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: (theme) => theme.spacing(1) }} />
        <Grid container sx={{ marginTop: (theme) => theme.spacing(1) }}>
          <Grid item>
            <Typography variant="h5" component="div">
              Billing Contact
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              NAME:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.billing_contact_name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              PHONE:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.billing_contact_phone}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              EMAIL:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.billing_contact_email}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: (theme) => theme.spacing(1) }} />
        <Grid container sx={{ marginTop: (theme) => theme.spacing(1) }}>
          <Grid item>
            <Typography variant="h5" component="div">
              Technical Contact
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              NAME:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.technical_contact_name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              PHONE:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.technical_contact_phone}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              EMAIL:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.technical_contact_email}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const OrderInfo = (orderToView) => {
  return (
    <Card
      sx={{ minWidth: "100%", marginTop: (theme) => theme.spacing(2) }}
      elevation={5}
    >
      <CardContent>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              WEB ORDER ID:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView._id}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CRM COMPANY ID:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.ZohoCompany}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CRM CONTACT ID:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.ZohoContact}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CRM ORDER ID(s):
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              "COMING SOON"
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              REFERRED BY:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.referred_by}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
const CardInfo = (orderToView) => {
  return (
    <Card sx={{ minWidth: "100%", marginTop: (theme) => theme.spacing(2) }}>
      <CardContent>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CC CUST PROFILE:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.AuthCustomerProfile}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CC PAYMENT ID:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.AuthPaymentID}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CC SUBSCRIPTION:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.AuthSubscription}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CC UPFRONT TRANS:
            </Typography>
          </Grid>
          <Grid item>
            {orderToView.AuthUpfrontTrans !== null || undefined ? (
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {orderToView.AuthUpfrontTrans[0].TransactionId} {" / "}
                {orderToView.AuthUpfrontTrans[0].message}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
const CardAddress = (orderToView) => {
  return (
    <Card
      sx={{ minWidth: "100%", marginTop: (theme) => theme.spacing(2) }}
      elevation={5}
    >
      <CardContent>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CREDIT CARD #:
            </Typography>
          </Grid>
          <Grid item>
            {orderToView.AuthUpfrontTrans !== null || undefined ? (
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {orderToView.AuthUpfrontTrans[0].account}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CARD NAME:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.card_name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CARD COMPANY:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.card_company}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CARD ADDRESS:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.data}>
              {orderToView.card_address}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CARD CITY:
            </Typography>
          </Grid>
          <Grid item>{orderToView.card_city}</Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CARD STATE:
            </Typography>
          </Grid>
          <Grid item>{orderToView.card_state}</Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              CARD ZIP:
            </Typography>
          </Grid>
          <Grid item>{orderToView.card_zip}</Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const SiteCard = (order, index) => {
  return (
    <Card
      sx={{ minWidth: "100%", marginTop: (theme) => theme.spacing(2) }}
      key={order.site_name}
      elevation={5}
    >
      <CardContent>
        <Grid container sx={{ background: "#1976d2" }}>
          <Grid item justifyContent="center" sx={{ padding: "8px" }} xs={8}>
            <Typography variant="h5" component="div" sx={{ color: "white" }}>
              SITE # {index}
            </Typography>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: "16px" }}>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SITE NAME:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                PLAN:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.plan_sku}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                COMPANY:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_company}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                MONTHLY:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {" $ "}
                {order.site_monthly.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SITE ADDRESS:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_address}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                UPFRONT:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {" $ "}
                {order.site_upfront.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SITE CITY:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_city}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                TERM:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_term}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SITE STATE:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_state}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SHIP ATTN:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_ship_attention}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SITE ZIP:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_zip}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SHIP COMPANY:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_ship_company}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SITE CONTACT:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_contact_name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SHIP ADDRESS:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_ship_address}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                CONTACT PHONE:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_contact_phone}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SHIP CITY:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_ship_city}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                CONTACT EMAIL:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_contact_email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container xs={6}>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.label}>
                SHIP STATE / ZIP:
              </Typography>
            </Grid>
            <Grid Item xs={6}>
              <Typography variant="body" component="div" sx={viewStyle.data}>
                {order.site_ship_state} {order.site_ship_zip}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
        <Grid container xs={12}>
          <Grid Item xs={12}>
            <Typography variant="body" component="div" sx={viewStyle.label}>
              SITE NOTES:
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid Item xs={12}>
            <Typography variant="body" component="div" sx={{ p: "16px" }}>
              {order.site_notes}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const ViewOrder = ({ orderToView }) => {
  const { loadAdminPage } = useContext(adminNavContext);
  return (
    <>
      <Paper
        elevation={6}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          background: "#EEEEEE",
        }}
      >
        <Grid container>
          <Grid item>
            <ArrowBackIosIcon
              fontSize="large"
              onClick={() => loadAdminPage("orders")}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ background: "#1976d2" }}>
          <Grid item justifyContent="center" sx={{ padding: "8px" }} xs={8}>
            <Typography variant="h5" component="div" sx={{ color: "white" }}>
              Order Overview
            </Typography>
          </Grid>
          <Grid item justifyContent="center" sx={{ padding: "8px" }} xs={2}>
            <Typography variant="h5" component="div" sx={{ color: "orange" }}>
              MRC: $ {orderToView.order_monthly.toFixed(2)}
            </Typography>
          </Grid>
          <Grid item justifyContent="center" sx={{ padding: "8px" }} xs={2}>
            <Typography variant="h5" component="div" sx={{ color: "orange" }}>
              NRC: $ {orderToView.order_upfront.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            {CustomerInfo(orderToView)}
          </Grid>
          <Grid item md={6}>
            {OrderInfo(orderToView)}
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={6}>
            {OrderContacts(orderToView)}
          </Grid>
          <Grid item md={6}>
            {CardInfo(orderToView)}
            {CardAddress(orderToView)}
          </Grid>
        </Grid>
        {orderToView.sites.map((order, index) => SiteCard(order, index + 1))}
      </Paper>
    </>
  );
};

export default ViewOrder;
