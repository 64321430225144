import React, { useContext, useState } from "react";
import AdminNavContext from "../../../../context/adminNavigation/adminNavContext";
import UsersList from "./UsersList";
import CreateUser from "./CreateUser";
import ViewUser from "./ViewUser";

const Users = () => {
  const { currentPage } = useContext(AdminNavContext);
  const [userToEdit, setUserToEdit] = useState(null);

  switch (currentPage) {
    default:
    case "usersList":
      return <UsersList setUserToEdit={setUserToEdit} />;
    case "createUser":
      return <CreateUser />;
    case "editUser":
      return <ViewUser userToEdit={userToEdit} />;
  }
};

export default Users;
