import React, { useState, useEffect, useContext } from "react";
import authContext from "../../../context/auth/authContext";
import OpportunityTable from "./OpportunityTable";
import axios from "axios";

const OpenOpportunities = ({ setFocusedOpportunity }) => {
  const { user } = useContext(authContext);
  const [opportunities, setOpportunities] = useState(null);

  useEffect(() => {
    const fetchOpenOpportunities = async (id) => {
      try {
        const res = await axios.get(`/api/agentOpportunities/${id}`);

        const openOpportunities = res.data.filter(
          (opt) => opt.status === "open"
        );
        setOpportunities(openOpportunities);
      } catch (error) {
        console.log(error);
      }
    };

    if (user !== null) {
      fetchOpenOpportunities(user._id);
    }
    //eslint-disable-next-line
  }, [user]);
  return (
    <OpportunityTable
      opportunities={opportunities}
      setFocusedOpportunity={setFocusedOpportunity}
    />
  );
};

export default OpenOpportunities;
