import { useState } from "react";

import AddSite from "./sites/AddSite";
import ViewSite from "./sites/ViewSite";
import EditSite from "./sites/EditSite";
import SiteSummary from "./sites/SiteSummary";

const Locations = ({ newOpportunity, setNewOpportunity, setBottomNav }) => {
  const [siteValidated, setSiteValidated] = useState(true);
  const [pageView, setPageView] = useState("summary");
  const [focusedSite, setFocusedSite] = useState(null);

  const viewSite = (site) => {
    setFocusedSite(site);
    setPageView("view");
  };

  switch (pageView) {
    case "view":
      return (
        <ViewSite
          focusedSite={focusedSite}
          setNewOpportunity={setNewOpportunity}
          newOpportunity={newOpportunity}
          setPageView={setPageView}
          setBottomNav={setBottomNav}
        />
      );
    case "edit":
      return (
        <EditSite
          focusedSite={focusedSite}
          setNewOpportunity={setNewOpportunity}
          newOpportunity={newOpportunity}
          setPageView={setPageView}
          setBottomNav={setBottomNav}
        />
      );
    case "add":
      return (
        <AddSite
          setNewOpportunity={setNewOpportunity}
          newOpportunity={newOpportunity}
          setPageView={setPageView}
          setBottomNav={setBottomNav}
        />
      );

    default:
    case "summary":
      return (
        <SiteSummary
          siteValidated={siteValidated}
          setSiteValidated={setSiteValidated}
          setPageView={setPageView}
          newOpportunity={newOpportunity}
          viewSite={viewSite}
        />
      );
  }
};

export default Locations;
