const BillingValidation = (data) => {
  var checkResults = { overall: true };
  if (data.card_number.length > 0) {
    checkResults.card_number = false;
  } else {
    checkResults.overall = false;
    checkResults.card_number = true;
  }
  if (data.card_dateA.length > 0) {
    checkResults.card_dateA = false;
  } else {
    checkResults.overall = false;
    checkResults.card_dateA = true;
  }
  if (data.card_dateB.length > 0) {
    checkResults.card_dateB = false;
  } else {
    checkResults.overall = false;
    checkResults.card_dateB = true;
  }
  if (data.card_code.length > 0) {
    checkResults.card_code = false;
  } else {
    checkResults.overall = false;
    checkResults.card_code = true;
  }
  if (data.card_address.length > 0) {
    checkResults.card_address = false;
  } else {
    checkResults.overall = false;
    checkResults.card_address = true;
  }
  if (data.card_city.length > 0) {
    checkResults.card_city = false;
  } else {
    checkResults.overall = false;
    checkResults.card_city = true;
  }
  if (data.card_state !== "SELECT") {
    checkResults.card_state = false;
  } else {
    checkResults.overall = false;
    checkResults.card_state = true;
  }
  if (data.card_zip.length > 0) {
    checkResults.card_zip = false;
  } else {
    checkResults.overall = false;
    checkResults.card_zip = true;
  }
  if (data.card_name.length > 0) {
    checkResults.card_name = false;
  } else {
    checkResults.overall = false;
    checkResults.card_name = true;
  }

  return checkResults;
};

export default BillingValidation;
