import React from "react";
import Company from "./components/Company";
import Locations from "./components/Locations";
import Summary from "./components/Summary";
import Technical from "./components/Technical";

const CreateBody = ({
  activeStep,
  setNewOpportunity,
  newOpportunity,
  contactValidation,
  setBottomNav,
}) => {
  switch (activeStep) {
    default:
    case 0:
      return (
        <Company
          newOpportunity={newOpportunity}
          setNewOpportunity={setNewOpportunity}
          contactValidation={contactValidation}
        />
      );
    case 1:
      return (
        <Locations
          newOpportunity={newOpportunity}
          setNewOpportunity={setNewOpportunity}
          setBottomNav={setBottomNav}
        />
      );
    case 2:
      return (
        <Technical
          newOpportunity={newOpportunity}
          setNewOpportunity={setNewOpportunity}
          setBottomNav={setBottomNav}
        />
      );
    case 3:
      return (
        <Summary
          newOpportunity={newOpportunity}
          setNewOpportunity={setNewOpportunity}
          setBottomNav={setBottomNav}
        />
      );
  }
};

export default CreateBody;
