import React from "react";
import pricing_art_1 from "../../media/pricing_art_1.png";
import pricing_art_2 from "../../media/pricing_art_2.png";
import pricing_art_3 from "../../media/pricing_art_3.png";
import "../../css/Solutions.css";

const Solutions = () => {
  return (
    <div className="public_solutions_container">
      <div className="public_solutions_header">
        The Enterprise Connect &trade; service possibilties are endless
      </div>
      <div className="public_solutions_flexbox">
        <div className="public_solutions_item">
          <div className="public_solutions_item_heading">
            STANDARD BACK UP CONFIGURATION
          </div>
          <div className="public_solutions_item_body">
            Our standard configuration is meant for internet backup for data
            connectivity. The Enterprise Connect router is configurated with a
            single public static IP address from the wireless service provider.
            The router passes through this public static IP address to the LAN
            Ports of the router. The customer's firewall WAN port is connected
            to the router's LAN port. The firewall configuration of this WAN
            port is DHCP and the Enterprise Connect router will hand off the
            public static IP Address to the firewall WAN port. The Enterprise
            Connect router will pass through all traffic to the firewall.
          </div>
          <div className="public_solutions_item_disclaimer">
            NOTE: The firewall WAN port that the EC router is connected to
            should be configured as a failover port and not a load balanced
            port. Failure to do so could cause large overage usage charges.
          </div>
        </div>
        <div className="public_solutions_image1">
          <img src={pricing_art_3} alt="Man with wrench" />
        </div>
        <div className="public_solutions_image2">
          <img src={pricing_art_2} alt="gears connected" />
        </div>
      </div>
      <div className="public_solutions_flexbox">
        <div className="public_solutions_item">
          <div className="public_solutions_item_heading">
            VOIP BACK UP CONFIGURATION
          </div>
          <div className="public_solutions_item_body">
            Our standard configuration is meant for internet backup for data
            connectivity. The Enterprise Connect router is configurated with a
            single public static IP address from the wireless service provider.
            The router passes through this public static IP address to the LAN
            Ports of the router. The customer's firewall WAN port is connected
            to the router's LAN port. The firewall configuration of this WAN
            port is DHCP and the Enterprise Connect router will hand off the
            public static IP Address to the firewall WAN port. The Enterprise
            Connect router will pass through all traffic to the firewall.
          </div>
          <div className="public_solutions_item_disclaimer">
            NOTE: The firewall WAN port that the EC router is connected to
            should be configured as a failover port and not a load balanced
            port. Failure to do so could cause large overage usage charges.
          </div>
        </div>
        <div className="public_solutions_image3">
          <img src={pricing_art_1} alt="Computer and mobile phone" />
        </div>
      </div>
      <div className="public_solutions_cta">
        <div className="public_solutions_cta_headline">SOUND GOOD?</div>
        <div className="public_solutions_cta_tagline">
          Place an order and stay connected
        </div>
        <button className="public_solutions_stay_connected_button">
          <a href="/order"> PLACE ORDER</a>
        </button>
      </div>
    </div>
  );
};

export default Solutions;
