import React, { useEffect, useState, useContext } from "react";
import OrderList from "./OrderList";
import ViewOrder from "./ViewOrder";
import { useApi } from "../../../api/useApi";
import { fetchResources } from "../../../api/ApiFunctions";
import AlertContext from "../../../context/alert/alertContext";
import adminNavContext from "../../../context/adminNavigation/adminNavContext";

export default function Orders() {
  const [orders, setOrders] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const { currentPage, loadAdminPage } = useContext(adminNavContext);
  const [orderToView, setOrdertoView] = useState(null);
  const [projectData, setProjectDataResponse] = useApi(() =>
    fetchResources("order")
  );

  useEffect(() => {
    setProjectDataResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectData.error === null) {
      setOrders(projectData.data);
    } else {
      setAlert(true, "Server Error Code 199", "error");
    }

    // eslint-disable-next-line
  }, [projectData]);

  const viewOrder = (order) => {
    setOrdertoView(order);
    loadAdminPage("viewOrder");
  };

  switch (currentPage) {
    default:
    case "orders":
      return <OrderList orders={orders} viewOrder={viewOrder} />;
    case "viewOrder":
      return <ViewOrder orderToView={orderToView} />;
  }
}
