import React, { useReducer } from "react";
import AdminNavContext from "./adminNavContext";
import adminNavReducer from "./adminNavReducer";
import { SET_CURRENT_PAGE } from "../types";

const AdminNavState = (props) => {
  const initialState = {
    currentPage: "orders",
  };

  const [state, dispatch] = useReducer(adminNavReducer, initialState);

  // Admin Dashboard

  const loadAdminPage = (page) => {
    dispatch({
      type: SET_CURRENT_PAGE,
      payload: page,
    });
  };

  return (
    <AdminNavContext.Provider
      value={{
        currentPage: state.currentPage,
        loadAdminPage,
      }}
    >
      {props.children}
    </AdminNavContext.Provider>
  );
};

export default AdminNavState;
