import { useState, Fragment, useContext } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@mui/material";
import CreateBody from "./CreateBody";
import InitalValues from "./InitialValues";
import ContactValidations from "./validations/Contact";
import authContext from "../../../context/auth/authContext";
import AgentNavigationContext from "../../../context/agentNavigation/agentNavigationContext";
import axios from "axios";

const CreateOpportunities = () => {
  const { user } = useContext(authContext);
  const { loadAgentPage } = useContext(AgentNavigationContext);
  const steps = ["Customer", "Location(s)", "Technical", "Summary"];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [newOpportunity, setNewOpportunity] = useState(InitalValues);
  const [bottomNav, setBottomNav] = useState(true);

  const [contactValidation, setContactValidation] = useState({
    overall: true,
    company: false,
    address: false,
    city: false,
    state: false,
    zip: false,
    order_contact_name: false,
    order_contact_phone: false,
    order_contact_email: false,
  });

  const isStepOptional = (step) => {
    return step === -1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    const contactValidated = ContactValidations(newOpportunity);
    setContactValidation(contactValidated);

    if (contactValidated.overall) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const saveOpportunityHandler = async () => {
    const opportunityData = {
      details: newOpportunity,
      user: user,
    };

    try {
      await axios
        .post("/api/agentOpportunities", opportunityData)
        .then((response) => {
          if (response.status === 200) {
            setNewOpportunity(InitalValues);
            loadAgentPage("open");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h4" color="secondary" sx={{ mb: 2 }}>
        NEW OPPORTUNITY
      </Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <CreateBody
            activeStep={activeStep}
            newOpportunity={newOpportunity}
            setNewOpportunity={setNewOpportunity}
            contactValidation={contactValidation}
            setBottomNav={setBottomNav}
            saveOpportunityHandler={saveOpportunityHandler}
          />
          {bottomNav === true && (
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}

              {activeStep === steps.length - 1 ? (
                <Button onClick={saveOpportunityHandler}>Save</Button>
              ) : (
                <Button onClick={handleNext}>Next</Button>
              )}
            </Box>
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default CreateOpportunities;
