import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import axios from "axios";
import mmddyy from "../../../utils/mmddyy";

const DemoRequests = () => {
  const [contacts, setContacts] = useState(null);

  const retrieveDemoRequests = async () => {
    try {
      await axios.get("/api/contactUs/demoRequest").then((response) => {
        setContacts(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    retrieveDemoRequests();
  }, []);
  return (
    <Table>
      <TableHead>
        <TableRow sx={{ background: "#2e66b0" }}>
          <TableCell sx={{ color: "white" }}>Name</TableCell>
          <TableCell sx={{ color: "white", textAlign: "center" }}>
            Company
          </TableCell>
          <TableCell sx={{ color: "white", textAlign: "center" }}>
            Phone
          </TableCell>
          <TableCell sx={{ color: "white", textAlign: "center" }}>
            Email
          </TableCell>
          <TableCell sx={{ color: "white", textAlign: "center" }}>
            Submission Date
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {contacts !== null &&
          contacts.map((contact) => (
            <TableRow key={contact.name} hover>
              <TableCell>{contact.name}</TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {contact.company}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {contact.phone}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {contact.email}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                {mmddyy(contact.register_date)}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default DemoRequests;
