import React from "react";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
const SiteSummary = ({
  siteValidated,
  setPageView,
  newOpportunity,
  viewSite,
}) => {
  return (
    <Paper
      sx={{
        mt: 2,
        py: 5,
        px: { xs: 1, sm: 2, md: 10, l: 10, xl: 4 },
        minHeight: "70vh",
        overflow: "hidden",
      }}
    >
      {!siteValidated ? (
        <Typography
          sx={{
            bgcolor: "yellow",
            color: "red",
            fontWeight: "bold",
            textAlign: "center",
            padding: 2,
            mt: 2,
            mb: 2,
          }}
        >
          ****** MUST HAVE ONE SITE ******
        </Typography>
      ) : null}

      <Grid container>
        <Grid item xs={6}>
          <Button
            variant="contained"
            size="large"
            color="success"
            sx={{ color: "white", mr: 2, mt: 2 }}
            onClick={() => setPageView("add")}
          >
            Add Site
          </Button>
        </Grid>
      </Grid>
      <Table sx={{ mt: 2 }} size="small">
        <TableHead>
          <TableRow sx={{ background: "#2e66b0", color: "white" }}>
            <TableCell sx={{ color: "white" }}>
              Site Name <span>(click to view details)</span>
            </TableCell>
            <TableCell sx={{ color: "white", textAlign: "center" }}>
              Monthly
            </TableCell>
            <TableCell sx={{ color: "white", textAlign: "center" }}>
              Upfront
            </TableCell>
            <TableCell sx={{ color: "white", textAlign: "center" }}>
              Plan
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newOpportunity.sites.length > 0 ? (
            newOpportunity.sites.map((site) => (
              <TableRow key={site.site_name} hover>
                <TableCell
                  onClick={() => viewSite(site)}
                  className="pointer site_edit_link"
                >
                  {site.site_name}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  ${site.site_monthly.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  ${site.site_upfront.toFixed(2)}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {site.plan_sku}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <tr>
              <td></td>
              <td>NO SITES ADDED</td>
              <td></td>
              <td></td>
            </tr>
          )}
        </TableBody>
      </Table>
      <Grid container sx={{ mt: 2 }}>
        <Grid item>
          <Typography variant="h6" color="primary">
            TOTAL MONTHLY CHARGES:
          </Typography>
        </Grid>
        <Grid item sx={{ ml: 1 }}>
          <Typography variant="h6" color="primary">
            ${newOpportunity.order_monthly.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <Typography variant="h6" color="primary">
            TOTAL UPFRONT CHARGES:
          </Typography>
        </Grid>
        <Grid item sx={{ ml: 1 }}>
          <Typography variant="h6" color="primary">
            ${newOpportunity.order_upfront.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SiteSummary;
