import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./components/routing/PrivateRoute";
import PrivateAgentRoute from "./components/routing/PrivateAgentRoute";
import Home from "./components/pages/home/Home";
import Contact from "./components/pages/contact/Contact";
import Pricing from "./components/pages/pricing/Pricing";
import Solutions from "./components/pages/solutions/Solutions";
import Order from "./components/pages/order/Order";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Login from "./components/pages/login/Login";
import AgentLogin from "./components/pages/login/AgentLogin";
import Dashboard from "./components/pages/dashboard/Dashboard";
import AgentDashboard from "./components/pages/agentDashboard/Dashboard";
import AdminNavState from "./components/context/adminNavigation/AdminNavState";
import setAuthToken from "./components/utils/setAuthToken";
import AlertState from "./components/context/alert/AlertState";
import AuthState from "./components/context/auth/AuthState";
import AgentNavigationState from "./components/context/agentNavigation/AgentNavigationState";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ResetPassword from "./components/pages/login/ResetPassword";
import ForgotPassword from "./components/pages/forgotPassword/ForgotPassword";
import Demo from "./components/pages/landingPages/Demo012022";
import Verizon5g from "./components/pages/landingPages/Verizon5GAnnounce";
import ReactGA from "react-ga";
import "./components/css/App.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2e66b0",
    },
    secondary: {
      main: "#f5780a",
    },
  },
});

const usePageViews = () => {
  let location = useLocation();
  useEffect(() => {
    ReactGA.initialize("UA-46646177-4");
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
};

if (localStorage.auth_token) {
  setAuthToken(localStorage.auth_token);
}

function App() {
  usePageViews();

  return (
    <ThemeProvider theme={theme}>
      <AlertState>
        <AdminNavState>
          <AgentNavigationState>
            <AuthState>
              <div className="App">
                <div id="backDrop"></div>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/agent" component={AgentLogin} />
                  <Route exact path="/agents" component={AgentLogin} />
                  <Route
                    exact
                    path="/forgotpassword"
                    component={ForgotPassword}
                  />
                  <Route exact path="/reset/:token" component={ResetPassword} />
                  <PrivateRoute
                    exact
                    path="/admin/dashboard"
                    component={Dashboard}
                  />
                  <PrivateAgentRoute
                    exact
                    path="/agent/dashboard"
                    component={AgentDashboard}
                  />
                  <Route exact path="/contact">
                    <Header />
                    <Contact />
                    <Footer />
                  </Route>
                  <Route exact path="/demo">
                    <Header />
                    <Demo />
                    <Footer />
                  </Route>
                  <Route exact path="/5gVzw">
                    <Header />
                    <Verizon5g />
                    <Footer />
                  </Route>
                  <Route exact path="/pricing">
                    <Header />
                    <Pricing />
                    <Footer />
                  </Route>
                  <Route exact path="/solutions">
                    <Header />
                    <Solutions />
                    <Footer />
                  </Route>
                  <Route exact path="/order">
                    <Header />
                    <Order />
                    <Footer />
                  </Route>
                  <Route path="/">
                    <Header />
                    <Home />
                    <Footer />
                  </Route>
                </Switch>
              </div>
            </AuthState>
          </AgentNavigationState>
        </AdminNavState>
      </AlertState>
    </ThemeProvider>
  );
}

export default App;
