import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import OrderProcessingPage from "./OrderProcessingPage";
import OrderSubmittedSuccessfully from "./OrderSubmittedSuccessfully";
import axios from "axios";
import "../../css/OrderSummary.css";
import {
  Paper,
  Typography,
  Card,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Button,
} from "@mui/material";

const OrderSummary = ({
  CancelOrderEntry,
  setOrderPage,
  formEntry,
  orderFormData,
  setOrderFormData,
  history,
}) => {
  const [orderStatus, setOrderStatus] = useState("processing");
  const [orderResponse, setOrderResponse] = useState("yellow");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (orderResponse === "green") {
      setOrderStatus("complete");
    }
  }, [orderResponse]);

  const submitOrder = () => {
    window.scroll(0, 0);
    setOrderStatus("ordered");

    axios
      .post("/api/order", orderFormData, config)
      .then((response) => {
        // if (response.data.authNet.upfrontCharge.message === "Successful.") {
        setOrderStatus("success");
        setTimeout(() => setOrderResponse("green"), 3000);
        // } else {
        //   console.log("SOMETHING WENT WRONG");
        // }
      })
      .catch((err) => {
        console.log("Submission Error");
        console.log(err);
      });
  };

  if (orderStatus === "processing") {
    return (
      <div className="public_order_container">
        <Paper elevation={4} sx={{ p: 2 }}>
          <Typography
            variant="h4"
            color="primary"
            sx={{ textAlign: "center", mt: 3 }}
          >
            Enterprise Connect &trade; Order
          </Typography>
          <Typography variant="h5" color="secondary" sx={{ mt: 3 }}>
            Order Summary
          </Typography>
          <Card sx={{ mt: 2 }} elevation={4}>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    Company:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {orderFormData.customer_company}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    Address:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {orderFormData.customer_address}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    City:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {orderFormData.customer_city}{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", ml: 1 }}
                    color="primary"
                  >
                    State:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {orderFormData.customer_state}{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", ml: 1 }}
                    color="primary"
                  >
                    Zip:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {orderFormData.customer_zip}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    Contact:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {orderFormData.order_contact_name}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    Phone:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {orderFormData.order_contact_phone}{" "}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    Email:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {orderFormData.order_contact_email}{" "}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ mt: 2 }} elevation={4}>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography variant="h6" color="secondary">
                    Site Summary
                  </Typography>
                </Grid>
              </Grid>
              <Table sx={{ mt: 1 }} size="small">
                <TableHead>
                  <TableRow sx={{ background: "#2e66b0" }}>
                    <TableCell sx={{ color: "white" }}>Site Name</TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Monthly
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Upfront
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      TERM
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderFormData.sites.length > 0 ? (
                    orderFormData.sites.map((site) => (
                      <TableRow key={site.site_name}>
                        <TableCell>{site.site_name}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          ${site.site_monthly.toFixed(2)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          ${site.site_upfront.toFixed(2)}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {site.site_term}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>NO SITES ADDED</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
          <Card elevation={4} sx={{ mt: 2, p: 2 }}>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    Total Monthly Charges:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    ${orderFormData.order_monthly.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                    color="primary"
                  >
                    Total Upfront Charges:{" "}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    ${orderFormData.order_upfront.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card elevation={4} sx={{ mt: 2, p: 2 }}>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Typography variant="h6">Terms of Agreement</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Typography variant="body2" sx={{ p: 2 }}>
                    This agreement ("Agreement") is between AB&T Telecom, LLC
                    ("AB&T Telecom") and {orderFormData.customer_company}{" "}
                    ("Customer") of AB&T Telecom Data Communications Service
                    ("Galileo"). Any Galileo services or products ("Services")
                    provided by AB&T Telecom to Customer shall be governed by
                    the terms and conditions by the terms and conditions found
                    at
                    http://www.abttelecom.com/enterprise-connect-term-of-service.
                    Customer acknowledges that they are of legal age and hold
                    proper officer status to enter into this agreement.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <Typography variant="body2" sx={{ p: 2 }}>
                    The terms and conditions stated herein are in lieu of and
                    replace any and all terms and conditions set forth in any
                    documents issued by Customer, including, without limitation,
                    purchase orders and specifications. ANY ADDITIONAL,
                    DIFFERENT, OR CONFLICTING TERMS AND CONDITIONS ON ANY SUCH
                    DOCUMENT ISSUED BY CUSTOMER AT ANY TIME ARE HEREBY OBJECTED
                    TO BY AB&T Telecom, AND ANY SUCH DOCUMENTS SHALL BE WHOLLY
                    INAPPLICABLE TO ANY SALE MADE OR SERVICE RENDERED HEREUNDER
                    AND SHALL NOT BE BINDING IN ANY WAY ON AB&T Telecom. No
                    waiver or amendment to this contract or these terms and
                    conditions shall be binding on AB&T Telecom unless made in
                    writing expressly stating that it is such a waiver or
                    amendment and signed by an Officer of AB&T Telecom. The
                    total monthly commitment associated with this agreement is $
                    {orderFormData.order_monthly.toFixed(2)}.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box>
            <Grid container sx={{ my: 3 }}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  color="error"
                  sx={{ color: "white", ml: 2, mt: 2 }}
                  onClick={() => setOrderPage("billing")}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  sx={{ color: "white", mr: 2, mt: 2, float: "right" }}
                  onClick={submitOrder}
                >
                  Agree & Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </div>
    );
  } else if (orderStatus === "ordered") {
    return <OrderProcessingPage />;
  } else if (orderStatus === "success") {
    return <OrderSubmittedSuccessfully />;
  } else if (orderStatus === "complete") {
    return <Redirect push to="/" />;
  } else {
    return <Redirect push to="/" />;
  }
};

export default OrderSummary;
