import React, { useContext, useState } from "react";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import AdminNavContext from "../../context/adminNavigation/adminNavContext";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import SettingsIcon from "@mui/icons-material/Settings";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AuthContext from "../../context/auth/authContext";

export default function AdminNav() {
  const { loadAdminPage, currentPage } = useContext(AdminNavContext);
  const { logoutUser } = useContext(AuthContext);
  const [expandSettings, setExpandSettings] = useState(false);

  const toggleSubMenu = (id) => {
    if (expandSettings) {
      setExpandSettings(false);
    } else {
      setExpandSettings(true);
    }
  };

  const logout = () => {
    loadAdminPage("orders");
    logoutUser();
  };

  return (
    <div>
      <List>
        <ListItem button onClick={() => loadAdminPage("orders")}>
          <ListItemIcon>
            {currentPage === "orders" || currentPage === "viewOrder" ? (
              <ShoppingCartIcon sx={{ color: "#f5780a" }} />
            ) : (
              <ShoppingCartIcon sx={{ color: "#E6E6E6" }} />
            )}
          </ListItemIcon>
          <ListItemText primary="Orders" sx={{ color: "#E6E6E6" }} />
        </ListItem>
        <ListItem button onClick={() => loadAdminPage("bundleList")}>
          <ListItemIcon>
            {currentPage === "createBundle" ||
            currentPage === "bundleList" ||
            currentPage === "editBundle" ? (
              <ViewQuiltIcon sx={{ color: "#f5780a" }} />
            ) : (
              <ViewQuiltIcon sx={{ color: "#E6E6E6" }} />
            )}
          </ListItemIcon>
          <ListItemText primary="Bundles" sx={{ color: "#E6E6E6" }} />
        </ListItem>
        <ListItem button onClick={() => loadAdminPage("demoRequests")}>
          <ListItemIcon>
            {currentPage === "demoRequests" ? (
              <ViewQuiltIcon sx={{ color: "#f5780a" }} />
            ) : (
              <ViewQuiltIcon sx={{ color: "#E6E6E6" }} />
            )}
          </ListItemIcon>
          <ListItemText primary="Demo Requests" sx={{ color: "#E6E6E6" }} />
        </ListItem>
        <ListItem button onClick={() => loadAdminPage("contactUs")}>
          <ListItemIcon>
            {currentPage === "contactUs" ? (
              <ViewQuiltIcon sx={{ color: "#f5780a" }} />
            ) : (
              <ViewQuiltIcon sx={{ color: "#E6E6E6" }} />
            )}
          </ListItemIcon>
          <ListItemText primary="Contact Us" sx={{ color: "#E6E6E6" }} />
        </ListItem>
        <Divider />
        <ListItem button onClick={toggleSubMenu}>
          <ListItemIcon>
            <SettingsIcon sx={{ color: "#E6E6E6" }} />
          </ListItemIcon>
          <ListItemText primary="Settings" sx={{ color: "#E6E6E6" }} />
          {expandSettings ? (
            <ExpandLess onClick={toggleSubMenu} />
          ) : (
            <ExpandMore onClick={toggleSubMenu} />
          )}
        </ListItem>
        <Collapse in={expandSettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              sx={{
                paddingLeft: "24px",
                color: "#E6E6E6",
                fontWeight: "bold",
              }}
              onClick={() => loadAdminPage("termsList")}
            >
              <ListItemIcon></ListItemIcon>
              {currentPage === "createTerm" ||
              currentPage === "termsList" ||
              currentPage === "editTerm" ? (
                <ListItemText
                  primary="Bundle Terms"
                  sx={{ color: "#f5780a" }}
                />
              ) : (
                <ListItemText
                  primary="Bundle Terms"
                  sx={{ color: "#E6E6E6" }}
                />
              )}
            </ListItem>
            <ListItem
              button
              sx={{
                paddingLeft: "24px",
                color: "#E6E6E6",
                fontWeight: "bold",
              }}
              onClick={() => loadAdminPage("providersList")}
            >
              <ListItemIcon></ListItemIcon>

              {currentPage === "createProvider" ||
              currentPage === "providersList" ||
              currentPage === "editProvider" ? (
                <ListItemText
                  primary="Bundle Providers"
                  sx={{ color: "#f5780a" }}
                />
              ) : (
                <ListItemText
                  primary="Bundle Providers"
                  sx={{ color: "#E6E6E6" }}
                />
              )}
            </ListItem>
            <ListItem
              button
              sx={{
                paddingLeft: "24px",
                color: "#E6E6E6",
                fontWeight: "bold",
              }}
              onClick={() => loadAdminPage("planSizeList")}
            >
              <ListItemIcon></ListItemIcon>
              {currentPage === "createPlanSize" ||
              currentPage === "planSizeList" ||
              currentPage === "editPlanSize" ? (
                <ListItemText primary="Plan Sizes" sx={{ color: "#f5780a" }} />
              ) : (
                <ListItemText primary="Plan Sizes" sx={{ color: "#E6E6E6" }} />
              )}
            </ListItem>
            <ListItem
              button
              sx={{
                paddingLeft: "24px",
                color: "#E6E6E6",
                fontWeight: "bold",
              }}
              onClick={() => loadAdminPage("usersList")}
            >
              <ListItemIcon></ListItemIcon>
              {currentPage === "usersList" ||
              currentPage === "createUser" ||
              currentPage === "editUser" ? (
                <ListItemText primary="Users" sx={{ color: "#f5780a" }} />
              ) : (
                <ListItemText primary="Users" sx={{ color: "#E6E6E6" }} />
              )}
            </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={() => loadAdminPage("orders")}>
          <ListItemIcon>
            <AssignmentIcon sx={{ color: "#E6E6E6" }} />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            sx={{ color: "#E6E6E6" }}
            onClick={logout}
          />
        </ListItem>
      </List>
    </div>
  );
}
