import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from "../context/auth/authContext";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loadAgent, loading } = useContext(AuthContext);

  useEffect(() => {
    if (localStorage.auth_token) {
      loadAgent(localStorage.auth_token);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Route
        {...rest}
        render={(props) =>
          !isAuthenticated && loading ? (
            <Redirect to="/agent" />
          ) : (
            <Component {...props} />
          )
        }
      />
    </div>
  );
};

export default PrivateRoute;
