import React, { useContext, useState, useEffect } from "react";
import AlertContext from "../../../../context/alert/alertContext";
import AdminNavContext from "../../../../context/adminNavigation/adminNavContext";
import { useApi } from "../../../../api/useApi";
import { postResource, fetchResources } from "../../../../api/ApiFunctions";
import { useForm, Form } from "../../../../controls/useForm";
import Controls from "../../../../controls/Controls";
import { Typography, Grid, Card } from "@mui/material";

const initialFValues = {
  First_Name: "",
  Last_Name: "",
  Username: "",
  Role: "",
  Password: "",
  Password2: "",
  active: true,
};

const CreateUser = () => {
  const { loadAdminPage } = useContext(AdminNavContext);
  const [currentUsers, setCurrentUsers] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const [fetchedData, setFectchDataResponse] = useApi(() =>
    fetchResources("auth/all")
  );

  const submitUser = async (e) => {
    e.preventDefault();
    const errorCheck = validateBeforeSubmit();
    if (errorCheck) {
      if (values.Password !== values.Password2) {
        setErrors({ ...errors, Password: "Passwords must match" });
      } else {
        const dataToSubmit = {
          First_Name: values.First_Name,
          Last_Name: values.Last_Name,
          Username: values.Username,
          Role: values.Role,
          Password: values.Password,
          active: true,
        };

        await postResource("auth/new", dataToSubmit)
          .then((response) => {
            resetForm();
            loadAdminPage("usersList");
            setAlert(true, "New User Submitted Successfully", "success");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("Username" in fieldValues && currentUsers !== null) {
      const checkForDuplicate = currentUsers.filter(
        (user) =>
          user.Username.toLowerCase() === fieldValues.Username.toLowerCase()
      );
      if (checkForDuplicate.length > 0) {
        temp.Username =
          "Username Must be Unique - Please update to unique Username";
      } else {
        temp.Username = "";
      }
    }

    setErrors({
      ...temp,
    });
  };

  const validateBeforeSubmit = () => {
    let temp = { ...errors };
    temp.Username =
      values.Username.length !== 0 ? "" : "This field is required.";
    temp.First_Name =
      values.First_Name.length !== 0 ? "" : "This field is required.";
    temp.Last_Name =
      values.Last_Name.length !== 0 ? "" : "This field is required.";
    temp.Role = values.Role.length !== 0 ? "" : "This field is required.";

    temp.Password =
      values.Password.length >= 8 ? "" : "Password must be 8 characters long.";
    temp.Password2 =
      values.Password2.length >= 8 ? "" : "Password must be 8 characters long.";

    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    setFectchDataResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fetchedData.error === null) {
      setCurrentUsers(fetchedData.data);
    }
  }, [fetchedData]);

  const { values, errors, setErrors, resetForm, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  useEffect(() => {
    console.log(errors);
  }, [errors]);

  const customStyles = {
    input: {
      margin: 1,
      minWidth: "95%",
      ":focused": {
        color: "red",
      },
    },
  };

  const cancelCreate = () => {
    loadAdminPage("usersList");
    resetForm();
  };

  return (
    <>
      <Typography variant="h6" sx={{ color: "#1976d2" }}>
        Create User
      </Typography>
      <Card elevation={4} sx={{ p: 4 }}>
        <Form onSubmit={submitUser}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="First_Name"
                label="First Name"
                value={values.First_Name}
                onChange={handleInputChange}
                error={errors.First_Name}
                sx={customStyles.input}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="Last_Name"
                label="Last Name"
                value={values.Last_Name}
                onChange={handleInputChange}
                error={errors.Last_Name}
                sx={customStyles.input}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="Username"
                label="Username"
                value={values.Username}
                onChange={handleInputChange}
                error={errors.Username}
                sx={customStyles.input}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                name="Role"
                label="Role"
                value={values.Role}
                onChange={handleInputChange}
                error={errors.Role}
                sx={customStyles.input}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Controls.Input
                type="password"
                name="Password"
                label="Password"
                value={values.Password}
                onChange={handleInputChange}
                error={errors.Password}
                sx={customStyles.input}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controls.Input
                type="password"
                name="Password2"
                label="Retype Password"
                value={values.Password2}
                onChange={handleInputChange}
                error={errors.Password2}
                sx={customStyles.input}
              />
            </Grid>
          </Grid>
        </Form>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{ mt: 2, mr: 3 }}
        >
          <Grid item>
            <Controls.Button
              text="Cancel"
              type="submit"
              color="error"
              onClick={cancelCreate}
            />
            <Controls.Button text="Add" type="submit" onClick={submitUser} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CreateUser;
