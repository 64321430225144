import React, { useContext, useState } from "react";
import AdminNavContext from "../../../../context/adminNavigation/adminNavContext";
import ProvidersListing from "./ProvidersListing";
import EditProvider from "./EditProvider";
import NewProvider from "./NewProvider";

const Providers = () => {
  const { currentPage } = useContext(AdminNavContext);
  const [providerToEdit, setProviderToEdit] = useState(null);

  switch (currentPage) {
    default:
    case "providerList":
      return <ProvidersListing setProviderToEdit={setProviderToEdit} />;
    case "createProvider":
      return <NewProvider />;
    case "editProvider":
      return <EditProvider providerToEdit={providerToEdit} />;
  }
};

export default Providers;
