import React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";

const TechnicalInformation = ({
  displaySite,
  readOnly,
  formEntry,
  editedOpportunity,
  focusedSite,
  setFocusedSite,
  focusedOpportunity,
}) => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ pl: 2, pr: 1 }}>
        {displaySite !== undefined && (
          <>
            <Grid container>
              <Grid item xs={12} xl={4} sx={{ mt: 2 }}>
                <TextField
                  variant="outlined"
                  label="Technical Contact"
                  id="technical_contact_name"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={focusedOpportunity.technical_contact_name}
                  sx={{ minWidth: "100%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
              </Grid>
              <Grid item xs={12} xl={4} sx={{ mt: 2 }}>
                <TextField
                  variant="outlined"
                  label="Phone"
                  id="technical_contact_phone"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={focusedOpportunity.technical_contact_phone}
                  sx={{ minWidth: "100%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
              </Grid>
              <Grid item xs={12} xl={4} sx={{ mt: 2 }}>
                <TextField
                  variant="outlined"
                  label="Email"
                  id="technical_contact_email"
                  size="small"
                  disabled={readOnly}
                  onChange={formEntry}
                  defaultValue={focusedOpportunity.technical_contact_email}
                  sx={{ minWidth: "100%", p: 1, mt: 1 }}
                  // error={contactValidation.company}
                  // helperText={contactValidation.company && manditoryFieldMessage}
                />
              </Grid>
            </Grid>
            <TextField
              variant="outlined"
              label="Technical Notes"
              id="technical_notes"
              multiline
              rows={4}
              disabled={readOnly}
              onChange={formEntry}
              defaultValue={focusedOpportunity.technical_notes}
              sx={{ width: "100%", p: 1, mt: 1 }}
              // error={contactValidation.company}
              // helperText={contactValidation.company && manditoryFieldMessage}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default TechnicalInformation;
