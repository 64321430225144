import React, { useState, useEffect, useContext } from "react";
import authContext from "../../../context/auth/authContext";
import OpportunityTable from "./OpportunityTable";
import axios from "axios";

const ClosedOpportunities = ({ setFocusedOpportunity }) => {
  const { user } = useContext(authContext);
  const [opportunities, setOpportunities] = useState(null);

  const fetchOpenOpportunities = async (id) => {
    try {
      const res = await axios.get(`/api/agentOpportunities/${user._id}`);

      const closedOpportunities = res.data.filter(
        (opt) => opt.status === "closed"
      );

      setOpportunities(closedOpportunities);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchOpenOpportunities();
    //eslint-disable-next-line
  }, []);

  return (
    <OpportunityTable
      opportunities={opportunities}
      setFocusedOpportunity={setFocusedOpportunity}
    />
  );
};

export default ClosedOpportunities;
