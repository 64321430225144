import React, { useContext, useState } from "react";
import AdminNavContext from "../../../../context/adminNavigation/adminNavContext";
import BundleTerms from "./BundleTerms";
import EditTerm from "./EditTerm";
import CreateTerm from "./CreateTerm";

const Terms = () => {
  const { currentPage } = useContext(AdminNavContext);
  const [termToEdit, setTermToEdit] = useState(null);

  switch (currentPage) {
    default:
    case "termsList":
      return <BundleTerms setTermToEdit={setTermToEdit} />;
    case "createTerm":
      return <CreateTerm />;
    case "editTerm":
      return <EditTerm termToEdit={termToEdit} />;
  }
};

export default Terms;
