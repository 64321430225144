import React, { useContext, useState, useEffect } from "react";
import AdminNavContext from "../../../context/adminNavigation/adminNavContext";
import SpeakerGroupIcon from "@mui/icons-material/SpeakerGroup";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Grid from "@mui/material/Grid";
import { useForm, Form } from "../../../controls/useForm";
import Controls from "../../../controls/Controls";
import { FormControl } from "@mui/material";

const initialFValues = {
  manufacturer: "",
  size: "",
  provider: "",
  mrc: 0.0,
  nrc: 0.0,
  overage: 0.0,
  term: "",
  model: "",
  active: true,
  sku: "",
  date: new Date(),
};

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const CreateBundle = ({ bundles, setBundles }) => {
  const { loadAdminPage } = useContext(AdminNavContext);
  const [planSizeOptions, setPlanSizeOptions] = useState(null);
  const [termOptions, setTermOptions] = useState(null);
  const [providerOptions, setProviderOptions] = useState(null);

  const getPlanSizes = async () => {
    try {
      const sizes = await axios.get("/api/plansizes");
      let sortedSize = sizes.data.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setPlanSizeOptions(sortedSize);
    } catch (err) {
      console.log(err);
    }
  };
  const getTerms = async () => {
    try {
      const terms = await axios.get("/api/terms");
      let sortedTerms = terms.data.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setTermOptions(sortedTerms);
    } catch (err) {
      console.log(err);
    }
  };
  const getProviders = async () => {
    try {
      const providers = await axios.get("/api/providers");
      let sortedProviders = providers.data.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setProviderOptions(sortedProviders);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    try {
      getPlanSizes();
      getTerms();
      getProviders();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("sku" in fieldValues)
      temp.sku = fieldValues.sku ? "" : "This field is required.";
    if ("sku" in fieldValues) {
      const checkForDuplicate = bundles.filter(
        (bundle) => bundle.sku.toLowerCase() === values.sku.toLowerCase()
      );
      if (checkForDuplicate.length > 0) {
        temp.sku = "SKU Must be Unique - Please update to unique SKU";
      } else {
        temp.sku = "";
      }
    }
    if ("manufacturer" in fieldValues)
      temp.manufacturer = fieldValues.manufacturer
        ? ""
        : "This field is required.";
    if ("model" in fieldValues)
      temp.model = fieldValues.model ? "" : "This field is required.";
    if ("term" in fieldValues)
      temp.term =
        fieldValues.term.length !== 0 ? "" : "This field is required.";
    if ("provider" in fieldValues)
      temp.provider =
        fieldValues.provider.length !== 0 ? "" : "This field is required.";
    if ("size" in fieldValues)
      temp.size =
        fieldValues.size.length !== 0 ? "" : "This field is required.";

    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );

  const formInput = (e) => {
    if (e.target.name === "sku") {
      const checkForDuplicate = bundles.filter(
        (bundle) => bundle.sku.toLowerCase() === e.target.value.toLowerCase()
      );
      if (checkForDuplicate.length > 0) {
        setErrors({
          ...errors,
          sku: "SKU Must be Unique - Please update to unique SKU",
        });
      } else {
        setErrors({ ...errors, sku: "" });
      }
    }
    if (e.target.name === "active") {
      if (e.target.value === true) {
        setValues({ ...values, active: true });
      } else {
        setValues({ ...values, active: false });
      }
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const submitBundle = async (e) => {
    e.preventDefault();
    if (validate()) {
      await axios
        .post("/api/bundles", values, config)
        .then((response) => {
          setBundles([...bundles, response.data]);
          resetForm();
          loadAdminPage("bundleList");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const customStyles = {
    input: {
      margin: 1,
      minWidth: "90%",
    },
  };

  return (
    <>
      <Form onSubmit={submitBundle}>
        <Grid container>
          <Grid item>
            <Typography variant="h4" component="h2" gutterBottom>
              <SpeakerGroupIcon /> Create New Bundle
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Controls.Input
              name="sku"
              label="SKU"
              value={values.sku}
              onChange={formInput}
              error={errors.sku}
              sx={customStyles.input}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ margin: 1, minWidth: "90%" }}>
              <Controls.Select
                name="term"
                label="Term"
                value={values.term}
                onChange={formInput}
                options={termOptions}
                error={errors.term}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Controls.Input
              label="Manufacturer"
              name="manufacturer"
              value={values.manufacturer}
              onChange={formInput}
              error={errors.manufacturer}
              sx={customStyles.input}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ margin: 1, minWidth: "90%" }}>
              <Controls.Select
                name="provider"
                label="Carrier"
                value={values.provider}
                onChange={formInput}
                options={providerOptions}
                error={errors.provider}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Controls.Input
              label="Model #"
              name="model"
              value={values.model}
              onChange={formInput}
              error={errors.model}
              sx={customStyles.input}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" sx={{ margin: 1, minWidth: "90%" }}>
              <Controls.Select
                name="size"
                label="Plan Size"
                value={values.size}
                onChange={formInput}
                options={planSizeOptions}
                error={errors.size}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} direction="row" sx={{ marginTop: "8px" }}>
          <Grid item xs={12} sm={4}>
            <Controls.Input
              label="Monthly"
              name="mrc"
              value={values.mrc}
              onChange={formInput}
              error={errors.mrc}
              sx={customStyles.input}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controls.Input
              label="Upfront"
              name="nrc"
              value={values.nrc}
              onChange={formInput}
              error={errors.nrc}
              sx={customStyles.input}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controls.Input
              label="Overage"
              name="overage"
              value={values.overage}
              onChange={formInput}
              error={errors.nrc}
              sx={customStyles.input}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ margin: 2 }}>
          <Grid item xs={12} md={6}>
            <Controls.Checkbox
              name="active"
              label="Active"
              value={values.active}
              onChange={formInput}
            />
          </Grid>
        </Grid>
      </Form>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        sx={{ paddingRight: (theme) => theme.spacing(3) }}
      >
        <Grid item>
          <Controls.Button
            color="secondary"
            text="Cancel"
            onClick={() => loadAdminPage("bundleList")}
          />
          <Controls.Button text="Add" type="submit" onClick={submitBundle} />
        </Grid>
      </Grid>
    </>
  );
};

export default CreateBundle;
