import React from "react";
import lady_announce from "../../media/lady_announce.png";
import "../../css/WhatsIncluded.css";

const WhatsIncluded = () => {
  return (
    <div className="public_whatsincluded_container">
      <div className="public_whatsincluded_headline">
        Enterprise Connect &trade; bundles has four components
      </div>
      <div className="public_whatsincluded_cards">
        <div className="public_whatsincluded_card">
          <div className="public_whatsincluded_card_headline">Connectivity</div>
          <div className="public_whatsincluded_card_body">
            <ul>
              <li>
                The latest wireless technologies - 4G LTE/5G/FWA - for a faster
                experience.
              </li>
              <li>
                Providing the wide spread coverage for enterprise and M2M/IoT
                connectivity
              </li>
              <li>
                The best service coverage through T-Mobile, AT&T and Verizon
                Wireless
              </li>
              <li>Public Static IP Address provided</li>
            </ul>
          </div>
        </div>
        <div className="public_whatsincluded_card">
          <div className="public_whatsincluded_card_headline">Hardware</div>
          <div className="public_whatsincluded_card_body">
            <div className="public_whatsincluded_description_nolist">
              Enterprise grade dual SIM wireless router or custom configurations
              depending on solution requirements. Some options include:
            </div>
            <ul>
              <li>LAN Connectivity: Ethernet, WiFi options</li>
              <li>SD-WAN - router & software configured for plug and play</li>
              <li>
                Hardened variants for adverse conditions, with extended
                temperature ranges
              </li>
              <li>Extended warranty options, Peplink, Cradlepoint, Cisco</li>
              <li>
                Accessories for antennas, pole mounts, extenders, cables,
                boosters
              </li>
            </ul>
          </div>
        </div>
        <div className="public_whatsincluded_card">
          <div className="public_whatsincluded_card_headline">
            Monitor/Management
          </div>
          <div className="public_whatsincluded_card_body">
            <ul>
              <li>Status of connection to site (up or down)</li>
              <li>Notification of large data overages</li>
              <li>Manage device firmware</li>
              <li>
                Nightly reboots to assure cellular connection remains current
              </li>
            </ul>
          </div>
        </div>
        <div className="public_whatsincluded_card">
          <div className="public_whatsincluded_card_headline">
            Customer Portal
          </div>
          <div className="public_whatsincluded_card_body">
            <ul>
              <li>List of all connections on Enterprise Connect account</li>
              <li>Status of all connections</li>
              <li>Current month to date usage by rate plans/connection</li>
              <li>Technical detailsof connection</li>
              <li>Create custom email alerts for MTD usage</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="public_whatsincluded_flexbox">
        <div className="public_whatsincluded_installation_card">
          <div className="public_whatsincluded_installation_headline">
            Installation Services
          </div>
          <div className="public_whatsincluded_installation_body">
            Need it installed for you? No problem. AB&T Telecom also offers
            expert installation services for Enterprise Connect including
            complete site surveys and same-day on-site installations.
          </div>
        </div>
        <div className="public_whatsincluded_button">
          <button>
            <a href="/pricing">PRICING PLANS</a>
          </button>
        </div>
        <div className="public_whatsincluded_image">
          <img src={lady_announce} alt="women announcing pricing plans" />
        </div>
      </div>
    </div>
  );
};

export default WhatsIncluded;
