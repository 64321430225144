import "../../css/OrderSubmittedSuccess.css";

const OrderProcessingPage = () => {
  return (
    <div className="public_orderComplete_container">
      <h2 className="public_orderComplete_status">
        YOUR ORDER HAS BEEN SUBMITTED SUCCESSFULY
      </h2>
      <h2 className="public_orderComplete_status">
        THANK YOU FOR YOUR BUSINESS
      </h2>
    </div>
  );
};

export default OrderProcessingPage;
