import React, { useState, useEffect } from "react";
import usStates from "../../utils/States";
import BillingValidation from "../../utils/BillingValidation";
import "../../css/OrderBillingInformation.css";
import {
  Paper,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Button,
  Checkbox,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

const OrderBilling = ({
  CancelOrderEntry,
  setOrderPage,
  formEntry,
  orderFormData,
  setOrderFormData,
}) => {
  const [cardTypeCheckboxes, setCardTypeCheckboxes] = useState({
    visa: false,
    mc: false,
    amex: false,
    discover: false,
  });
  const [billingValidation, setBillingValidation] = useState({
    overall: true,
    card_number: false,
    card_dateA: false,
    card_dateB: false,
    card_code: false,
    card_address: false,
    card_city: false,
    card_state: false,
    card_zip: false,
    card_name: false,
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const nextPage = () => {
    const validated = BillingValidation(orderFormData);
    setBillingValidation(validated);
    if (validated.overall) {
      const formatedCreditCard = orderFormData.card_number.replace(
        /[-\s ]/g,
        ""
      );
      const formatedCreditCardDate = `${orderFormData.card_dateA}/${orderFormData.card_dateB}`;
      setOrderFormData({
        ...orderFormData,
        card_number: formatedCreditCard,
        card_date: formatedCreditCardDate,
      });
      document.getElementById("card_number").value = formatedCreditCard;
      if (validated) {
        setOrderPage("orderSummary");
      }
    } else {
      window.scroll(0, 0);
    }
  };

  const checkBox = (e) => {
    if (e.target.name === "visa") {
      if (cardTypeCheckboxes.visa === false) {
        setCardTypeCheckboxes({
          visa: true,
          mc: false,
          amex: false,
          discover: false,
        });
        setOrderFormData({ ...orderFormData, card_type: "visa" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setOrderFormData({ ...orderFormData, card_type: "" });
      }
    }
    if (e.target.name === "mc") {
      if (cardTypeCheckboxes.mc === false) {
        setCardTypeCheckboxes({
          visa: false,
          mc: true,
          amex: false,
          discover: false,
        });
        setOrderFormData({ ...orderFormData, card_type: "mc" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setOrderFormData({ ...orderFormData, card_type: "" });
      }
    }
    if (e.target.name === "amex") {
      if (cardTypeCheckboxes.amex === false) {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: true,
          discover: false,
        });
        setOrderFormData({ ...orderFormData, card_type: "amex" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setOrderFormData({ ...orderFormData, card_type: "" });
      }
    }
    if (e.target.name === "discover") {
      if (cardTypeCheckboxes.discover === false) {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: true,
        });
        setOrderFormData({ ...orderFormData, card_type: "discover" });
      } else {
        setCardTypeCheckboxes({
          visa: false,
          mc: false,
          amex: false,
          discover: false,
        });
        setOrderFormData({ ...orderFormData, card_type: "" });
      }
    }
  };

  const manditoryFieldMessage = "------ MANDITORY FIELD ------";
  return (
    <div className="public_order_container">
      <Paper elevation={4} sx={{ p: 2 }}>
        <Typography
          variant="h4"
          color="primary"
          sx={{ textAlign: "center", mt: 3 }}
        >
          Payment Information
        </Typography>
        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h5" color="secondary">
              Card Details:
            </Typography>
            <form>
              <FormGroup sx={{ flexDirection: "row", mt: 2 }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", ml: 2, mt: 1 }}
                >
                  Card Type:
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ ml: 3 }}
                      id="card_type"
                      name="visa"
                      onChange={checkBox}
                      checked={cardTypeCheckboxes.visa}
                    />
                  }
                  label="VISA"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ ml: 3 }}
                      id="card_type"
                      name="mc"
                      onChange={checkBox}
                      checked={cardTypeCheckboxes.mc}
                    />
                  }
                  label="MC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ ml: 3 }}
                      id="card_type"
                      name="amex"
                      onChange={checkBox}
                      checked={cardTypeCheckboxes.amex}
                    />
                  }
                  label="AMEX"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ ml: 3 }}
                      id="card_type"
                      name="discover"
                      onChange={checkBox}
                      checked={cardTypeCheckboxes.discover}
                    />
                  }
                  label="DISCOVER"
                />
              </FormGroup>
              <TextField
                variant="outlined"
                label="Card Number"
                id="card_number"
                onChange={formEntry}
                value={orderFormData.card_number}
                sx={{ minWidth: "100%", p: 1, mt: 2 }}
                error={billingValidation.card_number}
                helperText={
                  billingValidation.card_number && manditoryFieldMessage
                }
              />
              <Grid container>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label="MM"
                    id="card_dateA"
                    onChange={formEntry}
                    value={orderFormData.card_dateA}
                    sx={{ p: 1, mt: 2 }}
                    error={billingValidation.card_dateA}
                    helperText={
                      billingValidation.card_dateA && manditoryFieldMessage
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label="YYYY"
                    id="card_dateB"
                    onChange={formEntry}
                    value={orderFormData.card_dateB}
                    sx={{ p: 1, mt: 2 }}
                    error={billingValidation.card_dateB}
                    helperText={
                      billingValidation.card_dateB && manditoryFieldMessage
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label="CCV"
                    id="card_code"
                    onChange={formEntry}
                    value={orderFormData.card_code}
                    sx={{ p: 1, mt: 2 }}
                    error={billingValidation.card_code}
                    helperText={
                      billingValidation.card_code && manditoryFieldMessage
                    }
                  />
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h5" color="secondary">
              Card Billing:
            </Typography>
            <TextField
              variant="outlined"
              label="Card Name"
              id="card_name"
              onChange={formEntry}
              value={orderFormData.card_name}
              sx={{ minWidth: "100%", p: 1, mt: 2 }}
              error={billingValidation.card_name}
              helperText={billingValidation.card_name && manditoryFieldMessage}
            />
            <TextField
              variant="outlined"
              label="Company Name"
              id="card_company"
              onChange={formEntry}
              value={orderFormData.card_company}
              sx={{ minWidth: "100%", p: 1, mt: 2 }}
            />
            <TextField
              variant="outlined"
              label="Address"
              id="card_address"
              onChange={formEntry}
              value={orderFormData.card_address}
              sx={{ minWidth: "100%", p: 1, mt: 2 }}
              error={billingValidation.card_address}
              helperText={
                billingValidation.card_address && manditoryFieldMessage
              }
            />
            <TextField
              variant="outlined"
              label="City"
              id="card_city"
              onChange={formEntry}
              value={orderFormData.card_city}
              sx={{ minWidth: "100%", p: 1, mt: 2 }}
              error={billingValidation.card_city}
              helperText={billingValidation.card_city && manditoryFieldMessage}
            />
            <FormControl sx={{ minWidth: { xs: "100%" }, p: 1, mt: 2 }}>
              <InputLabel id="customer_state_label">State</InputLabel>
              <Select
                labelId="customer_state_label"
                name="card_state"
                id="card_state"
                label="State"
                onChange={formEntry}
                value={orderFormData.card_state}
                error={billingValidation.card_state}
              >
                <MenuItem value="SELECT"> SELECT </MenuItem>
                {usStates !== null &&
                  usStates.map((state) => (
                    <MenuItem key={state.name} value={state.abbreviation}>
                      {state.abbreviation}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              label="Zip"
              id="card_zip"
              onChange={formEntry}
              value={orderFormData.card_zip}
              sx={{ minWidth: "100%", p: 1, mt: 2 }}
              error={billingValidation.card_zip}
              helperText={billingValidation.card_zip && manditoryFieldMessage}
            />
          </CardContent>
        </Card>

        <Box>
          <Grid container sx={{ my: 3 }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                color="error"
                sx={{ color: "white", ml: 2, mt: 2 }}
                onClick={() => setOrderPage("siteSummary")}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                sx={{ color: "white", mr: 2, mt: 2, float: "right" }}
                onClick={nextPage}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};

export default OrderBilling;
