import React, { useContext, useState, useEffect } from "react";
import AdminNavContext from "../../../context/adminNavigation/adminNavContext";
import BundleList from "./BundleList";
import CreateBundle from "./CreateBundle";
import EditBundle from "./EditBundle";
import { useApi } from "../../../api/useApi";
import { fetchResources } from "../../../api/ApiFunctions";
import AlertContext from "../../../context/alert/alertContext";

const Bundles = () => {
  const { currentPage } = useContext(AdminNavContext);
  const { setAlert } = useContext(AlertContext);
  const [bundles, setBundles] = useState(null);
  const [bundleToEdit, setBundleToEdit] = useState(null);
  const [projectData, setProjectDataResponse] = useApi(() =>
    fetchResources("bundles")
  );

  useEffect(() => {
    setProjectDataResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectData.error === null) {
      setBundles(projectData.data);
    } else {
      setAlert(true, "Server Error Code 199", "error");
    }

    // eslint-disable-next-line
  }, [projectData]);

  switch (currentPage) {
    default:
    case "bundleList":
      return (
        <BundleList
          bundles={bundles}
          setBundles={setBundles}
          setBundleToEdit={setBundleToEdit}
        />
      );
    case "createBundle":
      return <CreateBundle bundles={bundles} setBundles={setBundles} />;

    case "editBundle":
      return (
        <EditBundle
          bundleToEdit={bundleToEdit}
          bundles={bundles}
          setBundles={setBundles}
        />
      );
  }
};

export default Bundles;
