import React from "react";
import { Grid, Paper, Typography } from "@mui/material/";
import PortalScreenShot from "../../media/PortalDashboard.JPG";
import "../../css/CustomerPortal.css";

const Portal = () => {
  return (
    <Paper sx={{ py: 10, background: "#2e66b0" }}>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Typography
            sx={{
              textAlign: "center",
              color: "#acacac",
              fontSize: { xs: "2rem", lg: "3rem", xl: "4rem" },
            }}
          >
            Customer Portal
          </Typography>
          <ul className="portalFeaturesList">
            <li className="customerPortal">Dashboard</li>
            <ul>
              <li className="customerPortal">Summary of connections status</li>
              <li className="customerPortal">Summary of carrier connections</li>
              <li className="customerPortal">Status of usage pools</li>
            </ul>
            <li className="customerPortal">Connection List</li>
            <ul>
              <li className="customerPortal">Status of connection</li>
              <li className="customerPortal">Connection details</li>
              <ul>
                <li className="customerPortal">IP Address</li>
                <li className="customerPortal">SIM Card Number</li>
                <li className="customerPortal">Current Usage MTD</li>
              </ul>
            </ul>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            py: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={PortalScreenShot}
            alt="Customer Portal"
            style={{
              width: "90%",
              border: "solid 1px white",
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Portal;
