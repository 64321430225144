import React from "react";
import HeroImage from "../../media/Hero_Image.png";
import "../../css/Hero.css";

const HeroSection = () => {
  return (
    <div className="public_hero_container">
      <div className="public_hero_mobile_flexbox">
        <div className="public_hero_text">
          <div className="public_hero_text_headline">
            KEEPING THE WORLD'S ASSETS CONNECTED
          </div>
          <div className="public_hero_text_description">
            AB&T provides the <span>Enterprise Connect &trade;</span> failover
            service bundle nationwide with wireless service from either
            T-Mobile, Verizon, AT&T. The bundle is delivered to the customer
            site fully configured and ready to use out of the box.
          </div>
          <button className="stayConnected_button_desktop">
            <a href="/pricing">STAY CONNECTED</a>
          </button>
        </div>
        <div className="public_hero_image">
          <img src={HeroImage} alt="connecting assests" />
        </div>
        <div className="public_hero_button_mobile">
          <button className="stayConnected_button">
            <a href="/pricing">STAY CONNECTED</a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
