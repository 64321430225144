import React, { useState, useContext } from "react";
import AlertContext from "../../context/alert/alertContext";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Controls from "../../controls/Controls";
import axios from "axios";

const ForgotPassword = (props) => {
  const { setAlert } = useContext(AlertContext);
  const [userName, setUserName] = useState("");

  const onChange = (e) => {
    setUserName(e.target.value);
  };

  const submitReset = (e) => {
    e.preventDefault();

    if (userName === "") {
      setAlert(true, "Please Enter A Username to Reset", "danger");
    } else {
      axios
        .post("/api/auth/resetpassword", {
          Username: userName,
        })
        .then((response) => {
          if (response.data === "recovery email sent") {
            props.history.push("/login");
            setAlert(true, "Reset Email has been sent", "success");
          } else {
            setAlert(true, "Not a Valid UserName", "error");
          }
        })
        .catch((error) => {
          setAlert(true, "Server Error", "error");
        });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 9,
          marginBottom: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "#2e66b0" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box component="form" onSubmit={submitReset} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            label="Enter Username"
            name="Username"
            autoComplete="email"
            autoFocus
            onChange={onChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset
          </Button>
        </Box>
        <Controls.NewAlert />
      </Box>
    </Container>
  );
};

export default ForgotPassword;
