import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import Pricing_Art_2 from "../../media/pricing_art_2.png";
import Button from "@mui/material/Button";
const OrderShippingProcess2 = () => {
  return (
    <>
      <Card
        sx={{
          display: "flex",
          p: 2,
          mt: 3,
          boxShadow: "none",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "50%" },
          }}
        >
          <CardContent
            sx={{
              flex: "1 0 auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              component="h4"
              variant="h4"
              color="secondary"
              sx={{ mt: 2, textAlign: { xs: "center", sm: "left" } }}
            >
              Order and Shipping Process
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <Typography variant="h6" color="gray">
                  Choose "Place Order" button below
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <Typography variant="h6" color="gray">
                  Fill out the online order form for all locations
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <Typography variant="h6" color="gray">
                  AB&T Telecom will review the order and acknowledge that the
                  order has moved to the provisioning process.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <Typography variant="h6" color="gray">
                  A static IP address is assigned to the order.
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <Typography variant="h6" color="gray">
                  AB&T Telecom will ship fully configured and ready to be
                  plugged
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckIcon />
                </ListItemIcon>
                <Typography variant="h6" color="gray">
                  Most orders ship within 48 hours
                </Typography>
              </ListItem>
              <ListItem>
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  sx={{ color: "white", mr: 5, mt: 2 }}
                  href="/order"
                >
                  Order Service
                </Button>
              </ListItem>
            </List>
          </CardContent>
        </Box>
        <img
          src={Pricing_Art_2}
          alt="gears and wrench"
          className="pricing_gears_1"
        />
      </Card>
    </>
  );
};

export default OrderShippingProcess2;
