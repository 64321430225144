const ContactValidations = (data) => {
  var checkResults = { overall: true };
  if (data.customer_company.length > 0) {
    checkResults.company = false;
  } else {
    checkResults.overall = false;
    checkResults.company = true;
  }
  if (data.customer_address.length > 0) {
    checkResults.address = false;
  } else {
    checkResults.overall = false;
    checkResults.address = true;
  }
  if (data.customer_city.length > 0) {
    checkResults.city = false;
  } else {
    checkResults.overall = false;
    checkResults.city = true;
  }
  if (data.customer_state !== "SELECT") {
    checkResults.state = false;
  } else {
    checkResults.overall = false;
    checkResults.state = true;
  }
  if (data.customer_zip.length > 0) {
    checkResults.zip = false;
  } else {
    checkResults.overall = false;
    checkResults.zip = true;
  }
  if (data.order_contact_name.length > 0) {
    checkResults.order_contact_name = false;
  } else {
    checkResults.overall = false;
    checkResults.order_contact_name = true;
  }
  if (data.order_contact_phone.length > 0) {
    checkResults.order_contact_phone = false;
  } else {
    checkResults.overall = false;
    checkResults.order_contact_phone = true;
  }
  if (data.order_contact_email.length > 0) {
    checkResults.order_contact_email = false;
  } else {
    checkResults.overall = false;
    checkResults.order_contact_email = true;
  }
  return checkResults;
};

export default ContactValidations;
