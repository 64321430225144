import { TextField } from "@mui/material";
import React from "react";

export default function Input({
  name,
  label,
  value,
  error = null,
  onChange,
  sx,
  ...other
}) {
  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      sx={sx}
      {...other}
      {...(error && { error: true, helperText: error })}
    ></TextField>
  );
}
