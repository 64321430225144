import React, { useState, useContext, useEffect } from "react";
import AdminNavContext from "../../../../context/adminNavigation/adminNavContext";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Title from "../../orders/Title";
import { useApi } from "../../../../api/useApi";
import { useForm, Form } from "../../../../controls/useForm";
import { fetchResources, deleteResource } from "../../../../api/ApiFunctions";
import AlertContext from "../../../../context/alert/alertContext";
import { FormControl, Grid, Paper, Typography } from "@mui/material";
import Controls from "../../../../controls/Controls";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";

const PlanSizesList = ({ setPlanSizeToEdit }) => {
  const { loadAdminPage } = useContext(AdminNavContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [planSizeToDelete, setPlanSizeToDelete] = useState(null);
  const [planSizeToDisplay, setPlanSizeToDisplay] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const [planSizeData, setPlanSizeDataResponse] = useApi(() =>
    fetchResources("plansizes")
  );

  useEffect(() => {
    setPlanSizeDataResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (planSizeData.error === null) {
      setPlanSizeToDisplay(planSizeData.data);
    } else {
      setAlert(true, "Server Error Code 199", "error");
    }

    // eslint-disable-next-line
  }, [planSizeData]);

  const deleteConfirmation = (data) => {
    setShowDeleteConfirmation(true);
    setPlanSizeToDelete(data);
  };

  const deleteRecord = (id) => {
    deleteResource("plansizes", id);
    const updatedData = planSizeToDisplay.filter(
      (record) => record._id !== planSizeToDelete._id
    );
    setAlert(true, "Plan Size Deleted", "success");
    setPlanSizeToDisplay(updatedData);
    setShowDeleteConfirmation(false);
    setPlanSizeToDelete(null);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setPlanSizeToDelete(null);
  };

  const editRecord = (e) => {
    setPlanSizeToEdit(e);
    loadAdminPage("editPlanSize");
  };

  const filterOptions = [
    { _id: "Active", title: "Active", value: true },
    { _id: "InActive", title: "InActive", value: false },
  ];

  const defaultFilter = {
    filter: true,
  };

  const { values, handleInputChange, resetForm } = useForm(
    defaultFilter,
    false
  );

  const filterTerms = () => {
    if (planSizeData.data !== null && values !== null) {
      const results = planSizeData.data.filter(
        (planSize) => planSize.active === values.filter
      );

      setPlanSizeToDisplay(results);
    }
  };

  useEffect(() => {
    filterTerms();
    // eslint-disable-next-line
  }, [values, planSizeData]);

  if (showDeleteConfirmation) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showDeleteConfirmation}
        // onClick={handleClose}
      >
        <Paper
          elevation={3}
          sx={{
            position: "absolute",
            top: "25%",
            left: "30%",
            background: "white",
            width: "40%",
            height: "30%",
            px: 2,
          }}
        >
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <Typography
                variant="h4"
                gutterBottom
                color="primary"
                sx={{ marginTop: "5%" }}
              >
                CONFIRM DELETE TERM
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ minWidth: "100%" }}>
            <Grid item xs={12}>
              <Controls.Input
                label="TERM"
                value={planSizeToDelete.title}
                disabled
                sx={{
                  margin: "20px 40px 0 80px",
                  minWidth: "80%",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: 10, minHeight: "10px" }}
          ></Grid>
          <Grid container direction="row" justifyContent="flex-end">
            <Controls.Button
              color="error"
              text="Cancel"
              onClick={cancelDelete}
              sx={{ margin: "30px 10px 0px 0px" }}
            />
            <Controls.Button
              color="info"
              text="Delete"
              onClick={() => deleteRecord(planSizeToDelete._id)}
              sx={{ margin: "30px 40px 0px 10px" }}
            />
          </Grid>
        </Paper>
      </Backdrop>
    );
  } else {
    return (
      <React.Fragment>
        <Title>EC BUNDLE TERM OPTIONS</Title>
        <Grid container direction="row">
          <Grid item xs={2}>
            <Form>
              <FormControl variant="standard" sx={{ m: 1, minWidth: "85%" }}>
                <Controls.Select
                  name="filter"
                  label="Filter"
                  value={values.filter}
                  onChange={handleInputChange}
                  options={filterOptions}
                  sx={{ minWidth: "100%" }}
                />
              </FormControl>
            </Form>
          </Grid>

          <Grid item xs={2}>
            <Controls.Button
              color="secondary"
              text="RESET"
              size="small"
              style={{
                marginTop: "10%",
                marginLeft: "8px",
              }}
              onClick={resetForm}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs={10} />
          <Grid item>
            <Button
              variant="contained"
              onClick={() => loadAdminPage("createPlanSize")}
              sx={{
                background: "green",
                color: "white",
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid>

        <Table
          size="small"
          sx={{
            marginTop: (theme) => theme.spacing(2),
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                background: "#1976d2",
              }}
            >
              <TableCell sx={{ color: "white" }}>Term</TableCell>
              <TableCell sx={{ color: "white" }}>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {planSizeToDisplay !== null &&
              planSizeToDisplay.map((record) => (
                <TableRow key={record._id} hover={true}>
                  <TableCell>{record.title}</TableCell>
                  <TableCell>{record.active ? "Active" : "InActive"}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit Bundle">
                      <EditIcon
                        className="pointer"
                        onClick={() => editRecord(record)}
                      />
                    </Tooltip>
                    <Tooltip title="Delete Term">
                      <DeleteForeverIcon
                        color="secondary"
                        className="pointer bundleList_delete"
                        onClick={() => deleteConfirmation(record)}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
};

export default PlanSizesList;
