import React from "react";
import HeroSection from "./HeroSection";
import WhatsIncluded from "./WhatsIncluded";
import Portal from "./Portal";

const Home = () => {
  return (
    <div>
      <HeroSection />
      <Portal />
      <WhatsIncluded />
    </div>
  );
};

export default Home;
