import React, { useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import AgentNavigationContext from "../../context/agentNavigation/agentNavigationContext";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AuthContext from "../../context/auth/authContext";

export default function AdminNav() {
  const { loadAgentPage, currentPage } = useContext(AgentNavigationContext);
  const { logoutUser } = useContext(AuthContext);

  const logout = () => {
    loadAgentPage("orders");
    logoutUser();
  };

  return (
    <div>
      <List>
        <ListItem button onClick={() => loadAgentPage("new")}>
          <ListItemIcon>
            {currentPage === "new" ? (
              <ShoppingCartIcon sx={{ color: "#f5780a" }} />
            ) : (
              <ShoppingCartIcon sx={{ color: "#E6E6E6" }} />
            )}
          </ListItemIcon>
          <ListItemText primary="New" sx={{ color: "#E6E6E6" }} />
        </ListItem>
        <ListItem button onClick={() => loadAgentPage("open")}>
          <ListItemIcon>
            {currentPage === "open" ? (
              <ViewQuiltIcon sx={{ color: "#f5780a" }} />
            ) : (
              <ViewQuiltIcon sx={{ color: "#E6E6E6" }} />
            )}
          </ListItemIcon>
          <ListItemText primary="Open" sx={{ color: "#E6E6E6" }} />
        </ListItem>
        <ListItem button onClick={() => loadAgentPage("closed")}>
          <ListItemIcon>
            {currentPage === "closed" ? (
              <ViewQuiltIcon sx={{ color: "#f5780a" }} />
            ) : (
              <ViewQuiltIcon sx={{ color: "#E6E6E6" }} />
            )}
          </ListItemIcon>
          <ListItemText primary="Closed" sx={{ color: "#E6E6E6" }} />
        </ListItem>
        <Divider />

        <ListItem button>
          <ListItemIcon>
            <AssignmentIcon sx={{ color: "#E6E6E6" }} />
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            sx={{ color: "#E6E6E6" }}
            onClick={logout}
          />
        </ListItem>
      </List>
    </div>
  );
}
