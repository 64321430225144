const demoRequest = (data) => {
  const htmlMessage = `
  <br>
  An Enterprise Connect Demo Request has been filled by:
  <br>
  <br>
  Name: ${data.name} <br>
  Company: ${data.company} <br>
  Phone: ${data.phone}<br>
  Email: ${data.email}<br>

  `;

  const textMessage = `
  An Enterprise Connect Demo Request has been filled out by:
  
  
  Contact: ${data.name}
  Company: ${data.company}
  Phone: ${data.phone}
  Email: ${data.email}

  
  `;

  return {
    subject: "Enterprise Connect Demo Request",
    html: htmlMessage,
    text: textMessage,
  };
};

export default demoRequest;
