import React, { useState, useEffect } from "react";
import SiteSummaryValidation from "../../utils/SitesSummaryValidation";
import {
  Paper,
  Typography,
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import "../../css/Orders_site_summary.css";

const OrderSiteSummary = ({
  CancelOrderEntry,
  setOrderPage,
  formEntry,
  orderFormData,
  setViewSiteIndex,
}) => {
  const [siteValidated, setSiteValidated] = useState(true);
  const viewSiteOrder = (name) => {
    setViewSiteIndex(name);
    setOrderPage("viewSite");
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const nextPage = () => {
    const validated = SiteSummaryValidation(orderFormData);
    if (validated) {
      setSiteValidated(validated);
      setOrderPage("billing");
    }
    setSiteValidated(validated);
  };
  return (
    <div className="public_order_container ">
      <Paper elevation={4} sx={{ p: 2 }}>
        <Typography
          variant="h4"
          color="primary"
          sx={{ textAlign: "center", mt: 3 }}
        >
          ORDER - SITE SUMMMARY
        </Typography>
        {!siteValidated ? (
          <Typography
            sx={{
              bgcolor: "yellow",
              color: "red",
              fontWeight: "bold",
              textAlign: "center",
              padding: 2,
              mt: 2,
              mb: 2,
            }}
          >
            ****** MUST HAVE ONE SITE ******
          </Typography>
        ) : null}

        <Grid container>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              color="success"
              sx={{ color: "white", mr: 2, mt: 2 }}
              onClick={() => setOrderPage("addSite")}
            >
              Add Site
            </Button>
          </Grid>
        </Grid>
        <Table sx={{ mt: 2 }} size="small">
          <TableHead>
            <TableRow sx={{ background: "#2e66b0", color: "white" }}>
              <TableCell sx={{ color: "white" }}>
                Site Name <span>(click to view details)</span>
              </TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>
                Monthly
              </TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>
                Upfront
              </TableCell>
              <TableCell sx={{ color: "white", textAlign: "center" }}>
                Plan
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderFormData.sites.length > 0 ? (
              orderFormData.sites.map((site) => (
                <TableRow key={site.site_name} hover>
                  <TableCell
                    onClick={() => viewSiteOrder(site.site_name)}
                    className="pointer site_edit_link"
                  >
                    {site.site_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    ${site.site_monthly.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    ${site.site_upfront.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {site.plan_sku}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <tr>
                <td></td>
                <td>NO SITES ADDED</td>
                <td></td>
                <td></td>
              </tr>
            )}
          </TableBody>
        </Table>
        <Grid container sx={{ mt: 2 }}>
          <Grid item>
            <Typography variant="h6" color="primary">
              TOTAL MONTHLY CHARGES:
            </Typography>
          </Grid>
          <Grid item sx={{ ml: 1 }}>
            <Typography variant="h6" color="primary">
              ${orderFormData.order_monthly.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <Typography variant="h6" color="primary">
              TOTAL UPFRONT CHARGES:
            </Typography>
          </Grid>
          <Grid item sx={{ ml: 1 }}>
            <Typography variant="h6" color="primary">
              ${orderFormData.order_upfront.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              color="error"
              sx={{ color: "white", mr: 2, mt: 2 }}
              onClick={() => setOrderPage("contact")}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              sx={{ color: "white", mr: 2, mt: 2, float: "right" }}
              onClick={nextPage}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default OrderSiteSummary;
