import React, { useState, useEffect } from "react";
import "../../css/Order_view_site.css";
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
} from "@mui/material";

const ViewSiteOrder = ({
  viewSiteIndex,
  bundles,
  orderFormData,
  setOrderFormData,
  setViewSiteIndex,
  setOrderPage,
}) => {
  const [siteToView, setSiteToView] = useState(null);
  const [selectedBundle, setSelectedBundle] = useState(null);
  useEffect(() => {
    const viewingSite = orderFormData.sites.filter(
      (site) => site.site_name === viewSiteIndex
    );
    setSiteToView(viewingSite[0]);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (siteToView !== null && bundles !== null) {
      const bundleToView = bundles.filter(
        (bundle) => siteToView.plan_sku === bundle.sku
      );
      setSelectedBundle(bundleToView[0]);
    }

    // eslint-disable-next-line
  }, [siteToView, bundles]);

  const deleteSite = (name) => {
    const newSiteList = orderFormData.sites.filter(
      (site) => site.site_name !== viewSiteIndex
    );

    const newMRC = orderFormData.order_monthly - siteToView.site_monthly;
    const newNRC = orderFormData.order_upfront - siteToView.site_upfront;

    setOrderFormData({
      ...orderFormData,
      sites: newSiteList,
      order_monthly: newMRC,
      order_upfront: newNRC,
    });
    setOrderPage("siteSummary");
    setViewSiteIndex(null);
  };
  return (
    <div className="public_order_container">
      <Paper elevation={4} sx={{ padding: 4 }}>
        <Typography variant="h4" color="primary" sx={{ textAlign: "center" }}>
          Site Details
        </Typography>
        <Grid container sx={{ mt: 2 }} justifyContent="center">
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                size="small"
                color="success"
                sx={{ color: "white", mb: 2 }}
                onClick={() => setOrderPage("siteSummary")}
              >
                Back
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                size="small"
                color="warning"
                sx={{ color: "white", mb: 2 }}
                onClick={() => setOrderPage("editSite")}
              >
                Edit
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                size="small"
                color="error"
                sx={{ color: "white", mb: 2 }}
                onClick={deleteSite}
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Card elevation={4} sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h5" color="secondary" sx={{ mt: 1 }}>
              Plan Details
            </Typography>
            {selectedBundle !== null || undefined ? (
              <Table size="small" sx={{ mt: 2 }}>
                <TableHead>
                  <TableRow sx={{ background: "#2e66b0" }}>
                    <TableCell sx={{ color: "white" }}>Device</TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Plan
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Provider
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Monthly
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Upfront
                    </TableCell>
                    <TableCell sx={{ color: "white", textAlign: "center" }}>
                      Overage
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {selectedBundle.manufacturer} - {selectedBundle.model}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {selectedBundle.size}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {selectedBundle.provider}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      ${selectedBundle.mrc.toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      ${selectedBundle.nrc.toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      ${selectedBundle.overage.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ) : null}
          </CardContent>
        </Card>

        {siteToView !== null || undefined ? (
          <div className="public_viewSiteOrder_section_heading">
            <Card elevation={4} sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h5" color="secondary" sx={{ mt: 1 }}>
                  Site Costs
                </Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Monthly:
                      </TableCell>
                      <TableCell>
                        ${siteToView.site_monthly.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Upfront:
                      </TableCell>
                      <TableCell>
                        ${siteToView.site_upfront.toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Term:</TableCell>
                      <TableCell>{siteToView.site_term}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
            <Card elevation={4} sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h5" color="secondary" sx={{ mt: 1 }}>
                  Site Details
                </Typography>
                <Grid container sx={{ mt: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Site Name:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_name}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Company:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_company}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Address:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_address}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    City:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_city}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", ml: 1 }}
                  >
                    State:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_state}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", ml: 1 }}
                  >
                    Zip:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_zip}
                  </Typography>
                </Grid>
                <Grid container sx={{ mt: 2 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Contact:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_contact_name}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Phone:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_contact_phone}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Email:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_contact_email}
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
            <Card elevation={4} sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h5" color="secondary" sx={{ mt: 1 }}>
                  Shipping Details
                </Typography>
                <Grid container sx={{ mt: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Attention:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_ship_attention}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Company:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_ship_company}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Address:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_ship_address}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    City:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_ship_city}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", ml: 1 }}
                  >
                    State:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_ship_state}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", ml: 1 }}
                  >
                    Zip:
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {siteToView.site_ship_zip}
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
            <Card elevation={4} sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h5" color="secondary" sx={{ mt: 1 }}>
                  Site Configuration Notes
                </Typography>
                <Grid container sx={{ mt: 1 }}>
                  <Typography variant="body2" sx={{ ml: 1, p: 2 }}>
                    {siteToView.site_notes}
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </div>
        ) : null}
      </Paper>
    </div>
  );
};

export default ViewSiteOrder;
