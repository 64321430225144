import passwordValidator from "password-validator";

const PasswordValidator = (password) => {
  const schema = new passwordValidator();

  schema
    .is()
    .min(8)
    .is()
    .max(100)
    .is()
    .not()
    .oneOf(["Passw0rd", "Password123"]);

  const checkPassword = schema.validate(password);
  console.log(checkPassword);
  return checkPassword;
};

export default PasswordValidator;
