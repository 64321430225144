import React, { useContext, useState, useEffect } from "react";
import AdminNavContext from "../../../../context/adminNavigation/adminNavContext";
import Typography from "@mui/material/Typography";
import { useForm, Form } from "../../../../controls/useForm";
import Controls from "../../../../controls/Controls";
import { Grid } from "@mui/material";
import { useApi } from "../../../../api/useApi";
import { postResource, fetchResources } from "../../../../api/ApiFunctions";
import AlertContext from "../../../../context/alert/alertContext";

const initialFValues = {
  title: "",
  value: "",
  active: true,
};

const CreateTerm = () => {
  const { loadAdminPage } = useContext(AdminNavContext);
  const [currentTerms, setCurrentTerms] = useState(null);
  const { setAlert } = useContext(AlertContext);
  const [fetchedData, setFectchDataResponse] = useApi(() =>
    fetchResources("terms")
  );

  const submitTerm = async (e) => {
    e.preventDefault();
    if (values.title.length > 0 && validate()) {
      const dataToSubmit = {
        title: values.title,
        value: values.title,
        active: true,
      };

      await postResource("terms", dataToSubmit)
        .then((response) => {
          resetForm();
          loadAdminPage("termsList");
          setAlert(true, "New Term Submitted Successfully", "success");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setErrors({ ...errors, title: "This field is required." });
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("title" in fieldValues)
      temp.title =
        fieldValues.title.length === 0 ? "" : "This field is required.";
    if ("title" in fieldValues) {
      const checkForDuplicate = currentTerms.filter(
        (term) => term.title.toLowerCase() === values.title.toLowerCase()
      );
      if (checkForDuplicate.length > 0) {
        temp.title = "Term Must be Unique - Please update to unique Term";
      } else {
        temp.title = "";
      }
    }
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    setFectchDataResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentTerms(fetchedData.data);
  }, [fetchedData]);

  const { values, errors, setErrors, resetForm, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const customStyles = {
    input: {
      margin: 1,
      minWidth: "80%",
      ":focused": {
        color: "red",
      },
    },
  };

  const cancelCreate = () => {
    loadAdminPage("termsList");
    resetForm();
  };

  return (
    <>
      <Typography variant="h6" sx={{ color: "#1976d2" }}>
        Create Term Commitment
      </Typography>
      <Form onSubmit={submitTerm}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Controls.Input
              name="title"
              label="Term"
              value={values.title}
              onChange={handleInputChange}
              error={errors.title}
              sx={customStyles.input}
            />
          </Grid>
        </Grid>
      </Form>
      <Grid container direction="row" justifyContent="flex-end">
        <Grid item>
          <Controls.Button
            text="Cancel"
            type="submit"
            color="error"
            onClick={cancelCreate}
          />
          <Controls.Button text="Add" type="submit" onClick={submitTerm} />
        </Grid>
      </Grid>
    </>
  );
};

export default CreateTerm;
