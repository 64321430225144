import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import usStates from "../../../../utils/States";

const Company = ({ newOpportunity, setNewOpportunity, contactValidation }) => {
  const manditoryFieldMessage = "------ MANDITORY FIELD ------";

  const formEntry = (e) => {
    if (e.target.name === "customer_state") {
      setNewOpportunity({ ...newOpportunity, customer_state: e.target.value });
    } else {
      setNewOpportunity({ ...newOpportunity, [e.target.id]: e.target.value });
    }
  };
  return (
    <Paper
      sx={{
        mt: 2,
        py: 5,
        px: { xs: 1, sm: 2, md: 10, l: 10, xl: 4 },
        minHeight: "70vh",
        overflow: "hidden",
      }}
    >
      <Typography variant="h6" color="secondary" sx={{ textAlign: "left" }}>
        CUSTOMER
      </Typography>

      <TextField
        variant="outlined"
        label="Company Name"
        id="customer_company"
        onChange={formEntry}
        value={newOpportunity.customer_company}
        sx={{ minWidth: "100%", p: 1, mt: 1 }}
        error={contactValidation.company}
        helperText={contactValidation.company && manditoryFieldMessage}
      />
      <TextField
        variant="outlined"
        label="Address"
        id="customer_address"
        onChange={formEntry}
        value={newOpportunity.customer_address}
        sx={{ minWidth: "100%", p: 1 }}
        error={contactValidation.address}
        helperText={contactValidation.address && manditoryFieldMessage}
      />
      <TextField
        variant="outlined"
        label="City"
        id="customer_city"
        onChange={formEntry}
        value={newOpportunity.customer_city}
        sx={{ minWidth: { xs: "100%", sm: "55%" }, p: 1 }}
        error={contactValidation.city}
        helperText={contactValidation.city && manditoryFieldMessage}
      />
      <FormControl sx={{ minWidth: { xs: "100%", sm: "20%" }, p: 1 }}>
        <InputLabel id="customer_state_label">State</InputLabel>
        <Select
          labelId="customer_state_label"
          name="customer_state"
          label="State"
          onChange={formEntry}
          value={newOpportunity.customer_state}
          error={contactValidation.state}
        >
          <MenuItem value="SELECT"> SELECT </MenuItem>
          {usStates !== null &&
            usStates.map((state) => (
              <MenuItem key={state.name} value={state.abbreviation}>
                {state.abbreviation}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        variant="outlined"
        label="Zip"
        id="customer_zip"
        onChange={formEntry}
        value={newOpportunity.customer_zip}
        sx={{ minWidth: { xs: "100%", sm: "25%" }, p: 1 }}
        error={contactValidation.zip}
        helperText={contactValidation.zip && manditoryFieldMessage}
      />
      <Typography variant="h6" color="secondary" sx={{ textAlign: "left" }}>
        ORDER CONTACT
      </Typography>
      <TextField
        variant="outlined"
        label="Name"
        id="order_contact_name"
        onChange={formEntry}
        value={newOpportunity.order_contact_name}
        sx={{ minWidth: "100%", p: 1, mt: 1 }}
        error={contactValidation.order_contact_name}
        helperText={
          contactValidation.order_contact_name && manditoryFieldMessage
        }
      />
      <TextField
        variant="outlined"
        label="Phone"
        id="order_contact_phone"
        onChange={formEntry}
        value={newOpportunity.order_contact_phone}
        sx={{ minWidth: "100%", p: 1 }}
        error={contactValidation.order_contact_phone}
        helperText={
          contactValidation.order_contact_phone && manditoryFieldMessage
        }
      />
      <TextField
        variant="outlined"
        label="Email"
        id="order_contact_email"
        onChange={formEntry}
        value={newOpportunity.order_contact_email}
        sx={{ minWidth: { xs: "100%", sm: "100%" }, p: 1 }}
        error={contactValidation.order_contact_email}
        helperText={
          contactValidation.order_contact_email && manditoryFieldMessage
        }
      />
    </Paper>
  );
};

export default Company;
