import React, { useState, useContext, useEffect } from "react";
import AdminNavContext from "../../../../context/adminNavigation/adminNavContext";
import AlertContext from "../../../../context/alert/alertContext";
import { useApi } from "../../../../api/useApi";
import { fetchResources, deleteResource } from "../../../../api/ApiFunctions";
import {
  Table,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Backdrop,
  Paper,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Controls from "../../../../controls/Controls";

const UsersList = ({ setUserToEdit }) => {
  const { loadAdminPage } = useContext(AdminNavContext);
  const { setAlert } = useContext(AlertContext);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToDisplay, setUserToDisplay] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [userData, setUserDataResponse] = useApi(() =>
    fetchResources("auth/all")
  );

  useEffect(() => {
    setUserDataResponse();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userData.error === null) {
      setUserToDisplay(userData.data);
    } else {
      setAlert(true, "Server Error Code 199", "error");
    }

    // eslint-disable-next-line
  }, [userData]);

  const deleteConfirmation = (data) => {
    setShowDeleteConfirmation(true);
    setUserToDelete(data);
  };

  const deleteRecord = (id) => {
    deleteResource("auth", id);
    const updatedData = userToDisplay.filter(
      (record) => record._id !== userToDelete._id
    );
    setAlert(true, "User Deleted", "success");
    setUserToDisplay(updatedData);
    setShowDeleteConfirmation(false);
    setUserToDelete(null);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setUserToDelete(null);
  };

  const editRecord = (e) => {
    setUserToEdit(e);
    loadAdminPage("editUser");
  };

  if (showDeleteConfirmation) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showDeleteConfirmation}
      >
        <Paper
          elevation={3}
          sx={{
            position: "absolute",
            top: "25%",
            left: "30%",
            background: "white",
            width: "40%",
            height: "30%",
            px: 2,
          }}
        >
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <Typography
                variant="h4"
                gutterBottom
                color="primary"
                sx={{ marginTop: "5%" }}
              >
                CONFIRM DELETE TERM
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ minWidth: "100%" }}>
            <Grid item xs={12}>
              <Controls.Input
                label="UserName"
                value={userToDelete.Username}
                disabled
                sx={{
                  margin: "20px 40px 0 80px",
                  minWidth: "80%",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{ mt: 10, minHeight: "10px" }}
          ></Grid>
          <Grid container direction="row" justifyContent="flex-end">
            <Controls.Button
              color="error"
              text="Cancel"
              onClick={cancelDelete}
              sx={{ margin: "30px 10px 0px 0px" }}
            />
            <Controls.Button
              color="info"
              text="Delete"
              onClick={() => deleteRecord(userToDelete._id)}
              sx={{ margin: "30px 40px 0px 10px" }}
            />
          </Grid>
        </Paper>
      </Backdrop>
    );
  } else {
    return (
      <React.Fragment>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Users
        </Typography>

        <Grid container direction="row" justifyContent="flex-end">
          <Grid item xs={10} />
          <Grid item>
            <Button
              variant="contained"
              onClick={() => loadAdminPage("createUser")}
              sx={{
                background: "green",
                color: "white",
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid>

        <Table
          size="small"
          sx={{
            marginTop: (theme) => theme.spacing(2),
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                background: "#1976d2",
              }}
            >
              <TableCell sx={{ color: "white" }}>Name</TableCell>
              <TableCell sx={{ color: "white" }}>UserName</TableCell>
              <TableCell sx={{ color: "white" }}>Role</TableCell>
              <TableCell sx={{ color: "white" }}>Status</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userToDisplay !== null &&
              userToDisplay.map((record) => (
                <TableRow key={record._id} hover={true}>
                  <TableCell>
                    {record.First_Name} {record.Last_Name}
                  </TableCell>
                  <TableCell>{record.Username}</TableCell>
                  <TableCell>{record.Role}</TableCell>
                  <TableCell>{record.active ? "Active" : "InActive"}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit User">
                      <EditIcon
                        className="pointer"
                        onClick={() => editRecord(record)}
                        color="warning"
                      />
                    </Tooltip>
                    <Tooltip title="Delete User">
                      <DeleteForeverIcon
                        color="error"
                        className="pointer bundleList_delete"
                        onClick={() => deleteConfirmation(record)}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
};

export default UsersList;
