import React, { useReducer } from "react";
import AgentNavigationContext from "./agentNavigationContext";
import AgentNavigationReducer from "./agentNavigationReducer";
import { SET_CURRENT_PAGE } from "../types";

const AgentNavigationState = (props) => {
  const initialState = {
    currentPage: "open",
  };

  const [state, dispatch] = useReducer(AgentNavigationReducer, initialState);

  // Admin Dashboard

  const loadAgentPage = (page) => {
    dispatch({
      type: SET_CURRENT_PAGE,
      payload: page,
    });
  };

  return (
    <AgentNavigationContext.Provider
      value={{
        currentPage: state.currentPage,
        loadAgentPage,
      }}
    >
      {props.children}
    </AgentNavigationContext.Provider>
  );
};

export default AgentNavigationState;
