import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import Pricing_art_1 from "../../media/pricing_art_1.png";

const BundleInformation2 = () => {
  return (
    <Card
      sx={{
        display: "flex",
        p: 2,
        boxShadow: "none",
        justifyContent: "space-evenly",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent
          sx={{
            flex: "1 0 auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            component="h4"
            variant="h3"
            color="secondary"
            sx={{ mt: 2, textAlign: { xs: "center", sm: "left" } }}
          >
            Enterprise Connect &trade; bundle
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <Typography variant="h5" color="gray">
                Enterprise grade wireless router
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <Typography variant="h5" color="gray">
                SIM installed and actived
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <Typography variant="h5" color="gray">
                A public static IP activated with provider
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <Typography variant="h5" color="gray">
                Data plans available (1gb - Unlimited)
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <Typography variant="h5" color="gray">
                Remote line monitoring and alerts
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </Box>
      <img
        src={Pricing_art_1}
        alt="gears and wrench"
        className="pricing_art_1"
      />
    </Card>
  );
};

export default BundleInformation2;
