import React, { useContext, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Orders from "./orders/Orders";
import Bundles from "./bundles/Bundles";
import DemoRequests from "./demoRequests/DemoRequests";
import ContactUs from "./contactUs/ContactUs";
import Terms from "./settings/terms/Terms";
import AdminNavContext from "../../context/adminNavigation/adminNavContext";
import Providers from "./settings/providers/Providers";
import PlanSizes from "./settings/planSizes/PlanSizes";
import Users from "./settings/users/Users";

const DashboardBody = () => {
  const { currentPage } = useContext(AdminNavContext);

  useEffect(() => {
    console.log(currentPage);
  }, [currentPage]);

  switch (currentPage) {
    default:
    case "orders":
    case "viewOrder":
      return (
        <div>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <Orders />
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    case "createBundle":
    case "bundleList":
    case "editBundle":
      return (
        <div>
          <div sx={{ flexGrow: "1", height: "100vh", overflow: "auto" }} />
          <Container maxWidth="lg" sx={{ px: (theme) => theme.spacing(4) }}>
            <Grid container spacing={3} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    padding: (theme) => theme.spacing(2),
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                  }}
                >
                  <Bundles />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    case "termsList":
    case "createTerm":
    case "editTerm":
      return (
        <div>
          <div sx={{ flexGrow: "1", height: "100vh", overflow: "auto" }} />
          <Container maxWidth="lg" sx={{ px: (theme) => theme.spacing(4) }}>
            <Grid container spacing={1} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    padding: (theme) => theme.spacing(2),
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                  }}
                >
                  <Terms />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    case "providersList":
    case "createProvider":
    case "editProvider":
      return (
        <div>
          <div sx={{ flexGrow: "1", height: "100vh", overflow: "auto" }} />
          <Container maxWidth="lg" sx={{ px: (theme) => theme.spacing(4) }}>
            <Grid container spacing={1} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    padding: (theme) => theme.spacing(2),
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                  }}
                >
                  <Providers />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    case "planSizeList":
    case "createPlanSize":
    case "editPlanSize":
      return (
        <div>
          <div sx={{ flexGrow: "1", height: "100vh", overflow: "auto" }} />
          <Container maxWidth="lg" sx={{ px: (theme) => theme.spacing(4) }}>
            <Grid container spacing={1} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    padding: (theme) => theme.spacing(2),
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                  }}
                >
                  <PlanSizes />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    case "usersList":
    case "createUser":
    case "editUser":
      return (
        <div>
          <div sx={{ flexGrow: "1", height: "100vh", overflow: "auto" }} />
          <Container maxWidth="lg" sx={{ px: (theme) => theme.spacing(4) }}>
            <Grid container spacing={1} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    padding: (theme) => theme.spacing(2),
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                  }}
                >
                  <Users />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    case "demoRequests":
      return (
        <div>
          <div sx={{ flexGrow: "1", height: "100vh", overflow: "auto" }} />
          <Container maxWidth="lg" sx={{ px: (theme) => theme.spacing(4) }}>
            <Grid container spacing={1} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    padding: (theme) => theme.spacing(2),
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                  }}
                >
                  <DemoRequests />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
    case "contactUs":
      return (
        <div>
          <div sx={{ flexGrow: "1", height: "100vh", overflow: "auto" }} />
          <Container maxWidth="lg" sx={{ px: (theme) => theme.spacing(4) }}>
            <Grid container spacing={1} sx={{ mt: 10 }}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    padding: (theme) => theme.spacing(2),
                    display: "flex",
                    overflow: "auto",
                    flexDirection: "column",
                  }}
                >
                  <ContactUs />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
      );
  }
};

export default DashboardBody;
